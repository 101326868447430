import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SportsSkillType } from '@index/enums';
import { GthSportAvailability } from '@index/interfaces';
import { GthGameTypeModel } from '@sentinels/models';

@Component({
  selector: 'app-sports-toggles',
  templateUrl: './sports-toggles.component.html',
  styleUrls: ['./sports-toggles.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDividerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SportsTogglesComponent implements OnInit {
  @Input()
  sportsAvailability: GthSportAvailability;

  @Input()
  skillVisible = false;

  @Input()
  gameTypes: GthGameTypeModel[];

  @Output()
  availabilityChange = new EventEmitter<GthSportAvailability>();

  public get SportsSkillType() {
    return SportsSkillType;
  }

  get sportSkill() {
    return this.sportsAvailability.skill;
  }

  get sportRoles() {
    return this.sportsAvailability.roles;
  }

  rolesForm = new FormControl([]);

  allRoles = computed(() => {
    const gameType = this.gameTypes.find((gt) => gt.label === this.sportsAvailability.sport);
    return gameType?.roles ? gameType.roles.sort() : null;
  });


  ngOnInit() {
    this.rolesForm.setValue(this.sportRoles);
    this.rolesForm.valueChanges.subscribe((rolesForm) => {
      this.sportsAvailability.roles = rolesForm;
      this.availabilityChange.emit(this.sportsAvailability);
    });
  }

  onSlideToggleChange(event: MatSlideToggleChange) {
    this.sportsAvailability.toggle = event.checked;
    this.availabilityChange.emit(this.sportsAvailability);
  }

  onButtonToggleChange(event: MatButtonToggleChange) {
    if (event.value && this.sportsAvailability.skill !== event.value) {
      this.sportsAvailability.skill = event.value;
    } else {
      this.sportsAvailability.skill = null;
    }
    this.availabilityChange.emit(this.sportsAvailability);
  }
}
