import { GthEventItemModel } from '@sentinels/models';
import { CurrentState } from '@sentinels/state/state';

export interface FavoritesState {
  userId: string | null;
  favorites: GthEventItemModel[];
  state: CurrentState;
  error: unknown | null;
}

export const initialFavoritesState: FavoritesState = {
  userId: null,
  favorites: [],
  state: CurrentState.Pending,
  error: null,
};
