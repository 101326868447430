import { Component, computed, ElementRef, inject, input, NgZone, viewChildren } from '@angular/core';
import { RulesModule } from '@ark/components/organizer-website/models/website-models';
import { OrganizerWebsiteBrandHeaderComponent } from '../../organizer-website-brand-header/organizer-website-brand-header.component';
import { RuleItemComponent } from './components/rule-item/rule-item.component';
import { RouterModule } from '@angular/router';
import { OrganizerWebsiteOtherLinksComponent } from '../../organizer-website-other-links/organizer-website-other-links.component';

@Component({
  selector: 'ark-rules-module',
  standalone: true,
  imports: [
    OrganizerWebsiteBrandHeaderComponent,
    OrganizerWebsiteOtherLinksComponent,
    RuleItemComponent,
    RouterModule,
  ],
  templateUrl: './rules-module.component.html',
  styleUrl: './rules-module.component.scss'
})
export class RulesModuleComponent {
  moduleData = input.required<RulesModule>();

  ngZone = inject(NgZone);

  brand = computed(() => this.moduleData().brand);
  rules = computed(() => this.moduleData().rules);
  description = computed(() => this.moduleData().description);

  ruleItems = viewChildren(RuleItemComponent);
  ruleItemElements = viewChildren(RuleItemComponent, {
    read: ElementRef,
  });

  onQuickLinkClick(header: string) {
    const ruleItem = this.ruleItems().find((ruleItem) => ruleItem.header() === header);
    if (!ruleItem) return;
  
    const ruleElementIndex = this.ruleItems().indexOf(ruleItem);
    const ruleElement = this.ruleItemElements()[ruleElementIndex].nativeElement;
    const top = ruleElement.getBoundingClientRect().top;
    const scrollingElement = document.querySelector('html');
    scrollingElement.scrollTo({
      left: 0, 
      top: top + scrollingElement.scrollTop - 80,
      behavior: 'smooth',
    });

    ruleElement.classList.add('highlight');
  
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        ruleElement.classList.remove('highlight');
      }, 2000);
    });
  }
}
