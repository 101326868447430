import { GthTeamModel, GthTeamSeasonModel } from "@sentinels/models";
import { GthTeamSeasonGroupModel } from "@sentinels/models/team-season-group";

export interface OrganizerWebsite {
  logoURL: string;
  darkLogoURL?: string;
  footerLogoURL?: string;
  darkFooterLogoURL?: string;
  websiteDescription?: string;
  color: string;
  accent: string;
  pages: WebsitePage[];
  socialLinks: SocialMediaLinks[];
  rules: RuleItem[];
  brands: string[]; // An array of brand IDs
}

export interface SocialMediaLinks {
  type: SocialMediaType;
  label: string;
  url: string;
}

export interface WebsitePage {
  id: string;
  title: string;
  slug: string;
  icon?: string;
  modules: WebsiteModule[];
}

export type WebsiteModule = 
  | GalleryWebsiteModule
  | FreeFormWebsiteModule
  | SocialMediaWebsiteModule
  | ContactWebsiteModule
  | TestimonialsWebsiteModule
  | RulesWebsiteModule
  | BrandWebsiteModule
  | SeasonRegisterWebsiteModule
  | ScheduleWebsiteModule
  | ScheduleViewWebsiteModule;

export interface GalleryWebsiteModule {
  type: 'gallery';
  module: GalleryModule;
}

export interface FreeFormWebsiteModule {
  type: 'freeform';
  module: FreeFormModule;
}

export interface SocialMediaWebsiteModule {
  type: 'social';
  module: SocialMediaModule;
}

export interface ContactWebsiteModule {
  type: 'contact';
  module: ContactModule;
}

export interface TestimonialsWebsiteModule {
  type: 'testimonials';
  module: TestimonialsModule;
}

export interface RulesWebsiteModule {
  type: 'rules';
  module: RulesModule;
}

export interface BrandWebsiteModule {
  type: 'brand';
  module: BrandModule;
}

export interface SeasonRegisterWebsiteModule {
  type: 'season-register';
  module: SeasonRegisterModule;
}

export interface ScheduleWebsiteModule {
  type: 'schedule';
  module: ScheduleModule;
}

export interface ScheduleViewWebsiteModule {
  type: 'schedule-view';
  module: ScheduleViewModule;
}

export interface GalleryModule {
  galleryItems: GalleryModuleItem[];
}

export interface GalleryModuleItem {
  title: string;
  subtitle: string;
  imageURL: string;
  newsImageURL: string;
  actionButton?: GalleryModuleItemActionButton;
}

export interface GalleryModuleItemActionButton {
  label: string;
  url: string | string[];
  internal?: boolean;
}

export interface FreeFormModule {
  content: string;
  extraClasses?: string;
}

export interface SocialMediaModule {
  type: SocialMediaType;
  handle: string;
}

export interface TestimonialsModule {
  description?: string;
  testimonials: Testimonial[];
}

export interface Testimonial {
  name: string;
  content: string;
  imageURL: string;
}

export interface ContactModule {
  description?: string;
  email: string;
  formFields: ContactFormField[];
}

export interface ContactFormField {
  label: string;
  type: ContactFormFieldType;
}

export interface RulesModule {
  description?: string;
  rules: Rule[];
  brand: BrandWithSeasonGroups;
}

export interface Rule {
  header: string;
  content?: string;
}

export interface RuleItem {
  brandId: string;
  description?: string;
  rules: Rule[];
}

export interface BrandModule {
  brand: BrandWithSeasonGroups;
}

export interface SeasonRegisterModule {
  brand: BrandWithSeasonGroups;
  color: string;
  accent: string;
  darkMode: boolean;
  seasonId: string;
}

export interface ScheduleModule {
  brand: BrandWithSeasonGroups;
}

export interface ScheduleViewModule {
  brand: BrandWithSeasonGroups;
  season: GthTeamSeasonModel;
}

export interface OrganizerBrand {
  brandName: string;
  brandId: string;
}

export enum ContactFormFieldType {
  TEXT = 'text',
  EMAIL = 'email',
  SELECT = 'select',
  TEXTAREA = 'textarea',
}

export enum WebsiteModuleType {
  GALLERY = 'gallery',
  FREEFORM = 'freeform',
  CONTACT = 'contact',
  SOCIAL = 'social',
  TESTIMONIALS = 'testimonials',
  RULES = 'rules',
  BRAND = 'brand',
  SEASON_REGISTER = 'season-register',
  SCHEDULE = 'schedule',
  SCHEDULE_VIEW = 'schedule-view',
}

export enum SocialMediaType {
  INSTAGRAM = 'Instagram',
  TIKTOK = 'TikTok',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
}

export interface NavPageLink {
  title: string;
  slug: string;
  icon?: string;
}

export interface BrandWithSeasonGroups {
  id: string;
  brand: GthTeamModel;
  seasonsAndGroups: SeasonsAndGroups;
}

export type SeasonsAndGroups = (SeasonGroupsWithSeasons | GthTeamSeasonModel)[];

export interface SeasonGroupsWithSeasons {
  seasonGroup: GthTeamSeasonGroupModel;
  seasons: GthTeamSeasonModel[];
}

export function flattenSeasonsAndGroups(seasonsAndGroups: SeasonsAndGroups): GthTeamSeasonModel[] {
  return seasonsAndGroups.reduce((acc: GthTeamSeasonModel[], item) => {
    if ('seasons' in item) {
      return acc.concat(item.seasons);
    } else {
      acc.push(item);
      return acc;
    }
  }, []);
}

export function isGroup(item: SeasonGroupsWithSeasons | GthTeamSeasonModel): item is SeasonGroupsWithSeasons {
  return 'seasons' in item;
}