import { isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import posthog from 'posthog-js';
import { register as registerSwiperElements } from 'swiper/element/bundle';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { unregisterServiceWorkers } from './unregister-service-workers';

/** PostHog Initialization */
posthog.init(
  environment.postHogApiToken,
  {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
    loaded: (postHog) => {
      if (isDevMode()) {
        postHog.opt_out_capturing();
        postHog.set_config({ disable_session_recording: true });
      } else {
        postHog.opt_in_capturing();
        postHog.set_config({ disable_session_recording: false });
      }
    },
  },
);

// Unregister all installed service workers and force reload the page if there was
// an old service worker from a previous version of the Gametime Hero web app.
unregisterServiceWorkers()
  .then((hadServiceWorker) => hadServiceWorker && location.reload());

// Register Swiper custom elements
registerSwiperElements();

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
