import { Component, input, output } from '@angular/core';
import { NavPageLink } from '@ark/components/organizer-website/models/website-models';
import { NavDropdownItemComponent } from '../nav-dropdown-item/nav-dropdown-item.component';
import { NavMenuType } from '../../organizer-website-navbar.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'ark-nav-pages-dropdown',
  standalone: true,
  imports: [
    NavDropdownItemComponent,
  ],
  templateUrl: './nav-pages-dropdown.component.html',
  styleUrl: './nav-pages-dropdown.component.scss',
  animations: [
    trigger('fadeSlide', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(2.5%)' }),
        animate('250ms ease-out', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('250ms ease-in', style({ opacity: 0, transform: 'translateX(-2.5%)', position: 'absolute' }))
      ])
    ])
  ]
})
export class NavPagesDropdownComponent {
  pageData = input<NavPageLink[]>([]);
  menuToggle = output<NavMenuType>();
  small = input<boolean>(false);

  NavMenuType = NavMenuType;
}
