import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { ProfileFormComponent, UserPaymentsComponent } from '@gth-legacy';
import { NavBarLayoutComponent } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';

import { MoreInfoComponent } from './more-info/more-info.component';
import { MoreInfoAvailabilityModule } from './more-info-availability/more-info-availability.module';
import { MoreInfoPrivacyModule } from './more-info-privacy/more-info-privacy.module';
import { MoreInfoSportsModule } from './more-info-sports/more-info-sports.module';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

const ANGULAR_MATERIAL_MODULES = [
  MatButtonModule, MatTabsModule,
  MatIconModule, MatCardModule,
  MatSlideToggleModule,
  NotificationSettingsComponent,
];

const CORE_MODULES = [CommonModule, RouterModule, FormsModule];

const COMPONENTS = [MoreInfoComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    ...CORE_MODULES, ...ANGULAR_MATERIAL_MODULES,
    NavBarLayoutComponent, ProfileFormComponent,
    MoreInfoPrivacyModule, UserPaymentsComponent,
    MoreInfoSportsModule, MoreInfoAvailabilityModule,
  ],
  exports: [...COMPONENTS],
})
export class MoreInfoModule {}
