@for (module of pageData().modules; track $index) {
  @switch (module.type) {
    @case (moduleType.GALLERY) {
      <ark-gallery-module [moduleData]="module.module" />
    }
    @case (moduleType.FREEFORM) {
      <ark-freeform-module [moduleData]="module.module" />
    }
    @case (moduleType.CONTACT) {
      <ark-contact-module [moduleData]="module.module" />
    }
    @case (moduleType.TESTIMONIALS) {
      <ark-testimonials-module [moduleData]="module.module" />
    }
    @case (moduleType.SOCIAL) {
      <ark-social-module [moduleData]="module.module" />
    }
    @case (moduleType.BRAND) {
      <ark-brand-module [moduleData]="module.module" />
    }
    @case (moduleType.SCHEDULE) {
      <ark-schedule-module [moduleData]="module.module" />
    }
    @case (moduleType.SCHEDULE_VIEW) {
      <ark-schedule-view-module [moduleData]="module.module" />
    }
    @case (moduleType.RULES) {
      <ark-rules-module [moduleData]="module.module" />
    }
    @case (moduleType.SEASON_REGISTER) {
      <ark-season-register-module [moduleData]="module.module" />
    }
  }
}
