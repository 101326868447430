import { Component, inject, OnInit } from '@angular/core';
import { SrvApiService } from '@sentinels/services/api.service';
import { NavBarOptions } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';

import { CommunityForm } from './community-form';

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss'],
})
export class CreateTeamComponent implements OnInit {
  get navBarOptions(): NavBarOptions {
    return { pageName: 'Create Team' };
  }

  communityForm = inject(CommunityForm);

  constructor(
    private api: SrvApiService,
  ) { }

  async ngOnInit() {
    const gameTypes = await this.api.gameTypes.listAsync();
    this.communityForm.setGameTypes(gameTypes);
  }
}
