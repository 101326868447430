import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ark-organizer-website-card-link',
  standalone: true,
  imports: [
    RouterLink,
    MatIconModule,
  ],
  templateUrl: './organizer-website-card-link.component.html',
  styleUrl: './organizer-website-card-link.component.scss'
})
export class OrganizerWebsiteCardLinkComponent {
  link = input<string | string[]>();
  icon = input<string>();
  image = input<string>();
  title = input<string>();
  description = input<string>();
}
