import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PlacesService } from '@sentinels/services/firebase/places.service';
import { debounceTime, map, switchMap } from 'rxjs/operators';

import * as Places from './actions';

@Injectable()
export class PlacesEffects {
  actions$ = inject(Actions<Places.PlacesActionsUnion>);
  placesService = inject(PlacesService);

  readonly initPlaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Places.placesLoadListByLatLng),
      switchMap((action) => {
        return this.placesService.getPickleballPlacesByLocation(action.bounds).pipe(
          debounceTime(500),
          map((places) => {
            return Places.placesLoadListSuccess({ places });
          }),
        );
      }),
    ),
  );

  readonly addPlaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Places.placesAddOne),
      switchMap((action) => {
        return this.placesService
          .addPlace(action.place)
          .pipe(switchMap(() => [Places.placesLoadListSuccess({ places: [] })]));
      }),
    ),
  );

  readonly updatePlace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Places.updatePlace),
      switchMap((action) => {
        return this.placesService
          .updatePlace(action.place)
          .pipe(switchMap(() => [Places.placesLoadListSuccess({ places: [] })]));
      }),
    ),
  );

  readonly loadEventsByPlaceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Places.placesLoadEventsByPlaceId),
      switchMap((action) => {
        return this.placesService
          .getEventsByPlaceId(action.placeId)
          .pipe(map((events) => Places.placesLoadEventsByPlaceIdSuccess({ events })));
      }),
    ),
  );
}
