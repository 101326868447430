import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipSelectionChange, MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Endorsement, EndorsementType, PersonalityType } from '@index/interfaces';
import { SrvApiService } from '@sentinels/services/api.service';

@Component({
  selector: 'app-personality-toggles',
  templateUrl: './personality-toggles.component.html',
  styleUrls: ['./personality-toggles.component.scss'],
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatProgressSpinnerModule],
})
export class PersonalityTogglesComponent implements OnInit {
  @Input()
  endorsements?: Endorsement[];

  @Output()
  personalityUpdate = new EventEmitter<string>();

  selectedPersonalities: string[] = [];
  personalityTypes?: string[][] = [];

  constructor(private api: SrvApiService) {}

  async ngOnInit() {
    if (this.endorsements) {
      this.selectedPersonalities = this.endorsements
        .filter((e) => e.type === EndorsementType.PERSONALITY)
        .map((e) => e.id);
    }
    this.personalityTypes = (await this.api.personalityTypes.listAsync())
      .map((item) => {
        return [item.item, item.label];
      })
      .sort();
  }

  onPersonalityChange(change: MatChipSelectionChange) {
    if (change.isUserInput) this.personalityUpdate.emit(change.source.value as string);
  }
}
