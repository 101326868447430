import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  isDevMode,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { ArkLoadingProgressService } from '@ark/services/progress.service';
import { environment } from '@environments/environment';
import {
  ANALYTICS_CONSTS,
  GthAnalyticsService,
  GthLoadingService,
} from '@gth-legacy';
import { DuprService } from '@sentinels/services/dupr.service';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { PageTitleService } from '@shared/services/page-title.service';
import { AppSidenavService } from '@shared/services/sidenav.service';
import { UnsafeBrowserService } from '@shared/services/unsafe-browser.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterOutlet,
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('pwaDialogContent', { static: true })
  pwaDialogContent!: TemplateRef<any>;

  private subscriptions = new Subscription();
  environmentName = isDevMode() ? environment.envName : '';
  isLoggedIn = new BehaviorSubject(false);
  isOnline: boolean;
  modalVersion: boolean;
  isFirstVisit = false;

  private offlineSnackBarRef?: MatSnackBarRef<TextOnlySnackBar>;
  private duprService = inject(DuprService);
  showLoadingLogo = new BehaviorSubject<boolean>(true);

  constructor(
    private titleService: Title,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    readonly spinner: GthLoadingService,
    readonly progress: ArkLoadingProgressService,
    private pageTitle: PageTitleService,
    private iconRegistry: MatIconRegistry,
    private analytics: GthAnalyticsService,
    private safeWindow: SrvSafeWindowService,
    public sidenav: AppSidenavService,
    private safeStorage: SrvSafeStorageService,
    private unsafeService: UnsafeBrowserService,
    private remoteConfig: AngularFireRemoteConfig,
  ) {
    this.remoteConfig.fetchAndActivate();

    // Add SVG icon to MatIconRegistry
    this.iconRegistry.addSvgIcon(
      'gth-organize',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/gth-organize.svg'),
    );
  }

  ngOnInit() {
    this.subscriptions.add(this.duprService.authenticate().subscribe());

    // Check visit count and set isFirstVisit accordingly
    let visitCount = parseInt(this.safeStorage.getItem('visitCount'));
    if (isNaN(visitCount)) {
      this.isFirstVisit = true;
      visitCount = 1;
    } else {
      this.isFirstVisit = false;
      visitCount++;
    }
    this.safeStorage.setItem('visitCount', visitCount.toString());

    // Check online status
    this.updateOnlineStatus();

    // Subscribe to title changes
    this.subscriptions.add(
      this.pageTitle.title$.pipe(distinctUntilChanged()).subscribe((val: string) => {
        this.titleService.setTitle(val);
      }),
    );

    // Log app open event
    this.analytics.logEvent(ANALYTICS_CONSTS.appOpen, { component: 'AppComponent' });

    // Check if window is blacklisted and mark as unsafe
    if (this.safeWindow.blacklisted) {
      this.unsafeService.markUnsafe();
    }

    // Remove PWA installation flag
    this.safeStorage.removeItem('gth-pwa-installed');

    // Add event listeners for online/offline events
    if (this.safeWindow.hasWindow) {
      window.addEventListener('online', this.updateOnlineStatus.bind(this));
      window.addEventListener('offline', this.updateOnlineStatus.bind(this));
    }
  }

  ngAfterViewInit() {
    // Initialize notification sound
    this.pageTitle.newNotifications.initSound();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.unsubscribe();
  }

  private getFirstSegment(url: string): string | null {
    const segments = url.split('/').filter((segment) => segment);
    return segments.length > 0 ? segments[0] : null;
  }

  private updateOnlineStatus(): void {
    // Update online status based on navigator status
    this.isOnline = this.safeWindow.navigator.onLine;

    // Show offline snackbar if offline
    if (!this.isOnline) {
      this.offlineSnackBarRef = this.snackBar.open('Offline: No Internet Connection', '', {
        duration: 0,
      });
    } else {
      // Close offline snackbar if online
      if (this.offlineSnackBarRef) this.offlineSnackBarRef.closeWithAction();
    }
  }

  onSidenavChange(opened: any) {
    // Clear sidenav content if closed
    if (!opened) {
      this.sidenav.ctrl.set(undefined);
    }
  }
}
