import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

export interface TodoListItem {
  text: string;
  url?: any[];
  params?: { [key: string]: any };
  callback?: () => void;
}

@Component({
  selector: 'app-alert-menu',
  standalone: true,
  imports: [
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    RouterLink,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './alert-menu.component.html',
  styleUrl: './alert-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertMenuComponent {
  @Input() tasks: TodoListItem[] = [];

  constructor(private router: Router) {}

  handleClickTask(task: TodoListItem) {
    if (task.callback) {
      task.callback();
    } else {
      this.router.navigate(task.url, {
        queryParams: task.params,
        queryParamsHandling: 'merge',
      });
    }
  }
}
