import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs';

import { GthAuthService, GthLoadingService } from '../../../../../gth-legacy/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class EmailVerifiedGuard {
  router = inject(Router);
  auth = inject(GthAuthService);
  spinner = inject(GthLoadingService);

  canActivate(route: ActivatedRouteSnapshot) {
    const user$ = this.auth.getAuthStateUser$();
    this.spinner.show = true;
    return user$.pipe(
      map(async (user: firebase.User) => {
        // If the user is logged in, but the email is not verified
        // and the route is not the unauthorized route,
        console.log('user', user);
        if (!user?.emailVerified) {
          console.log();
          await this.router.navigate(['verify-email']);
          return false;
        }
        return true;
      }),
    );
  }
}
