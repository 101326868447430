<div class="sport-availability-item">
  <mat-slide-toggle [(ngModel)]="sportsAvailability.toggle"
    (change)="onSlideToggleChange($event)">{{sportsAvailability.sport}}</mat-slide-toggle>

  @if (sportsAvailability.toggle || skillVisible) {
  <mat-button-toggle-group [value]="sportSkill" class="small-toggle" (change)="onButtonToggleChange($event)"
    [hideSingleSelectionIndicator]="true" name="skill" aria-label="Select a skill">
    <mat-button-toggle value="novice" matTooltip="I have little to no experience."
      [matTooltipPosition]="'above'">Novice</mat-button-toggle>
    <mat-button-toggle value="beginner" matTooltip="I play casually."
      [matTooltipPosition]="'above'">Beginner</mat-button-toggle>
    <mat-button-toggle value="intermediate" matTooltip="I play and know the rules."
      [matTooltipPosition]="'above'">Intermediate</mat-button-toggle>
    <mat-button-toggle value="advanced" matTooltip="I play competitively."
      [matTooltipPosition]="'above'">Advanced</mat-button-toggle>
  </mat-button-toggle-group>
  }

  @if (allRoles()) {
  <div>
    <mat-form-field>
      <mat-label>Positions</mat-label>
      <mat-select [formControl]="rolesForm" multiple>
        @for (role of allRoles(); track role) {
        <mat-option [value]="role">{{role}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  }
</div>

<mat-divider />