import { Component, computed, input, output, signal } from '@angular/core';
import { BrandWithSeasonGroups, isGroup, NavPageLink, SeasonGroupsWithSeasons } from '../../models/website-models';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NavLinkComponent } from './components/nav-link/nav-link.component';
import { NavDropdownItemComponent } from './components/nav-dropdown-item/nav-dropdown-item.component';
import { DatePipe, JsonPipe } from '@angular/common';
import { NavBrandsDropdownComponent } from './components/nav-brands-dropdown/nav-brands-dropdown.component';
import { NavPagesDropdownComponent } from './components/nav-pages-dropdown/nav-pages-dropdown.component';

export enum NavMenuType {
  PAGES = 'pages',
  BRAND = 'brand',
  DIVISIONS = 'divisions',
}

@Component({
  selector: 'ark-organizer-website-navbar',
  standalone: true,
  imports: [
    RouterLink,
    MatIconModule,
    NavLinkComponent,
    NavDropdownItemComponent,
    NavPagesDropdownComponent,
    NavBrandsDropdownComponent,
    JsonPipe,
    DatePipe,
  ],
  templateUrl: './organizer-website-navbar.component.html',
  styleUrl: './organizer-website-navbar.component.scss',
})
export class OrganizerWebsiteNavbarComponent {
  logoURL = input<string>('');
  pageData = input<NavPageLink[]>([]);
  brandData = input<BrandWithSeasonGroups[]>([]);
  isDarkMode = input<boolean>(false);
  urlSegments = input<string[]>([]);

  toggleDarkMode = output<void>();
  
  isMenuOpen = signal<boolean>(false);
  menuType = signal<NavMenuType | null>(null);

  isPagesDropdownOpen = computed(() => this.menuType() === NavMenuType.PAGES && this.isMenuOpen());

  activeNavBrand = signal<BrandWithSeasonGroups | null>(null);
  activeNavGroup = signal<SeasonGroupsWithSeasons | null>(null);

  NavMenuType = NavMenuType;

  onMenuToggle(type: NavMenuType, brandId?: string) {
    // TODO: This is a mess
    if (this.isMenuOpen() && this.menuType() === type) {
      if (brandId && this.activeNavBrand()?.id !== brandId) {
        this.activeNavBrand.set(this.brandData().find(brand => brand.id === brandId));
        this.activeNavGroup.set(null);
      } else {
        this.closeMenu();
      }
    } else {
      this.menuType.set(type);
      this.isMenuOpen.set(true);
      if (brandId) {
        this.activeNavBrand.set(this.brandData().find(brand => brand.id === brandId));
      }
    }
  }

  private closeMenu() {
    this.isMenuOpen.set(false);
    this.activeNavBrand.set(null);
    this.activeNavGroup.set(null);
    this.menuType.set(null);
  }

  onMobileNavMenuClick() {
    if (this.isMenuOpen()) {
      this.closeMenu();
    } else {
      this.onMenuToggle(null);
    }
  }

  isBrandDropdownOpen(brandId: string) {
    return this.menuType() === NavMenuType.BRAND && this.activeNavBrand()?.id === brandId && this.isMenuOpen();
  }

  isGroup = isGroup;
}
