@defer (when tasks.length) {
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon color="warn" class="warning-icon">warning</mat-icon>
          <p>Incomplete Tasks (Click to view)</p>
        </mat-panel-title>

      </mat-expansion-panel-header>
      <div appearance="outlined" class="main-container">
        @for (task of tasks; track task) {
          <a
            class="task-btn"
            mat-stroked-button
            color="warn"
             (click)="handleClickTask(task)"
            >
            {{task.text}}
          </a>
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
} @placeholder {
  <ngx-skeleton-loader
    [theme]="{ 'width': '100%', 'height': '48px', 'border-radius': '8px', background: '#e6e9ed',
     'box-shadow': '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);' }"
  />
}
