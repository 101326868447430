<div class="notification-settings">
  <h2>Notification Settings</h2>
  <form [formGroup]="settingsForm" (ngSubmit)="saveSettings()">
    <mat-list>
      <mat-list-item [formGroup]="getFormGroup('surveys')">
        <div matListItemMeta class="icon-container-wrapper">
          <div class="icon-container">
            <mat-icon>email</mat-icon>
            <span>Email</span>
          </div>
          <div class="icon-container">
            <mat-icon>notification_important</mat-icon>
            <span>Push</span>
          </div>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>

      @for(keyValue of subscriptionMetadata | keyvalue:keepOriginalOrder; track keyValue) {
      <mat-list-item [formGroup]="getFormGroup(keyValue.key)">
        <span matListItemTitle>{{ keyValue.value.title }}</span>
        <p class="description" matListItemLine>{{ keyValue.value.description }}</p>
        <div matListItemMeta>
          <mat-checkbox class="checkbox" formControlName="email"></mat-checkbox>
          <mat-checkbox class="checkbox" formControlName="push"></mat-checkbox>
        </div>
      </mat-list-item>
      }

    </mat-list>
    <button class="button-save" mat-raised-button color="primary" type="submit">Save Changes</button>
    <a class="button-save" mat-flat-button class="inverted uppercase" color="accent"
      [routerLink]="[APP_ROUTES.Profile]">View Profile</a>
  </form>
</div>