<div class="relative w-full p-4 md:p-6 h-[calc(100svh-4rem)]">
  @if (gallerySlides()?.length) {
    <div class="absolute z-20 transform -translate-x-1/2 left-1/2 bottom-10 flex gap-2.5">
      @for (slide of gallerySlides(); track $index) {
        <button
          class="size-2.5 rounded-full bg-white all-unset"
          [class.opacity-50]="$index !== activeSlide()"
          (click)="setActiveSlide($index)"
        ></button>
      }
    </div>
    <!-- TODO: Make these components -->
    <div
      class="absolute top-0 left-0 p-[inherit] z-10 w-32 md:w-[15%] h-full opacity-0 hover:opacity-100 transition-opacity"
      (click)="setPreviousSlide()"
    >
      <div class="size-full bg-gradient-to-r from-black/60 to-transparent rounded-l-[4rem] flex items-center justify-center">
        <mat-icon class="text-4xl text-white size-9">chevron_left</mat-icon>
      </div>
    </div>
    <div
      class="absolute top-0 right-0 p-[inherit] z-10 w-32 md:w-[15%] h-full opacity-0 hover:opacity-100 transition-opacity"
      (click)="setNextSlide()"
    >
      <div class="size-full bg-gradient-to-l from-black/60 to-transparent rounded-r-[4rem] flex items-center justify-center">
        <mat-icon class="text-4xl text-white size-9">chevron_right</mat-icon>
      </div>
    </div>
    @for (slide of gallerySlides(); track $index) {
      <div
        class="p-[inherit] w-full h-full absolute top-0 left-0 transition-opacity duration-500"
        [class.opacity-0]="$index !== activeSlide()"
      >
        <div class="p-[inherit] absolute transform -translate-y-1/2 top-1/2 w-[90%] -translate-x-1/2 left-1/2 md:translate-x-0 md:left-[5%] md:max-w-[40%] z-20 flex flex-col sm:items-start items-center">
          @if (slide.newsImageURL) {
            <img [src]="slide.newsImageURL" class="object-contain object-center mb-4 sm:object-left md:-mt-24 -mt-14 max-h-20 md:max-h-36" />
          }
          <h2 class="mt-0 mb-4 text-center text-white sm:text-left">{{ slide.title }}</h2>
          <p class="m-0 mb-5 prose text-center text-pear-400 sm:text-left">{{ slide.subtitle }}</p>
          @if (slide.actionButton; as button) {
            @if (button.internal) {
              <a
                class="bg-pear-600 text-olive-50 text-button"
                [routerLink]="button.url"
              >
                {{ button.label }}
              </a>
            } @else {
              <a
                class="bg-pear-600 text-olive-50 text-button"
                href="{{ button.url }}"
                target="_blank"
              >
                {{ button.label }}
              </a>
            }
          }
        </div>
        <div class="relative w-full h-full">
          <img
            class="object-cover w-full h-full rounded-[4rem]"
            src="{{ slide.imageURL }}"
            alt="{{ slide.title }}"
          />
          <div class="absolute inset-0 bg-gradient-to-r from-black/50 to-transparent rounded-[4rem]"></div>
          <div class="absolute inset-0 bg-black/35 rounded-[4rem]"></div>
        </div>
      </div>
    }
  }
</div>
