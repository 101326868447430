import { Injectable } from '@angular/core';
import { EventJoiner } from '@index/interfaces';
import { TeamsService } from '@sentinels/services/firebase/teams.service';
import { eventLoadOne } from '@sentinels/state/features/events/actions';
import { selectEventByid } from '@sentinels/state/features/events/selectors';
import { joinerLoadList } from '@sentinels/state/features/joiners/actions';
import { selectJoinersByEventId } from '@sentinels/state/features/joiners/selectors';
import { isBottomNavCollapsed } from '@shared/layouts/nav-bar-layout/state/selectors';
import { combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  shareReplay,
  switchMap,
} from 'rxjs/operators';

import { CurrentState, EventInfoState } from '../state'; // Make sure to import CurrentState
import { EventInfoLoaderService } from './event-info-loader.service';

@Injectable({ providedIn: 'root' })
export class EventInfoViewModelService {
  constructor(
    private loaderService: EventInfoLoaderService,
    private teamService: TeamsService,
  ) {}

  /**
   * Retrieves event information.
   * @param {string} eventId - ID of the event.
   * @param {string} teamId - (Optional) ID of the team associated with the event.
   * @return {Observable<EventInfoState>} Observable of EventInfoState.
   */
  getEventInfo(eventId: string, teamId?: string): Observable<EventInfoState> {
    return this.initEventView(eventId, teamId).pipe(shareReplay());
  }

  /**
   * Initializes event view.
   * @param {string} eventId - ID of the event.
   * @param {string} teamId - (Optional) ID of the team associated with the event.
   * @return {Observable<EventInfoState>} Observable of EventInfoState.
   */
  initEventView(eventId: string, teamId?: string): Observable<EventInfoState> {
    let team$: Observable<any>;
    if (teamId) {
      team$ = this.teamService.getTeamByTeamId$(teamId);
    } else {
      team$ = of(null);
    }

    this.loaderService.store.dispatch(eventLoadOne({ id: eventId }));

    this.loaderService.store.dispatch(joinerLoadList({ eventId }));

    const event$ = this.loaderService.store.select(selectEventByid(eventId));
    const joiners$ = this.loaderService.store.select(selectJoinersByEventId(eventId)).pipe(
      switchMap((joiners) => {
        return !joiners || joiners.length === 0 ? of([]) : of(joiners);
      }),
    );

    const users$ = joiners$.pipe(
      mergeMap((joiners) => {
        if (joiners.length === 0) {
          return of([]);
        }
        const userObs$ = joiners.map((joiner: EventJoiner) => {
          if (joiner.isUnregisteredUser) {
            return this.loaderService.getUnregisteredUser(joiner.player);
          }

          return this.loaderService.getUser(joiner.player);
        });

        return userObs$?.length ? combineLatest(userObs$) : of([]);
      }),
    );

    const creator$ = event$.pipe(
      switchMap((eventItem) =>
        eventItem && eventItem.creatorId
          ? this.loaderService.getUser(eventItem.creatorId)
          : of(null),
      ),
      shareReplay(),
    );

    const isBottomCollapsed$ = this.loaderService.store.select(isBottomNavCollapsed);
    return combineLatest([event$, team$, users$, joiners$, creator$, isBottomCollapsed$]).pipe(
      distinctUntilChanged(),
      map(([event, team, users, joiners, creator, isBottomCollapsed]) => {
        if (event && creator) {
          event.setCreator(creator);
        }
        return {
          event,
          joinersUsers: { joiners, users },
          state: (event && creator) || event === null ?
            CurrentState.Success : CurrentState.Loading,
          team,
          teamId,
          isBottomCollapsed,
        };
      }),
      catchError((error) => {
        throw new error();
      }),
    );
  }
}
