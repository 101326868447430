/**
 * @deprecated To be replaced by interface in @index
 */
export interface User {
  uid: string;
  email: string | null;
  displayName?: string | null;
  fullName?: string | null;
  photoURL?: string | null;
  emailVerified?: boolean;
  defaultCity?: DefaultCity;
  additionalInfo?: null | AdditionalInfo;
  privacySettings?: null | PrivacySettings;
  token?: string;
  availability?: Availability[] | null;
}

/**
 * @deprecated To be replaced by interface in @index
 */
export interface Availability {
  weekday: unknown;
  sport?: string;
  times: TimeRanges[];
  location?: DefaultAddress;
  radius?: number;
  toggle: boolean;
}

/**
 * @deprecated To be replaced by interface in @index
 */
export interface PrivacySettings {
  messagesFromAnyone?: boolean;
  publicBadges?: boolean;
  publicOnlineStatus?: boolean;
  publicUpcomingGames?: boolean;
  blocklist?: string[];
}

/**
 * @deprecated To be replaced by interface in @index
 */
export interface AdditionalInfo {
  sports?: string[];
  gender?: string;
  pronoun?: string;
}

/**
 * @deprecated To be replaced by interface in @index
 */
export interface DefaultCity {
  lat: number;
  lng: number;
  name: string;
  hash?: string;
}

/**
 * @deprecated To be replaced by interface in @index
 */
export interface DefaultAddress {
  lat: number;
  lng: number;
  name: string;
  hash?: string;
}

/**
 * @deprecated To be replaced by interface in @index
 */
export interface ListFilter {
  email?: string;
  displayName?: string;
  fullName?: string;
  omits?: omitable[];
}

/**
 * @deprecated To be replaced by interface in @index
 */
export type omitable = "email" | "additionalInfo" | "defaultCity";

/**
 * @deprecated To be replaced by interface in @index
 */
export enum TimeRanges {
  Morning = "morning",
  Afternoon = "afternoon",
  Evening = "evening",
}
