import { Component, computed, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrandWithSeasonGroups, NavPageLink } from '@ark/components/organizer-website/models/website-models';

@Component({
  selector: 'ark-nav-link',
  standalone: true,
  imports: [
    MatIconModule,
  ],
  templateUrl: './nav-link.component.html',
  styleUrl: './nav-link.component.scss'
})
export class NavLinkComponent {
  label = input<string>('');
  icon = input<string>();
  isDropdownOpen = input<boolean>(false);
  pageData = input<NavPageLink[]>([]);
  brandData = input<BrandWithSeasonGroups>();
  active = input<boolean>(false);

  hasDropdown = computed(() => this.pageData().length > 0 || this.brandData());

  linkClicked = output<void>();
}
