import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScheduleScraperService {
  private http = inject(HttpClient);

  recentlyParsedDate: Date;

  getAllScheduleData(scheduleURLs: string[]): Observable<any[]> {
    if (!scheduleURLs?.length) return of([]);
    const requests = scheduleURLs.map((url) => this.getScheduleData(url));
    return forkJoin(requests);
  }

  getScheduleData(scheduleURL: string): Observable<any> {
    const proxyUrl = `/scheduleapi${new URL(scheduleURL).pathname}`;
    return this.http.get(proxyUrl, { responseType: 'text' }).pipe(
      map((html) => {
        const schedule = this.extractTableData(html, 'ctl00_ContentPlaceHolder1_StandingsResultsControl_ScheduleGrid_ctl00');
        const { upcoming, past } = this.separateUpcomingAndPastGames(schedule);
        return {
          name: this.extractContent(html, 'PageSubTitleSpan'),
          standings: this.extractTableData(html, 'ctl00_ContentPlaceHolder1_StandingsResultsControl_standingsGrid_ctl00'),
          upcoming,
          past,
          playOffs: this.extractTableData(html, 'ctl00_ContentPlaceHolder1_StandingsResultsControl_PlayoffGrid_ctl00'),
        }
      })
    );
  }

  private extractContent(html: string, elementId: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const element = doc.getElementById(elementId);
    return element ? element.textContent?.trim() || '' : '';
  }

  private extractTableData(html: string, tableId: string): any[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const table = doc.getElementById(tableId);
    if (!table) return [];

    const rows = Array.from(table.getElementsByTagName('tr'));
    const data = rows.map(row => {
      let cells = Array.from(row.getElementsByTagName('td'));
      if (!cells.length) cells = Array.from(row.getElementsByTagName('th'));
      if (!cells.length) return null;
      return cells.map(cell => {
        const childElements = Array.from(cell.childNodes);
        return childElements.map(child => child.textContent?.trim() || '').filter(text => text !== '').join('|');
      });
    })
    .filter(row => row !== null);

    return data;
  }

  private separateUpcomingAndPastGames(schedule: any[]): { upcoming: any[], past: any[] } {
    const now = new Date();
    const currentYear = now.getFullYear();
    const upcoming = [];
    const past = [];

    for (const game of schedule) {
      const date = this.parseDate(game[0], currentYear);
      if (!date) continue;
      if (date > now) {
        upcoming.push(game);
      } else {
        past.push(game);
      }
    }

    this.recentlyParsedDate = null;

    return { upcoming, past };
  }

  private parseDate(dateString: string, year: number): Date {
    const [_dayOfWeek, monthDay] = dateString.split(' ');
    if (!monthDay || !monthDay.includes('/')) return null;
    const [month, day] = monthDay.split('/').map(Number);
    let parsedDate = new Date(year, month - 1, day);

    if (
      this.recentlyParsedDate &&
      parsedDate < this.recentlyParsedDate
    ) {
      parsedDate = new Date(year + 1, month - 1, day);
    }

    this.recentlyParsedDate = parsedDate;
    return parsedDate;
  }
}
