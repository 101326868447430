@if (group(); as group) {
  <div class="md:absolute flex flex-col md:flex-1 w-full md:w-[calc(100%-4rem)] md:h-[calc(100%-3rem)]" @fadeSlide>
    <div class="flex items-center gap-3 mb-2">
      <h3 class="my-0 dark:text-olive-50 text-olive-1000">{{ group.seasonGroup.label }}</h3>
      <button class="all-unset link-button" (click)="setGroup.emit(null)">Back</button>
    </div>
    <ul class="flex flex-1 gap-4 p-0 m-0 overflow-x-auto list-none">
      @for (season of group.seasons; track season) {
        <ark-nav-dropdown-item
          [title]="season.label"
          icon="sports_score"
          subtitle="Starts {{ season.startDate | date:'longDate' }}"
          [link]="[brand().id, 'season', season.id]"
          [small]="small()"
          [image]="season.imageURL"
          [badgeText]="isActive(season) ? 'Current' : 'Upcoming'"
          (click)="menuToggle.emit({ type: NavMenuType.BRAND, id: brand().id })"
        />
        @if (season.scheduleURLs[0]) {
          <ark-nav-dropdown-item
            title="Schedule"
            icon="calendar_month"
            subtitle="View the schedule for {{ season.label }}"
            [link]="[brand().id, 'schedule', season.id]"
            [small]="small()"
            [badgeText]="isActive(season) ? 'Current' : 'Upcoming'"
            (click)="menuToggle.emit({ type: NavMenuType.BRAND, id: brand().id })"
          />
        }
      }
    </ul>
  </div>
} @else {
  <div class="md:absolute flex flex-col md:flex-1 w-full md:w-[calc(100%-4rem)] md:h-[calc(100%-3rem)]" @fadeSlide>
    <div class="flex items-center gap-2 mb-2">
      <h3 class="my-0 dark:text-olive-50 text-olive-1000">{{ brand().brand.name }}</h3>
    </div>
    <ul class="flex flex-col flex-1 gap-4 p-0 m-0 list-none md:flex-row">
      <div class="flex flex-1 gap-4 overflow-x-auto">
        @for (item of brand().seasonsAndGroups; track $index) {
          @if (isGroup(item)) {
            <ark-nav-dropdown-item
              [title]="item.seasonGroup.label"
              icon="location_pin"
              subtitle="View all seasons in this group"
              [small]="small()"
              (click)="onGroupSelect(item)"
            />
          } @else {
            <ark-nav-dropdown-item
              [title]="item.label"
              icon="sports_score"
              subtitle="Starts {{ item.startDate | date:'longDate' }}"
              [link]="[brand().id, 'season', item.id]"
              [small]="small()"
              [image]="item.imageURL"
              [badgeText]="isActive(item) ? 'Current' : 'Upcoming'"
              (click)="menuToggle.emit({ type: NavMenuType.BRAND, id: brand().id })"
            />
          }
        }
      </div>
      <li class="flex flex-row w-full gap-4 md:flex-col md:max-w-80">
        <ark-nav-dropdown-item
          title="Rules"
          icon="balance"
          subtitle="View the rules for {{ brand().brand.name }}"
          [small]="true"
          [link]="[brand().id, 'rules']"
          (click)="menuToggle.emit({ type: NavMenuType.BRAND })"
        />
        <ark-nav-dropdown-item
          title="Schedules"
          icon="calendar_month"
          subtitle="View schedules for all seasons"
          [small]="true"                    
          [link]="[brand().id, 'schedule']"
          (click)="menuToggle.emit({ type: NavMenuType.BRAND })"
        />
      </li>
      <li class="flex flex-row gap-4 md:flex-col">
        <ark-nav-dropdown-item
          title="Register"
          icon="assignment"
          subtitle="View all seasons"
          [small]="true"
          [link]="[brand().id]"
          (click)="menuToggle.emit({ type: NavMenuType.BRAND })"
        />
        <ark-nav-dropdown-item
          title="Tournaments"
          icon="emoji_events"
          subtitle="Coming soon"
          [small]="true"
          [disabled]="true"
          (click)="menuToggle.emit({ type: NavMenuType.BRAND })"
        />
      </li>
    </ul>
  </div>
}