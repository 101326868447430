<div class="bg-olive-200 dark:bg-olive-950">
  <div class="flex flex-col items-center gap-8 py-8 md:py-16 max-w-[1920px] mx-auto px-4 sm:px-8 md:px-16">
    <div>
      <h2 class="mb-2.5 mt-0 text-3xl font-bold text-center dark:text-olive-50 text-olive-1000">Testimonials</h2>
      <p class="m-0 text-center text-olive-600 dark:text-olive-500 max-w-[500px] prose">{{ description() }}</p>
    </div>
    <div class="gap-4 columns-1 md:columns-2 xl:columns-3">
      @for (testimonial of testimonials(); track $index) {
        <div class="flex sm:flex-row flex-col flex-1 gap-6 p-8 bg-olive-100 dark:bg-olive-1000 rounded-2xl w-full sm:min-w-[350px] break-inside-avoid-column mb-4">
          @if (testimonial.imageURL) {
            <img
              class="flex-shrink-0 object-cover rounded-full size-12"
              [src]="testimonial.imageURL"
            />
          } @else {
            <div class="flex items-center justify-center flex-shrink-0 rounded-full size-12 bg-olive-300 dark:bg-olive-900">
              <mat-icon class="text-4xl leading-none text-olive-500 dark:text-olive-700 size-9">person</mat-icon>
            </div>
          }
          <div class="flex-1">
            <p class="m-0 prose dark:text-olive-500 text-olive-600">{{ testimonial.content }}</p>
            <h3 class="mt-4 mb-0 text-base font-bold dark:text-olive-50 text-olive-1000">- {{ testimonial.name }}</h3>
          </div>
        </div>
      }
    </div>
  </div>
</div>
