export interface NotificationSettings {
  email?: boolean;
  push?: boolean;
}

export interface SubscriptionSettingsBase {
  surveys: NotificationSettings;
  news: NotificationSettings;
  learning: NotificationSettings;
  productSpotlight: NotificationSettings;
  offers: NotificationSettings;
  usersJoinNearby: NotificationSettings;
  eventCreatedJoin: NotificationSettings;
  eventJoined: NotificationSettings;
  endorsement: NotificationSettings;
  directMessage: NotificationSettings;
  teamJoin: NotificationSettings;
  teamMessage: NotificationSettings;
  connections: NotificationSettings;
  announcements: NotificationSettings;
}

interface IMetaDataItem {
  title: string;
  description: string;
}

export const subscriptionMetadata: Record<keyof SubscriptionSettingsBase, IMetaDataItem> = {
  surveys: {
    title: 'Surveys',
    description: 'Give us feedback and help us improve.',
  },
  news: {
    title: 'News',
    description: 'All the latest – news, updates, and good vibes!',
  },
  connections: {
    title: 'Connections',
    description: 'Learn when you\'re connections to others grow',
  },
  learning: {
    title: 'Learning',
    description: 'Learn, grow, and stay savvy!',
  },
  productSpotlight: {
    title: 'Product Spotlight',
    description: 'Get the spotlight on products you might like.',
  },
  offers: {
    title: 'Offers',
    description: 'Deals and exclusive offers, just for you!',
  },
  usersJoinNearby: {
    title: 'Users Nearby',
    description: 'Meet new people nearby and keep your community growing!',
  },
  eventCreatedJoin: {
    title: 'Event Join Notification',
    description: 'Someone’s joined your event! Party just got better.',
  },
  eventJoined: {
    title: 'Event Participant Alert',
    description: 'When a new face joins the event you\'re attending.',
  },
  endorsement: {
    title: 'Endorsement Received',
    description: 'Recognition alert when someone endorses you!',
  },
  directMessage: {
    title: 'Direct Message',
    description: 'New DM alert! Someone’s slid into your inbox.',
  },
  teamJoin: {
    title: 'New Team Member',
    description: 'Team update! A new member has joined the squad.',
  },
  teamMessage: {
    title: 'Team Message Received',
    description: 'Received when a new team message received – chat’s buzzing!',
  },
  announcements: {
    title: 'Announcements Received',
    description: 'Received when an announcement is made within your community.',
  },
};

export function getSettingMetadata(setting: keyof SubscriptionSettingsBase) {
  return subscriptionMetadata[setting];
}
