import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TeamSeasonService } from '@sentinels/services/firebase/team-season.service';
import { TeamSessionService } from '@sentinels/services/firebase/team-session.service';
import { TeamsService } from '@sentinels/services/firebase/teams.service';
import { LoadExternalTeamsByUserId, LoadExternalTeamsByUserIdError, LoadExternalTeamsByUserIdSuccess, LoadSeasonGroupsOne, LoadSeasonGroupsSuccess, LoadSeasonsOne, LoadSeasonsSuccess, LoadSessionsError, LoadSessionsOne, LoadSessionsSuccess, LoadTeamRoles, LoadTeamRolesError, LoadTeamRolesSuccess, LoadTeamsByUserId, LoadTeamsByUserIdError, LoadTeamsByUserIdSuccess, loadTeamsListByLatLng, LoadTeamsListError, LoadTeamsListSuccess, LoadTeamsOne, LoadTeamsOneSuccess } from '@sentinels/state/features/teams/actions';
import { exhaustMap, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { LoadSeasonsError } from '../active-team/actions';

@Injectable()
export class TeamsEffects {
  constructor(
    private actions$: Actions,
    private teamsService: TeamsService,
    private teamSessionService: TeamSessionService,
    private teamSeasonService: TeamSeasonService,
  ) { }

  readonly loadTeamsByUserIdEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LoadTeamsByUserId),
    mergeMap(({ userId }) => this.teamsService.getTeamsByUserId$(userId).pipe(
      map((teams) => LoadTeamsByUserIdSuccess({ teams })),
      catchError((error: unknown) => {
        return of(LoadTeamsByUserIdError({ error }));
      }),
    )),
  ));

  readonly loadExternalTeamsByUserIdEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LoadExternalTeamsByUserId),
    exhaustMap((action) => this.getExternalTeamsByUserId$(action.userId)),
  ));

  readonly loadTeamRolesEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LoadTeamRoles),
    exhaustMap(() => this.getTeamRoles$()),
  ));

  readonly loadTeamsListByLatLngEffect$ = createEffect(() => this.actions$.pipe(
    ofType(loadTeamsListByLatLng),
    exhaustMap((action) => this.getTeamsByLatLng$(action.lat, action.lng)),
  ));

  readonly loadTeamsOneEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LoadTeamsOne),
    mergeMap((action) => this.getTeamByTeamId$(action.id)),
  ));

  readonly loadSessionsOneEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LoadSessionsOne),
    exhaustMap((action) => this.getTeamSessions$(action.teamId)),
  ));

  readonly loadSeasonsOneEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LoadSeasonsOne),
    mergeMap((action) => this.getTeamSeasons$(action.teamId)),
  ));

  readonly loadSeasonGroupsOneEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LoadSeasonGroupsOne),
    mergeMap((action) => this.getTeamSeasonGroups$(action.teamId)),
  ));

  getTeamsByUserId$(userId: string) {
    return this.teamsService.getTeamsByUserId$(userId).pipe(
      map((teams) => LoadTeamsByUserIdSuccess({ teams })),
      catchError((error: unknown) => {
        return of(LoadTeamsByUserIdError({ error }));
      }),
    );
  }

  getTeamByTeamId$(teamId: string) {
    return this.teamsService.getTeamByTeamId$(teamId).pipe(
      map((team) => LoadTeamsOneSuccess({ team })),
      catchError((error: unknown) => {
        return of(LoadTeamsByUserIdError({ error }));
      }),
    );
  }

  getExternalTeamsByUserId$(userId: string) {
    return this.teamsService.getExternalTeamsByUserId$(userId).pipe(
      map((externalTeams) => LoadExternalTeamsByUserIdSuccess({ externalTeams })),
      catchError((error: unknown) => {
        return of(LoadExternalTeamsByUserIdError({ error }));
      }),
    );
  }

  getTeamRoles$() {
    return this.teamsService.getTeamRoles$().pipe(
      map((roles) => LoadTeamRolesSuccess({ roles })),
      catchError((error: unknown) => {
        return of(LoadTeamRolesError({ error }));
      }),
    );
  }

  getTeamsByLatLng$(lat:number, lng: number, bounds?: google.maps.LatLngBoundsLiteral) {
    return this.teamsService.getTeamsByLocation$(lat, lng, bounds).pipe(
      map((teams) => LoadTeamsListSuccess({ teams })),
      catchError((error: unknown) => {
        return of(LoadTeamsListError({ error }));
      }),
    );
  }

  getTeamSessions$(teamId: string) {
    return this.teamSessionService.getAll(teamId).pipe(
      map((sessions: any[]) => LoadSessionsSuccess({ sessions, teamId })),
      catchError((error: unknown) => {
        return of(LoadSessionsError({ error }));
      }),
    );
  }

  getTeamSeasons$(teamId: string) {
    return this.teamSeasonService.getAll(teamId).pipe(
      map((seasons) => LoadSeasonsSuccess({ seasons, teamId })),
      catchError((error: unknown) => {
        return of(LoadSeasonsError({ error }));
      }),
    );
  }

  getTeamSeasonGroups$(teamId: string) {
    return this.teamSeasonService.getAllGroups(teamId).pipe(
      map((seasonGroups) => LoadSeasonGroupsSuccess({ seasonGroups, teamId })),
      catchError((error: unknown) => {
        return of(LoadSeasonsError({ error }));
      }),
    );
  }
}
