<div>
  <h1>{{formDetails.header}}</h1>
</div>
<div class="form-wrapper">
  <form [formGroup]="profileForm">
    <div class="account-status-wrapper">
      <p>Choose an Avatar (Optional)</p>
      <app-select-avatar-button [avatarFormControl]="photoURL" [photoUrlFromSocialMedia]="photoUrlFromSocialMedia">
      </app-select-avatar-button>
    </div>
    <br />
    <div class="controls-container">
      <div [ngClass]="{'flex-col': formDetails.layout === 'vertical'}">
        <!-- Display Name-->
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Display Name</mat-label>
          <input matInput required type="text" class="displayName" formControlName="displayName"
            [minlength]="constraints.displayName.minLength" [maxlength]="constraints.displayName.maxLength" />
          <mat-hint align="end">{{profileForm.controls.displayName.value?.length ?? 0}} /
            {{constraints.displayName.maxLength}}</mat-hint>
        </mat-form-field>

        <!-- Full Name -->
        <div class="full-name">
          <mat-form-field class="form-field full-name-field" appearance="outline">
            <mat-label>Full Name</mat-label>
            <input matInput required type="text" class="fullName" formControlName="fullName"
              [minLength]="constraints.fullName.minLength" [maxLength]="constraints.fullName.maxLength" />
            <mat-hint align="end" class="character-count">{{profileForm.controls.fullName.value?.length ?? 0}} /
              {{constraints.fullName.maxLength}}</mat-hint>
            @if ( getWidth() > 483) {
            <mat-hint class="privacy-hint">We respect your privacy. Your full name is kept private and won't be shared with anyone. Only your display name will appear on Gametime Hero.</mat-hint>
            } @else {
            <mat-hint class="privacy-hint">Your full name is kept private and won't be shared with anyone.</mat-hint>  
            }
          </mat-form-field>
        </div>
      </div>
      <div [ngClass]="{'flex-col': formDetails.layout === 'vertical'}">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput required maxlength="100" type="email" class="email" formControlName="email" email />
        </mat-form-field>
        
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Go-To Area to Play</mat-label>
          <input required class="city-input" matInput #cityInput ngx-gp-autocomplete formControlName="cityName"
            (change)="clearCityInput()" #placesRef="ngx-places" [options]="options"
            (onAddressChange)="onAutocompleteSelected($event)" />
            @if(cityInput.value){
              <mat-hint>Great choice! {{cityInput.value}} is now your go-to spot for all things fun and active!</mat-hint>
            }
        </mat-form-field>
      </div>

      @if(isNewUser){
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>What will you MOSTLY use Gametime Hero for?</mat-label>
          <mat-select formControlName="joinReason" multiple>
            <mat-option value="Organize">To Organize Events/Games</mat-option>
            <mat-option value="Find-people">Find new Friends/Partners/Teammates</mat-option>
            <mat-option value="Find-people">Help Manage/build my Community</mat-option>
            <mat-option value="Substitute">List Myself as a Sub/Find More Events to Join</mat-option>
          </mat-select>
        </mat-form-field>
      }

      @if(isNewUser){
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>What do you like to enjoy? (Pick atleast one)</mat-label>
          <mat-select formControlName="games" multiple>
            @for(game of gamesList; track game){
              <mat-option [value]="game">{{game.label}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      <div [ngClass]="{'flex-col': formDetails.layout === 'vertical'}">
        <mat-form-field class="form-field" appearance="outline" *ngIf="genders">
          <mat-label>What gender do you identify with?</mat-label>
          <mat-select class="gender" formControlName="gender" #smallGendersSelect [value]="gender">
            <mat-option *ngFor="let gender of genders" value="{{ gender.label }}">{{ gender.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!initialSignUp" class="form-field" appearance="outline">
          <mat-label>Which pronouns do you prefer?</mat-label>
          <mat-select formControlName="pronouns">
            <mat-option>None</mat-option>
            <mat-option value="He/His">He/His</mat-option>
            <mat-option value="She/Her">She/Her</mat-option>
            <mat-option value="They/Them">They/Them</mat-option>
            <mat-option value="Unspecified">Unspecified</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <button class="get-started-button" type="button" (click)="setAdditionalInfo()" color="primary"
      [disabled]="isFormValid()|| loading()" mat-flat-button>
      @if(loading()){
        <mat-spinner [diameter]="36" color="accent"></mat-spinner>
      } @else {
        {{ isNewUser ? 'Setup Account' : 'Save Changes' }}
      }
    </button>
  </form>
</div>