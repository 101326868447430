import { Component, computed, inject, input, NgZone, OnDestroy, OnInit, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { GalleryModule } from '@ark/components/organizer-website/models/website-models';

@Component({
  selector: 'ark-gallery-module',
  standalone: true,
  imports: [
    MatIconModule,
    RouterLink,
  ],
  templateUrl: './gallery-module.component.html',
  styleUrl: './gallery-module.component.scss'
})
export class GalleryModuleComponent implements OnInit, OnDestroy {
  moduleData = input.required<GalleryModule>();

  gallerySlides = computed(() => this.moduleData().galleryItems);
  activeSlide = signal<number>(0);
  isHovering = signal<boolean>(false);
  slideLastChanged = signal<number>(Date.now());
  slideInterval = 5000;
  galleryInterval: any;

  ngZone = inject(NgZone);

  ngOnInit(): void {
    this.startGalleryInterval();
  }

  startGalleryInterval() {
    if (!this.gallerySlides().length) return;
    this.ngZone.runOutsideAngular(() => {
      this.galleryInterval = setInterval(() => {
        if (this.slideLastChanged() - 1 + this.slideInterval < Date.now()) this.setNextSlide();
      }, this.slideInterval);
    });
  }

  stopGalleryInterval() {
    if (!this.galleryInterval) return;
    clearInterval(this.galleryInterval);
  }

  ngOnDestroy(): void {
    this.stopGalleryInterval();
  }

  setActiveSlide(index: number) {
    this.activeSlide.set(index);
    this.slideLastChanged.set(Date.now());
  };

  setNextSlide() {
    const activeSlide = this.activeSlide();
    const nextSlide = activeSlide === this.gallerySlides().length - 1 ? 0 : activeSlide + 1;
    this.setActiveSlide(nextSlide);
  }

  setPreviousSlide() {
    const activeSlide = this.activeSlide();
    const previousSlide = activeSlide === 0 ? this.gallerySlides().length - 1 : activeSlide - 1;
    this.setActiveSlide(previousSlide);
  }
}
