import { Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TestimonialsModule } from '@ark/components/organizer-website/models/website-models';

@Component({
  selector: 'ark-testimonials-module',
  standalone: true,
  imports: [
    MatIconModule,
  ],
  templateUrl: './testimonials-module.component.html',
  styleUrl: './testimonials-module.component.scss'
})
export class TestimonialsModuleComponent {
  moduleData = input.required<TestimonialsModule>();

  testimonials = computed(() => this.moduleData().testimonials || []);
  description = computed(() => this.moduleData().description || '');
}
