import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ANALYTICS_CONSTS,
  EndorsementsService,
  GthAnalyticsService,
  GthAuthService,
} from '@gth-legacy';
import { DuprScraperService } from '@gth-legacy/services/dupr-scrapper-service';
import { GthSportAvailability } from '@index/interfaces';
import { GthGameTypeModel, GthUserModel } from '@sentinels/models';
import { SrvApiService } from '@sentinels/services/api.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { InstructionsDialogComponent } from './instructions-dialog.component';

@Component({
  selector: 'app-more-info-sports',
  templateUrl: './more-info-sports.component.html',
  styleUrls: ['./more-info-sports.component.scss'],
})
export class MoreInfoSportsComponent implements OnInit, OnDestroy {
  formGroup!: FormGroup;
  user$ = this.authService.userModel$.pipe(
    switchMap(async (user) => {
      if (user) {
        user.endorsements = await this.endorsementsService.getEndorsements(user.uid);
      }
      return user;
    }),
  );
  offAvailabilities: GthSportAvailability[];
  onAvailabilities: GthSportAvailability[];
  hiddenToggles = false;
  duprRating = '';
  showDuprLoading = false;
  gameTypes: GthGameTypeModel[];

  private subscription = new Subscription();

  constructor(
    private endorsementsService: EndorsementsService,
    private duprScraper: DuprScraperService,
    private analytics: GthAnalyticsService,
    private authService: GthAuthService,
    private usersService: UserService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private api: SrvApiService,
    private dialog: MatDialog,
  ) { }

  async ngOnInit() {
    this.hiddenToggles = false;
    this.gameTypes = await this.api.gameTypes.listAsync();
    this.getAvailabilities(this.gameTypes).subscribe((data) => {
      this.offAvailabilities = data.offAvailabilities;
      this.onAvailabilities = data.onAvailabilities;
    });

    const user$ = this.authService.userModel$;
    this.subscription.add(
      user$.subscribe((user) => {
        if (!user) {
          return;
        }
        this.duprRating = user.duprId ?? '';
        this.initializeForm();
      }),
    );
  }

  openInstructionsDialog() {
    this.dialog.open(InstructionsDialogComponent);
  }

  getAvailabilities(gameTypes: GthGameTypeModel[]) {
    return this.user$.pipe(
      map((user) => {
        if (!gameTypes) {
          return undefined;
        }
        const onAvailabilities = []; // Array for availabilities with toggle set to true
        const offAvailabilities = []; // Array for availabilities with toggle set to false

        gameTypes
          .sort((a, b) => a.label.localeCompare(b.label))
          .filter((g) => g.label !== 'Other')
          .forEach((g) => {
            const availability = this.getSportAvailability(g.label);
            const userAvail = user.sportAvailability
              ? user.sportAvailability.find((a) => a.sport === g.label)
              : undefined;
            if (!userAvail) {
              availability.toggle
                ? onAvailabilities.push(availability)
                : offAvailabilities.push(availability);
            } else {
              availability.skill = userAvail.skill;
              availability.toggle = userAvail.toggle;
              availability.roles = userAvail.roles;
              availability.toggle
                ? onAvailabilities.push(availability)
                : offAvailabilities.push(availability);
            }
          });

        return { onAvailabilities, offAvailabilities };
      }),
    );
  }

  validateDuprInput(control: AbstractControl): ValidationErrors | null {
    const duprIdRegex = /(?:\/(?:profile\/)?)(\d+)(?:\/|$)/;
    const value = control.value || '';

    // Match the DUPR ID using the regex
    const match = value.match(duprIdRegex);

    // If there is a match, extract the ID and update the form control value
    if (match) {
      const duprId = match[1]; // The first capturing group (index 1) contains the DUPR ID
      control.setValue(duprId);
    }

    // Perform the other validations
    const validators = [Validators.required, Validators.pattern(/^\d{10}$/)];
    const errors = validators.map((validator) => validator(control));

    // Merge the errors into a single object
    const errorObj = errors.reduce((acc, error) => {
      return error ? { ...acc, ...error } : acc;
    }, {});

    return Object.keys(errorObj).length ? errorObj : null;
  }

  private initializeForm() {
    this.formGroup = this.formBuilder.group({
      duprId: [
        this.duprRating,
        [
          Validators.required,
          this.validateDuprInput.bind(this),
          Validators.pattern(/^\d{10}$/),
        ],
      ],
    });
  }

  isDuprIdValid(): boolean {
    const control = this.formGroup?.get('duprId'); // Add the safe navigation operator '?'
    return control?.valid ?? false;
  }

  async onUpdateDuprRating(user: GthUserModel) {
    if (this.formGroup.invalid) {
      this.snackbar.open('Enter a valid DUPR Id');
      return;
    }

    this.showDuprLoading = true;
    const duprIdStr = this.duprRating ? this.duprRating.toString() : '';
    const duprId = parseInt(duprIdStr, 10);

    if (isNaN(duprId)) {
      this.snackbar.open('Enter a valid DUPR Id');
      this.showDuprLoading = false;
      return;
    }
    const scrapeData = await this.duprScraper.scrapeData(user.uid, duprIdStr);

    if (scrapeData.error) {
      this.snackbar.open('Error updating DUPR Id');
      this.showDuprLoading = false;
      return;
    }
    user.setDuprId(duprId);
    user.setDuprRatings([scrapeData.doubles, scrapeData.singles]);

    this.analytics.logEvent(ANALYTICS_CONSTS.settingsUpdated, {
      context: 'more-info-dupr-rating',
    });
    const success = await this.usersService.updateUser(user);
    if (success) {
      this.showDuprLoading = false;
      this.snackbar.open('Successfully updated DUPR Id');
    } else {
      this.showDuprLoading = false;
      this.snackbar.open('Error updating DUPR Id');
    }
  }

  async onSportsAvailabilityChange(
    user: GthUserModel,
    sportAvailability: GthSportAvailability,
    first: GthSportAvailability[],
    second: GthSportAvailability[],
    state: boolean,
  ) {
    const availabilities = user.sportAvailability ?? [];
    const index = availabilities.findIndex((a) => a.sport === sportAvailability.sport);

    if (!sportAvailability.toggle) {
      if (index >= 0) {
        availabilities.splice(index, 1);
      }
    } else {
      if (index >= 0) {
        availabilities[index] = {
          ...sportAvailability,
        };
      } else {
        availabilities.push(sportAvailability);
      }
    }

    if (state !== sportAvailability.toggle) {
      for (let i = first.length - 1; i >= 0; i--) {
        if (first[i].sport === sportAvailability.sport) {
          first.splice(i, 1);
        }
      }

      second.push(sportAvailability);
    }

    user.sportAvailability = availabilities;

    this.analytics.logEvent(ANALYTICS_CONSTS.settingsUpdated, {
      context: 'more-info-sports',
    });

    await this.usersService.updateUser(user).then(async (success) => {
      if (!success) {
        console.log('Error updating user sports availability');
        return;
      }

      /** Check user sport availability changes and sync endorsements */
      await this.endorsementsService.syncPassionEndorsements(user);
    });
  }

  private getSportAvailability(sport: string): GthSportAvailability {
    return { sport: sport, skill: null, toggle: false };
  }

  toggleHiddenToggles(event: MouseEvent) {
    this.hiddenToggles = !this.hiddenToggles;

    event.preventDefault();
  }

  onSaveBtnClick() {
    this.snackbar.open('Successfully saved changes', 'Dismiss', { duration: 5000 });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
