import { createReducer, on } from '@ngrx/store';
import {
  loadFavorites, loadFavoritesFailure, loadFavoritesSuccess,
} from '@sentinels/state/features/favorites/actions';
import {
  initialFavoritesState,
} from '@sentinels/state/features/favorites/state';
import { CurrentState } from '@sentinels/state/state';

export const favoritesReducer = createReducer(
  initialFavoritesState,
  on(
    loadFavorites,
    (state, action) => ({
      ...state,
      state: CurrentState.Loading,
      userId: action.userId,
    }),
  ),
  on(
    loadFavoritesSuccess,
    (state, action) => ({
      ...state,
      state: CurrentState.Success,
      favorites: action.favorites,
    }),
  ),
  on(
    loadFavoritesFailure,
    (state, action) => ({
      ...state,
      state: CurrentState.Error,
      error: action.error,
    }),
  ),
);
