import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  ANALYTICS_CONSTS,
  GthAnalyticsService,
  GthAuthService,
  GthRemoteConfigService,
} from '@gth-legacy';
import { MessageUpdateRequest, Team } from '@index/interfaces';
import { GthConversationModel, GthUserModel } from '@sentinels/models';
import { ConversationsService } from '@sentinels/services/firebase/conversations.service';
import { TeamRosterService } from '@sentinels/services/firebase/team-roster.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import { APP_ROUTES } from '@shared/helpers';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { MessageService } from '../../../../messages/services/message.service';
import { DeleteUserDialogComponent } from './delete-user';


@Component({
  selector: 'app-more-info-privacy',
  templateUrl: './more-info-privacy.component.html',
  styleUrls: ['./more-info-privacy.component.scss'],
})
export class MoreInfoPrivacyComponent implements OnInit, OnDestroy {
  deleteConfirmationInput = '';
  user$?: Observable<GthUserModel>;
  blockedConversations$?: Observable<GthConversationModel[]>;

  currentPlayerTeams$ = new BehaviorSubject<null | Team[]>(null);
  displayBadges = false;

  private subscriptions = new Subscription();

  constructor(
    private conversationsService: ConversationsService,
    private teamRosterService: TeamRosterService,
    private remoteConfig: GthRemoteConfigService,
    private messageService: MessageService,
    private analytics: GthAnalyticsService,
    private usersService: UserService,
    private snackBar: MatSnackBar,
    private auth: GthAuthService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.user$ = this.auth.userModel$.pipe(
      map((user) => {
        if (!user) return undefined;
        user.privacySettings = {
          messagesFromAnyone: user.privacySettings?.messagesFromAnyone ?? false,
          publicBadges: user.privacySettings?.publicBadges ?? false,
          publicOnlineStatus: user.privacySettings?.publicOnlineStatus ?? false,
          publicUpcomingGames: user.privacySettings?.publicUpcomingGames ?? false,
        };
        return user;
      }),
    );

    this.blockedConversations$ = this.userConversations$.pipe(
      map((conversations) => {
        return conversations.filter((conversation) => !!conversation.blocked);
      }),
    );
    this.displayBadges = await this.remoteConfig.getBadgesEnabled();

    this.subscriptions.add(
      this.auth.userModel$.pipe(
        take(1),
        switchMap(async (user) => {
          return this.teamRosterService.getTeamsByUserId(user?.uid).then(
            (teams) => this.currentPlayerTeams$.next(teams),
          );
        }),
      ).subscribe(),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get APP_ROUTES() {
    return APP_ROUTES;
  }

  async onPrivacySettingsChange(user: GthUserModel, event: MatSlideToggleChange) {
    switch (event.source.id) {
      case 'messagesFromAnyone':
        user.privacySettings = {
          ...user.privacySettings,
          messagesFromAnyone: event.checked,
        };
        break;
      case 'publicBadges':
        user.privacySettings = {
          ...user.privacySettings,
          publicBadges: event.checked,
        };
        break;
      case 'publicOnlineStatus':
        user.privacySettings = {
          ...user.privacySettings,
          publicOnlineStatus: event.checked,
        };
        break;
      case 'publicUpcomingGames':
        user.privacySettings = {
          ...user.privacySettings,
          publicUpcomingGames: event.checked,
        };
        break;
      default:
        console.log('default case triggered', event.source.id);
    }
    this.analytics.logEvent(ANALYTICS_CONSTS.settingsUpdated, {
      context: 'more-info-privacy',
    });

    await this.usersService.updateUser(user);
  }

  private get userConversations$(): Observable<GthConversationModel[]> {
    return this.messageService.getConversations();
  }

  onUnblockButtonClick(user: GthUserModel, conversation: GthConversationModel) {
    if (!user || !conversation) return;
    this.unblockConversation(conversation.id, user.uid, !conversation.blocked);
    // Optimistic Update
    conversation.block(!conversation.blocked);
  }

  async unblockConversation(conversationId: string, userId: string, blocked: boolean) {
    const contract: MessageUpdateRequest = {
      conversationId: conversationId,
      userId: userId,
      blocked,
    };

    this.analytics.logEvent(ANALYTICS_CONSTS.settingsUpdated, {
      context: 'more-info-privacy-unblock',
    });

    await this.conversationsService.update(contract);
  }


  onDeleteUserClick(user: GthUserModel) {
    const dialogConfig: MatDialogConfig = {
      data: this.currentPlayerTeams$.value,
    };
    this.analytics.logEvent(ANALYTICS_CONSTS.deleteUserPrompt);

    const dialogRef = this.dialog.open(DeleteUserDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.deleteUser(user);
      }
    });
  }

  async deleteUser(user: GthUserModel) {
    const userId = user.uid;

    const tasks = [
      this.usersService.deleteUser(userId),
      this.auth.deleteUser(userId),
    ];

    return Promise.all(tasks)
      .then(() => {
        this.snackBar.open('Account deleted successfully.', 'OK', { duration: 3000 });
        // Redirect to the home route
        this.router.navigate(['/']);
      }).catch((error) => {
        console.error('Failed to delete user:', error);
        // eslint-disable-next-line max-len
        this.snackBar.open('An error occurred while deleting your account.', 'OK', { duration: 3000 });
      });
  }
}
