/* eslint-disable max-len */
import { Routes } from '@angular/router';
import { OrganizerWebsitePageComponent } from '@ark/components/organizer-website/components/organizer-website-page/organizer-website-page.component';
import { OrganizerWebsiteLayoutComponent } from '@ark/components/organizer-website/layout/organizer-website-layout.component';
import { organizerWebsiteResolver } from '@ark/components/organizer-website/resolvers/organizer-website.resolver';
import { EmailVerifiedGuard } from '@shared/guard/email-verified-guard.service';

import { authRoutes } from './features/auth/auth-routing';
import { MoreInfoComponent } from './features/auth/views/more-info/more-info/more-info.component';
import { ErrorPageComponent } from './features/error-page/error-page.component';
import { MainHomeComponent } from './features/main/views/home.component';
import { CreateTeamComponent } from './features/teams-dashboard/shared/create-team/create-team.component';
import { GameInfoResolver } from './route-resolvers';
import { AuthGuard } from './shared/guard/auth.guard';
import { NewUserGuard } from './shared/guard/new-user.guard';

export const appRoutes: Routes = [
  {
    path: 'home',
    component: MainHomeComponent,
    canActivate: [NewUserGuard],
  },
  {
    path: 'teams/create',
    component: CreateTeamComponent,
  },
  {
    path: 'form',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./features/form/views/list/list.component').then((c) => c.ListComponent),
      },
      {
        path: 'build/:id',
        loadComponent: () =>
          import('./features/form/views/form-builder/form-builder.component').then(
            (c) => c.FormBuilderComponent,
          ),
      },
      {
        path: ':id',
        loadComponent: () =>
          import('./features/form/views/edit/edit.component').then((c) => c.EditComponent),
      },
      {
        path: ':id/submissions',
        loadComponent: () =>
          import('./features/form/views/submissions/submissions.component').then(
            (c) => c.SubmissionsComponent,
          ),
      },
      {
        path: ':id/:submissionId',
        loadComponent: () =>
          import('./features/form/views/edit/edit.component').then((c) => c.EditComponent),
      },
      {
        path: 'payment/:id/:submissionId',
        loadComponent: () =>
          import(
            './features/form/views/form-payments-manager/form-payments-manager.component'
          ).then((c) => c.FormPaymentsManagerComponent),
      },
    ],
  },
  {
    path: 'my-communities',
    loadComponent: () =>
      import('./features/my-community/my-community.component').then(
        (m) => m.MyCommunityComponent,
      ),
  },
  {
    path: 'teams-v3',
    loadChildren: () =>
      import('./features/teams-dashboard/teams-dashboard.module').then(
        (m) => m.TeamsDashboardModule,
      ),
  },

  ...authRoutes,

  {
    path: 'verify-email',
    loadComponent: () =>
      import('./features/verify-email/verify-email.component').then(
        (m) => m.VerifyEmailComponent,
      ),
  },
  {
    path: 'full-search',
    loadComponent: () =>
      import('./features/search/omni-full-screen/omni-full-screen.component').then(
        (c) => c.OmniFullScreenComponent,
      ),
  },
  {
    path: 'places/view/:id',
    loadComponent: () =>
      import(
        '../../../gth-legacy/src/lib/components/place-info/view-container.component'
      ).then((c) => c.ViewContainerComponent),
  },
  {
    path: 'places/poster',
    loadComponent: () =>
      import('./features/places/views/poster/poster.component').then((c) => c.PosterComponent),
  },
  {
    path: 'places/new',
    loadComponent: () =>
      import('./features/places/views/create-place/create-place.component').then(
        (c) => c.CreatePlaceComponent,
      ),
    canActivate: [NewUserGuard, EmailVerifiedGuard],
  },
  {
    path: 'places/edit/:id',
    loadComponent: () =>
      import('./features/places/views/edit-place/edit-place.component').then(
        (c) => c.EditPlaceComponent,
      ),
    canActivate: [NewUserGuard, EmailVerifiedGuard],
  },
  {
    path: 'discover/games',
    loadComponent: () =>
      import('./features/discover/discover.component').then((c) => c.AppDiscoverComponent),
  },
  {
    path: 'discover/games/:id',
    loadComponent: () =>
      import('./features/event-item/views/view/game-view.component').then(
        (m) => m.GameViewComponent,
      ),
    resolve: {
      gameData: GameInfoResolver,
    },
  },
  {
    path: 'evt/:id',
    loadComponent: () =>
      import('./features/event-item/views/view/game-view.component').then(
        (m) => m.GameViewComponent,
      ),
    resolve: {
      gameData: GameInfoResolver,
    },
  },
  {
    path: 'discover/games/:id/manage',
    loadComponent: () =>
      import('./features/event-item/views/manage/game-manage.component').then(
        (m) => m.GameManageComponent,
      ),
    resolve: {
      gameData: GameInfoResolver,
    },
  },
  {
    path: 'discover/players',
    loadComponent: () =>
      import('./features/discover/discover.component').then((c) => c.AppDiscoverComponent),
  },
  {
    path: 'create',
    loadChildren: () => import('./features/event-item/views/create-game/routes'),
    canActivate: [EmailVerifiedGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./features/admin/admin.module').then((m) => m.AdminComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'custom-website',
    loadChildren: () =>
      import('./features/custom-website/custom-website.module').then(
        (m) => m.CustomWebsiteModule,
      ),
  },
  {
    path: 'discover/teams',
    loadComponent: () =>
      import('./features/discover/discover.component').then((c) => c.AppDiscoverComponent),
  },
  {
    path: 'discover/places',
    loadComponent: () =>
      import('./features/discover/discover.component').then((c) => c.AppDiscoverComponent),
  },
  {
    path: 'more-info',
    component: MoreInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: MoreInfoComponent,
    canActivate: [AuthGuard, EmailVerifiedGuard],
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('./features/messages/messages.module').then((mod) => mod.MessagesComponentModule),
    canActivate: [NewUserGuard],
  },
  {
    path: 'invoices',
    loadComponent: () =>
      import('./features/invoices/invoices.component').then((m) => m.InvoicesComponent),
    canActivate: [NewUserGuard, EmailVerifiedGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./features/profile/containers/profile-container/profile-container.module').then(
        (mod) => mod.ProfileContainerModule,
      ),
    canActivate: [NewUserGuard],
  },
  {
    path: 'team',
    loadChildren: () =>
      import('./features/teams/views/team-website/team-website.module').then(
        (m) => m.TeamWebsiteModule,
      ),
  },
  {
    path: 'team/:id',
    loadComponent: () =>
      import('./features/teams/views/team-website-new/team-website.component').then(
        (m) => m.TeamWebsiteComponent,
      ),
  },
  {
    path: 'organization/:id',
    component: OrganizerWebsiteLayoutComponent,
    // canActivate: [organizerWebsiteGuard],
    resolve: {
      team: organizerWebsiteResolver,
    },
    children: [
      {
        path: '**',
        component: OrganizerWebsitePageComponent,
      },
    ],
  },
  {
    path: 'external-teams',
    loadChildren: () =>
      import('./features/teams/views/external-teams/external-teams.module').then(
        (mod) => mod.AppExternalTeamsModule,
      ),
    canActivate: [NewUserGuard],
  },
  {
    path: 'team-not-found',
    loadComponent: () =>
      import('./features/teams/views/not-found/not-found.component').then(
        (mod) => mod.NotFoundComponent,
      ),
    canActivate: [NewUserGuard],
  },
  {
    path: 'payment-success',
    loadComponent: () =>
      import(
        '../../../gth-legacy/src/lib/components/payment-success/payment-success.component'
      ).then((m) => m.PaymentSuccessComponent),
    canActivate: [NewUserGuard],
  },
  {
    path: 'transactions',
    loadComponent: () =>
      import('./features/transactions/transactions.component').then(
        (m) => m.TransactionsComponent,
      ),
    canActivate: [NewUserGuard],
  },
  {
    path: 'localconnect',
    loadComponent: () =>
      import(
        './features/local-connect/views/local-connect-view/local-connect-view.component'
      ).then((m) => m.LocalConnectViewComponent),
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: '**',
    component: ErrorPageComponent,
  },
];
