import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Invoice } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { GthInvoiceModel, GthUserModel } from '@sentinels/models';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import firebase from 'firebase/compat/app';
import { from, mergeMap, Observable, switchMap, toArray, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InvoicesService extends FirestoreService<GthInvoiceModel> {
  readonly basePath = 'invoices';

  constructor(
    firestore: AngularFirestore,
    private usersService: UserService,
  ) {
    super(firestore);
  }

  /**
   * Retrieves an Observable stream of GthInvoiceModel objects
   * representing invoices sent by a specific user.
   *
   * @param {string} userId - The ID of the user who sent the invoices.
   * @return {Observable<GthInvoiceModel[]>} An Observable emitting an
   * array of GthInvoiceModel objects, each representing an invoice sent
   * by the specified user.
   */
  getSentInvoicesByUserId$(userId: string): Observable<GthInvoiceModel[]> {
    const sentInvoicesRef = this.firestore.collection<Invoice>(DBUtil.Invoices, (ref) => {
      return ref.where('from', '==', userId);
    });
    return sentInvoicesRef.valueChanges({ idField: 'id' }).pipe(
      switchMap((invoices) => {
        return from(invoices).pipe(
          mergeMap((invoice) => this.convertInvoiceToGthInvoice$(invoice)),
          take(invoices.length),
          toArray(),
        );
      }),
    );
  }

  /**
   * Retrieves an Observable stream of GthInvoiceModel objects
   * representing invoices received by a specific user.
   *
   * @param {string} userId - The ID of the user who received the invoices.
   * @return {Observable<GthInvoiceModel[]>} An Observable emitting an
   * array of GthInvoiceModel objects, each representing an invoice
   * received by the specified user.
   */
  getReceivedInvoicesByUserId$(userId: string): Observable<GthInvoiceModel[]> {
    const receivedInvoicesRef = this.firestore.collection<Invoice>(DBUtil.Invoices, (ref) => {
      return ref.where('to', '==', userId);
    });
    return receivedInvoicesRef.valueChanges({ idField: 'id' }).pipe(
      switchMap((invoices) => {
        return from(invoices).pipe(
          mergeMap((invoice) => this.convertInvoiceToGthInvoice$(invoice)),
          take(invoices.length),
          toArray(),
        );
      }),
    );
  }

  /**
   * Retrieves an Observable stream of all GthInvoiceModel objects
   * (both sent and received) for a specific user.
   *
   * @param {string} userId - The ID of the user.
   * @return {Observable<GthInvoiceModel[]>} An Observable emitting
   * a combined array of GthInvoiceModel objects, representing both
   * sent and received invoices for the user.
   */
  getInvoicesByUserId$(userId: string): Observable<GthInvoiceModel[]> {
    const sentInvoices$ = this.getSentInvoicesByUserId$(userId);
    const receivedInvoices$ = this.getReceivedInvoicesByUserId$(userId);
    return zip([sentInvoices$, receivedInvoices$]).pipe(
      map(([sentInvoices, receivedInvoices]) => {
        return sentInvoices.concat(receivedInvoices);
      }),
    );
  }

  /**
   * Converts a plain Invoice object into a GthInvoiceModel object by
   * fetching the associated user information.
   *
   * @param {Invoice} invoice - The invoice to convert.
   * @return {Observable<GthInvoiceModel>} An Observable emitting a
   * GthInvoiceModel object with the user information populated.
   */
  convertInvoiceToGthInvoice$(invoice: Invoice): Observable<GthInvoiceModel> {
    const from$ = this.usersService.getUser$(invoice.from);
    const to$ = this.usersService.getUser$(invoice.to);

    return zip([from$, to$]).pipe(
      map(([from, to]) => {
        const gthInvoiceModel = new GthInvoiceModel(invoice.id, invoice);

        gthInvoiceModel.from = from;
        if (to) {
          gthInvoiceModel.to = to;
        } else {
          gthInvoiceModel.to = new GthUserModel('', {
            email: invoice.to,
            displayName: invoice.to,
            uid: '',
            createdAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
          });
        }

        return gthInvoiceModel;
      }),
    );
  }
}
