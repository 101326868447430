import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  ArkProgressiveFormLayout,
  ArkProgressiveFormLayoutItem,
  IArkProgressiveFormControlService,
} from '@ark';
import { SubscriptionType } from '@index/enums';
import { CreateTeamRequest, DefaultCity, Location } from '@index/interfaces';
import { GthGameTypeModel, GthTeamPlayerModel, GthUserModel } from '@sentinels/models';
import { TeamRosterService } from '@sentinels/services/firebase/team-roster.service';
import { TeamsService } from '@sentinels/services/firebase/teams.service';
import { APP_ROUTES, TEAMS_ROUTES } from '@shared/helpers';
import { geohashForLocation } from 'geofire-common';
import { BehaviorSubject, Subject, Subscription, take } from 'rxjs';

import { GthAuthService } from '../../../../../../../gth-legacy/src/public-api';
import { DEFAULT_LAYOUT } from './creation-layout';

@Injectable({
  providedIn: 'root',
})
export class CommunityForm implements IArkProgressiveFormControlService {
  private user?: GthUserModel;
  private subscription = new Subscription();
  loading = false;

  layout?: ArkProgressiveFormLayout;
  progress = new Subject<number>();
  activePageIndex = new BehaviorSubject<number>(0);
  invalid = false;
  completeText = 'Create Community';
  gameTypeOptions: any[];

  formGroup = new FormGroup({
    name: new UntypedFormControl('', Validators.required),
    communityType: new UntypedFormControl(''),
    gameType: new UntypedFormControl(''),
    location: new UntypedFormControl(''),
    skillLevels: new UntypedFormControl([]),
    ageRange: new UntypedFormControl('All Ages'),
    anyAgeRange: new UntypedFormControl(true),
    photoURL: new UntypedFormControl(''),
  });

  get currentPageIndex() {
    return this.activePageIndex.getValue();
  }

  constructor(
    private teamRosterService: TeamRosterService,
    private authService: GthAuthService,
    private teamsService: TeamsService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.validateUser();

    this.layout = DEFAULT_LAYOUT;
    this.activePageIndex.next(0);
    this.progress.next(0);
  }

  setGameTypes(gameTypes: GthGameTypeModel[]) {
    const ctrl = this.getControlByKey('gameType');
    if (!ctrl) return;
    ctrl.options = gameTypes.map((gt) => {
      return {
        key: gt.gameType.label,
        label: gt.label,
        withIconEnd: gt.icon,
      };
    });
  }

  validateUser() {
    this.subscription.add(
      this.authService.userModel$.subscribe((user) => {
        this.user = user;
      }),
    );
  }

  updateLayout(ctrl: ArkProgressiveFormLayout): void {
    this.layout = ctrl;
  }

  setActivePageIndex(index: number): void {
    if (this.layout && index >= 0 && index < this.layout.pages.length) {
      this.activePageIndex.next(index);
      this.calculateProgress(index);
    }
  }

  next(): number {
    if (this.layout) {
      const currentIndex = this.currentPageIndex;
      if (currentIndex < this.layout.pages.length - 1) {
        this.setActivePageIndex(currentIndex + 1);
        const ctrlKey = this.layout.pages[currentIndex + 1].formKey;
        const ctrl = this.formGroup.get(ctrlKey as string);
        if (ctrl && !ctrl.validator && ctrlKey !== 'ageRange' && ctrl !== null) {
          ctrl.setValidators([Validators.required]);
          ctrl.updateValueAndValidity();
        }
        return currentIndex + 1;
      }
    }
    return this.currentPageIndex;
  }

  previous(): number {
    if (this.layout) {
      const currentIndex = this.currentPageIndex;
      if (currentIndex > 0) {
        const ctrlKey = this.layout.pages[currentIndex].formKey;
        const ctrl = this.formGroup.get(ctrlKey as string);
        if (ctrl && ctrlKey != 'photoURL') {
          ctrl.clearValidators();
          ctrl.updateValueAndValidity();
          this.setActivePageIndex(currentIndex - 1);
        }
        return currentIndex - 1;
      }
    }
    return this.currentPageIndex;
  }

  setPropValue(key: unknown, val: unknown): void {
    let ctrl: AbstractControl | null = null;
    const value = val;
    switch (key) {
      case 'anyAgeRange':
        {
          ctrl = this.formGroup.get('ageRange');
          const auxCtrl = this.formGroup.get('ageRange');
          if (auxCtrl) {
            auxCtrl.setValue({
              min: 0,
              max: 110,
            });
          }
        }
        break;
      case 'ageRange': {
        const auxCtrl = this.formGroup.get('anyAgeRange');
        if (auxCtrl) {
          auxCtrl.setValue(false as unknown as never);
        }
        ctrl = this.formGroup.get(key as string);
        break;
      }
      default:
        ctrl = this.formGroup.get(key as string);
    }

    if (!ctrl) {
      console.error(`Error finding control by name: '${key}'`);
      return;
    }
    ctrl.setValue(value);
  }

  disable(): void {
    this.invalid = true;
  }

  enable(): void {
    this.invalid = false;
  }

  async onComplete(): Promise<void> {
    if (!this.user) return;

    try {
      this.loading = true;

      const owner = new GthTeamPlayerModel('', '', {
        player: this.user.model,
        role: 'Owner',
        rsvp: true,
      });
      const location = this.getLocation();

      const createTeamRequest: CreateTeamRequest = {
        name: this.formGroup.controls.name.value,
        description: '',
        photoURL: this.formGroup.controls.photoURL.value,
        sport: this.formGroup.controls.gameType.value,
        roster: [owner],
        discoverable: false,
        subscription: SubscriptionType.ROOKIE,
        ageRange: this.formGroup.controls.ageRange.value,
        location,
        skillLevel: this.formGroup.controls.skillLevels.value,
      };
      const teamID = await this.teamsService.createTeam(createTeamRequest);

      if (!teamID) {
        this.snackBar.open('Something went wrong creating team. -no', 'OK');
        this.loading = false;
        return;
      }
      const rosterUpdate = await this.teamRosterService.addPlayer({
        teamId: teamID,
        userId: this.user.id,
        role: 'Owner',
      });

      if (!rosterUpdate) {
        this.snackBar.open('Something went wrong creating team.', 'OK');
        this.loading = false;
        return;
      }

      this.snackBar.open(
        'Team Created. Click "My Communities" If you are not redirected in a few seconds',
        'My Communities', {
        duration: 20000,
      }).onAction().pipe(take(1))
      .subscribe(async () => {
        await this.router.navigate([APP_ROUTES.MyCommunities]);
        this.snackBar.dismiss();
      });

      await this.router.navigate([APP_ROUTES.TeamsV3, teamID, TEAMS_ROUTES.DASHBOARD]);
      this.snackBar.dismiss();
    } catch {
      this.snackBar.open('Something went wrong creating team.', 'OK');
    } finally {
      this.loading = false;
    }
  }

  private calculateProgress(index: number): void {
    if (this.layout) {
      const progress = ((index + 1) / this.layout.pages.length) * 100;
      this.progress.next(progress);
    }
  }

  getLocation() {
    const place = this.formGroup.controls.location.value as google.maps.places.PlaceResult;
    const lat = place.geometry ? place.geometry.location.lat() : (place as DefaultCity).lat;
    const lng = place.geometry ? place.geometry.location.lng() : (place as DefaultCity).lng;
    const hash = geohashForLocation([lat, lng]);

    return {
      lat,
      lng,
      hash,
      formattedAddress: place.name,
    } as Location;
  }

  private getControlByKey(key: string): ArkProgressiveFormLayoutItem | undefined {
    if (!this.layout?.pages) return undefined;
    for (const page of this.layout.pages) {
      const ctrl = page.collection.find((control) => control.key === key);
      if (ctrl) return ctrl;
    }
    return undefined;
  }
}
