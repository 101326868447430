import { inject, Injectable } from '@angular/core';
import { NotificationType } from '@index/enums';
import { Notification } from '@index/interfaces';
import { NotificationModel } from '@index/models/notifications';
import {
  GthBadgeModel,
  GthConversationModel,
  GthEventItemModel,
  GthNotificationModel,
  GthTeamModel,
  GthUserModel,
} from '@sentinels/models';
import { BadgesService } from '@sentinels/services/firebase/badges.service';
import { EventItemService } from '@sentinels/services/firebase/event-items.service';
import { TeamRosterService } from '@sentinels/services/firebase/team-roster.service';
import { TeamsService } from '@sentinels/services/firebase/teams.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import { Timestamp } from 'firebase/firestore';
import {
  catchError,
  EMPTY,
  first,
  forkJoin,
  from,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  take,
  timeout,
} from 'rxjs';

import { MessageService } from '../../../../../gth/src/app/features/messages/services/message.service';
import { FirestoreService } from '../core/firebase.service';

export type NotificationMetadata = {
  joiner?: Observable<GthUserModel | undefined>;
  event?: Observable<GthEventItemModel | undefined>;
  user?: Observable<GthUserModel | undefined>;
  conversation?: Observable<GthConversationModel | undefined>;
  team?: Observable<GthTeamModel | undefined>;
  badge?: Observable<GthBadgeModel | null>;
};

const ACCEPTED_NOTIFICATION_TYPES = [
  NotificationType.EVENT_JOINER_PENDING_CREATOR,
  NotificationType.EVENT_JOINER_APPROVED,
  NotificationType.EVENT_JOINER_DROPPED,
  NotificationType.EVENT_CANCELLED,
  NotificationType.EVENT_JOINER_PENDING_JOINER,
  NotificationType.TEAM_REMINDER,
  NotificationType.TEAM_NEW_MEMBER,
  NotificationType.TEAM_JOINER_PENDING_CREATOR,
  NotificationType.TEAM_JOINER_PENDING_JOINER,
  NotificationType.TEAM_JOINER_APPROVED,
  NotificationType.TEAM_JOINER_DENIED,
  NotificationType.TEAM_JOINER_DROPPED,
  NotificationType.NEW_BADGE,
  NotificationType.RATE_PLAYER,
  NotificationType.RATE_HOST,
  NotificationType.NEW_MESSAGE,
  NotificationType.PLAIN_TEXT,
  NotificationType.ENDORSE_PLAYER,
  NotificationType.NEW_CONNECTION,
  NotificationType.TEAM_ANNOUNCEMENT,
];

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends FirestoreService<NotificationModel> {
  protected basePath: string = 'users';

  private messageService = inject(MessageService);
  private badgesService = inject(BadgesService);
  private usersService = inject(UserService);
  private eventsService = inject(EventItemService);
  private teamsService = inject(TeamsService);
  private teamRosterService = inject(TeamRosterService);

  private metaDataCache: { [notificationId: string]: NotificationMetadata } = {};

  getNotifications$(userId: string): Observable<GthNotificationModel[]> {
    return this.doc(userId)
      .collection('notifications', (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges({ idField: 'id' })
      .pipe(
        switchMap((notifications) => {
          if (!notifications || notifications.length === 0) {
            return of([]);
          }
          const notifs$ = notifications.map((notification) => {
            if (notification.createdAt && (notification.createdAt as Timestamp).toDate) {
              notification.createdAt = (notification.createdAt as Timestamp).toDate();
            }
            if (!this.metaDataCache[notification.id]) {
              return this.getMetaData(notification as Notification, userId);
            }
            notification.metadata = this.metaDataCache[notification.id];
            const notificationModel = new GthNotificationModel(
              notification.id,
              notification as Notification,
            );
            return of(notificationModel);
          });
          return forkJoin(notifs$);
        }),
        map((notifications) => {
          const filteredNotifications: GthNotificationModel[] = [];
          for (const notification of notifications) {
            switch (notification.type) {
              case NotificationType.RATE_PLAYER:
                // Filter out duplicate Event Rating notifications
                const existingNotification = filteredNotifications.find(
                  (n) => n.eventItem === notification.eventItem,
                );
                if (!existingNotification) {
                  filteredNotifications.push(notification);
                }
                break;
              default:
                filteredNotifications.push(notification);
                break;
            }
          }
          // If we don't have a modal to display the notification details, filter it out
          return filteredNotifications.filter((n) =>
            ACCEPTED_NOTIFICATION_TYPES.includes(n.type),
          );
        }),
        startWith([]),
        catchError(() => {
          return EMPTY;
        }),
      );
  }

  readNotification(userId: string, notificationId: string) {
    return from(
      this.doc(userId).collection('notifications').doc(notificationId).update({ read: true }),
    );
  }

  addNotification$(userId: string, notification: NotificationModel) {
    const id = this.firestore.createId();

    return from(
      this.doc(userId).collection('notifications').doc(id).set({
        type: notification.type,
        metadata: notification.metadata,
        read: notification.read,
        createdAt: new Date(),
      }),
    );
  }

  addManyNotifications$(userIds: string[], notification: NotificationModel) {
    const observables = userIds.map((userId) => this.addNotification$(userId, notification));
    return forkJoin(observables);
  }

  removeNotification(userId: string, notificationId: string) {
    return from(this.doc(userId).collection('notifications').doc(notificationId).delete());
  }

  clearNotifications(userId: string) {
    return from(
      this.doc(userId)
        .collection('notifications')
        .get()
        .forEach((snapshot) => {
          snapshot.docs.forEach((doc) => doc.ref.delete());
        }),
    );
  }

  markAllNotificationsAsRead(userId: string) {
    return from(
      this.doc(userId)
        .collection('notifications')
        .get()
        .forEach((snapshot) => {
          snapshot.docs.forEach((doc) => doc.ref.update({ read: true }));
        }),
    );
  }

  markNotificationAsUnread(userId: string, notificationId: string) {
    return this.doc(userId)
      .collection('notifications')
      .doc(notificationId)
      .update({ read: false });
  }

  getMetaData(notification: Notification, userId: string): Observable<GthNotificationModel> {
    const requests = [];
    const requestProps = [];

    if (notification.metadata?.joinerId) {
      // eslint-disable-next-line max-len
      const joiner$ = this.usersService
        .getUserById$(notification.metadata.joinerId as string)
        .pipe(take(1));
      requests.push(joiner$);
      requestProps.push('joiner');
    }

    if (notification.metadata?.event) {
      const event$ = from(
        this.eventsService.list({
          eventItemId: (notification.metadata.event as string) ?? 'none',
        }),
      ).pipe(
        first(),
        map((eventlist) => {
          if (!eventlist) {
            return undefined;
          }
          return eventlist[0];
        }),
      );
      requests.push(event$);
      requestProps.push('event');
    }

    if (notification.metadata?.user) {
      const user$ = this.usersService
        .getUserById$(notification.metadata.user as string)
        .pipe(take(1));
      requests.push(user$);
      requestProps.push('user');
    }

    if (notification.metadata?.creatorId) {
      const user$ = this.usersService
        .getUserById$(notification.metadata.creatorId as string)
        .pipe(take(1));
      requests.push(user$);
      requestProps.push('user');
    }

    if (notification.metadata?.actorId) {
      const user$ = this.usersService
        .getUserById$(notification.metadata.actorId as string)
        .pipe(take(1));
      requests.push(user$);
      requestProps.push('user');
    }

    if (notification.metadata?.conversation) {
      const conversation$ = this.messageService
        .getConversation(notification.metadata.conversation as string, userId)
        .pipe(take(1));
      requests.push(conversation$);
      requestProps.push('conversation');
    }

    if (notification.metadata?.team) {
      const team$ = this.teamsService
        .getTeamByTeamId$(notification.metadata.team as string)
        .pipe(
          map((team) => (team ? new GthTeamModel(team.id, team) : undefined)),
          switchMap(async (team) => {
            if (!team) return undefined;
            team.roster = await this.teamRosterService.getUsersByTeamId(team.id);
            return team;
          }),
          take(1),
        );
      requests.push(team$);
      requestProps.push('team');
    }

    if (notification.metadata?.badge) {
      // eslint-disable-next-line max-len
      const badge$ = this.badgesService
        .getBadgeById$(notification.metadata.badge as string)
        .pipe(take(1));
      requests.push(badge$);
      requestProps.push('badge');
    }
    return forkJoin(requests).pipe(
      timeout(5000),
      map((responses) => {
        const partialMetadata: NotificationMetadata = {};
        responses.forEach((response, i) => {
          const key = requestProps[i];
          partialMetadata[key] = response;
        });

        notification.metadata = {
          ...notification.metadata,
          ...partialMetadata,
        };
        this.metaDataCache[notification.id] = notification.metadata;
        const notificationModel = new GthNotificationModel(notification.id, notification);
        return notificationModel;
      }),
    );
  }
}
