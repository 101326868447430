<h1>Interests, Proficiency & Passions</h1>
@if (user$ | async; as user) {
<div class="sports-availability-container on">
  @if (onAvailabilities; as on) {
  <mat-list>
    @for (sport of on; track sport.sport) {
    @if (sport.toggle === true) {
    <mat-list-item>
      <div class="sport-availability selected" [style.display]="sport.toggle ? 'block' : 'none'">
        <app-sports-toggles class="app-sports-toggles selected" [sportsAvailability]="sport" [gameTypes]="gameTypes"
          (availabilityChange)="onSportsAvailabilityChange(user, $event, onAvailabilities, offAvailabilities, true)">
        </app-sports-toggles>
      </div>
    </mat-list-item>
    }
    }
  </mat-list>
  }

</div>
<div class="center-link">
  <button mat-button class="show-hide-link" (click)="toggleHiddenToggles($event)">
    {{ hiddenToggles ? 'Show all interests': 'Hide all interests' }}
  </button>
</div>
<div class="sports-availability-container">
  @if (!hiddenToggles) {
  @if (offAvailabilities; as off) {
  @for (sport of off; track sport.sport) {
  @if (sport.toggle === false) {
  <div class="sport-availability unselected" [style.display]="sport.toggle ? 'none' : 'block'">
    <app-sports-toggles class="app-sports-toggles" [sportsAvailability]="sport" [gameTypes]="[]"
      (availabilityChange)="onSportsAvailabilityChange(user, $event, offAvailabilities, onAvailabilities, false)">
    </app-sports-toggles>
  </div>
  }
  }
  }
  }
</div>
<!-- TODO: Disable button if no changes -->
<button mat-raised-button type="button" color="primary" class="save-btn" (click)="onSaveBtnClick()">Save</button>
<!--
  <div class="dupr-rating fx-column">
    <h2>
      DUPR Rating
      <mat-icon class="info-icon" (click)="openInstructionsDialog()">info_outline</mat-icon>
    </h2>
    <mat-form-field appearance="outline" [formGroup]="formGroup">
      <mat-label>DUPR Id</mat-label>
      <input matInput formControlName="duprId" />
      <mat-error *ngIf="!isDuprIdValid()">This is not a valid DUPR Id.</mat-error>
    </mat-form-field>

    <mat-progress-bar mode="indeterminate" *ngIf="showDuprLoading"></mat-progress-bar>
    <button mat-flat-button color="primary" (click)="onUpdateDuprRating(user)">
      Update DUPR Rating
    </button>
  </div>
  -->
}