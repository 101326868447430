import { Component, input } from '@angular/core';
import { BrandWithSeasonGroups, NavPageLink, SocialMediaLinks } from '../../models/website-models';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ark-organizer-website-footer',
  standalone: true,
  imports: [
    RouterLink,
  ],
  templateUrl: './organizer-website-footer.component.html',
  styleUrl: './organizer-website-footer.component.scss'
})
export class OrganizerWebsiteFooterComponent {
  brandData = input<BrandWithSeasonGroups[]>();
  pageData = input<NavPageLink[]>();
  socialData = input<SocialMediaLinks[]>();
  footerLogoURL = input<string>('');
  description = input<string>('');
}
