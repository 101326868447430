import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { TeamsService } from '@sentinels/services/firebase/teams.service';
import { APP_ROUTES } from '@shared/helpers';
import { DomainCheckService } from '@shared/services/domain-check.service';
import { RouteService } from '@shared/services/route.service';
import { map, Observable } from 'rxjs';
import { appRoutes } from '../../../../../../gth/src/app/app.routes';

export const organizerWebsiteResolver: ResolveFn<Observable<any> | boolean> = (route, state) => {
  const teamsService = inject(TeamsService);
  const routeService = inject(RouteService);
  const domainCheckService = inject(DomainCheckService);
  const router = inject(Router);
  const hostname = window.location.hostname;
  const teamId = route.params.id;

  const isGthDomain = domainCheckService.isGameTimeHeroDomain();

  if (!teamId && isGthDomain) {
    router.navigate([APP_ROUTES.Root]);
    return false;
  }

  if (!isGthDomain) {
    return teamsService.getTeamByDomain$(hostname).pipe(
      map((team) => {
        if (!team) {
          routeService.setRoutes(appRoutes);
          router.navigate([APP_ROUTES.Root]);
          return false;
        }
        return team;
      }),
    );
  }

  return teamsService.getTeamByTeamId$(teamId).pipe(
    map((team) => {
      if (!team?.creator) {
        router.navigate([APP_ROUTES.Root])
        return false;
      }
      return team;
    }),
  );
};
