<div class="flex flex-col gap-8 px-4 pb-16 mx-auto max-w-7xl">
  @if (brand() && seasonsAndGroups()) {
    <ark-organizer-website-brand-header
      [subHeader]="brand().name"
      header="Season Selection"
      [photoURL]="brand().photoURL"
      [description]="brand().description"
    />
    <div class="flex flex-col gap-4">
      @if (currentSeasons()[0]) {
        <div>
          <h3 class="mt-0 mb-4 text-base font-extrabold text-olive-1000 dark:text-olive-50">Current Seasons</h3>
          <div class="grid grid-cols-1 gap-4 sm:flex-row xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2">
            @for (item of currentSeasons(); track item) {
              @if (isGroup(item)) {
                @for (season of item.seasons; track season) {
                  <ark-organizer-website-card-link
                    icon="sports_soccer"
                    title="{{ item.seasonGroup.label }} - {{ season.label }}"
                    description="Starts {{ season.startDate | date:'longDate' }}"
                    [link]="[brand().id, 'season', season.id]"
                    [image]="season.imageURL"
                  />
                }
              } @else {
                <ark-organizer-website-card-link
                  icon="sports_soccer"
                  [title]="item.label"
                  description="Starts {{ item.startDate | date:'longDate' }}"
                  [link]="[brand().id, 'season', item.id]"
                  [image]="item.imageURL"
                />
              }
            }
          </div>
        </div>
      }
      @if (upcomingSeasons()[0]) {
        <div>
          <h3 class="mt-0 mb-4 text-base font-extrabold text-olive-1000 dark:text-olive-50">Upcoming Seasons</h3>
          <div class="grid grid-cols-1 gap-4 sm:flex-row xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2">
            @for (item of upcomingSeasons(); track item) {
              @if (isGroup(item)) {
                @for (season of item.seasons; track season) {
                  <ark-organizer-website-card-link
                    icon="sports_soccer"
                    title="{{ item.seasonGroup.label }} - {{ season.label }}"
                    description="Starts {{ season.startDate | date:'longDate' }}"
                    [link]="[brand().id, 'season', season.id]"
                    [image]="season.imageURL"
                  />
                }
              } @else {
                <ark-organizer-website-card-link
                  icon="sports_soccer"
                  [title]="item.label"
                  description="Starts {{ item.startDate | date:'longDate' }}"
                  [link]="[brand().id, 'season', item.id]"
                  [image]="item.imageURL"
                />
              }
            }
          </div>
        </div>
      }
    </div>
    <ark-organizer-website-other-links
      [brandId]="brand().id"
      [seasons]="false"
    />
  }
</div>
