<div
  id="organizer-website-layout"
  class="flex flex-col min-h-screen pt-16"
  [class.dark]="isDarkMode()"
>
  <ark-organizer-website-navbar
    [logoURL]="logoURL()"
    [pageData]="navPageData()"
    [brandData]="brandDataArray()"
    [isDarkMode]="isDarkMode()"
    [urlSegments]="urlSegments()"
    (toggleDarkMode)="onToggleDarkMode()"
  />

  <main class="flex-1 dark:bg-olive-1000 bg-olive-100">
    <ark-organizer-website-page [pageData]="pageData()" />
  </main>
  
  <ark-organizer-website-footer
    [brandData]="brandDataArray()"
    [pageData]="navPageData()"
    [socialData]="socialData()"
    [footerLogoURL]="footerLogoURL()"
    [description]="websiteDescription()"
  />
</div>