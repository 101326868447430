import { Component, Input } from '@angular/core';
import { IArkProgressiveFormControlService } from '../progressive-form-layout/types';
import { ArkProgressiveFormLayoutComponent } from '../progressive-form-layout/progressive-form-layout/progressive-form-layout.component';
import { of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'ark-splash-progressive-form-layout',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressBarModule,
    ArkProgressiveFormLayoutComponent,
  ],
  templateUrl: './splash-progressive-form-layout.component.html',
  styleUrl: './splash-progressive-form-layout.component.scss'
})
export class ArkSplashProgressiveFormLayoutComponent {
  @Input()
  service?: IArkProgressiveFormControlService;

  get progress$() {
    if (!this.service) return of(0);
    return this.service.progress;
  }
}
