import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { APP_STATE } from '@gth-legacy';
import { EventInfoLoadEventItem } from '@gth-legacy/components/event-info/state/actions';
import { eventInfoReducer } from '@gth-legacy/components/event-info/state/reducers';
import { Store } from '@ngrx/store';

@Injectable()
// eslint-disable-next-line max-len
export class GameInfoResolver {
  constructor(
    private store: Store<APP_STATE>,

  ) { }
  resolve(
    route: ActivatedRouteSnapshot,
  ) {
    const id = route.params.id;
    let teamId = '';

    if (route.parent) {
      teamId = route.parent.params.id;
    }

    this.store.addReducer('eventInfoState', eventInfoReducer);
    this.store.dispatch(EventInfoLoadEventItem(
      { eventId: id, teamId },
    ));
  }
}
