<footer class="flex flex-col w-full gap-4 px-4 py-12 md:flex-row md:px-16 dark:bg-olive-950 bg-olive-200">
  <div class="flex flex-col order-2 md:w-2/3 md:order-1">
    <img class="self-start w-auto h-24 text-left object-fit" [src]="footerLogoURL()" />
    <p class="md:max-w-[50%] dark:text-olive-50 text-olive-1000">{{ description() }}</p>
    <p class="text-olive-600 dark:text-olive-500">Powered by Gametime Hero</p>
  </div>
  <div class="flex flex-col justify-between order-1 w-full gap-4 md:flex-row md:order-2 md:w-1/3">
    <div>
      <h4 class="mt-0 mb-3 text-olive-1000 dark:text-olive-50">Brands</h4>
      <ul class="flex flex-col gap-2 p-0 list-none">
        @for (brand of brandData(); track $index) {
          <!-- TODO: Create component for this -->
          @if (brand.brand) {
            <li>
              <a
                class="text-olive-600 dark:text-olive-500"
                [routerLink]="[brand.brand.id]"
              >{{ brand.brand.name }}</a>
            </li>
          }
        }
      </ul>
    </div>
    <div>
      <h4 class="mt-0 mb-3 text-olive-1000 dark:text-olive-50">Pages</h4>
      <ul class="flex flex-col gap-2 p-0 list-none">
        @for (page of pageData(); track $index) {
          <li>
            <a
              class="text-olive-600 dark:text-olive-500"
              [routerLink]="[page.slug]"
            >{{ page.title }}</a>
          </li>
        }
      </ul>
    </div>
    <div>
      <h4 class="mt-0 mb-3 text-olive-1000 dark:text-olive-50">Social</h4>
      <ul class="flex flex-col gap-2 p-0 list-none">
        @for (social of socialData(); track $index) {
          <li>
            <a
              class="text-olive-600 dark:text-olive-500"
              [href]="social.url"
              target="_blank"
            >{{ social.label }}</a>
          </li>
        }
      </ul>
    </div>
  </div>
</footer>
