// search.actions.ts

import { createAction, props, union } from '@ngrx/store';

export enum SearchActionTypes {
    SearchLoadEventByStr = '[Search] Event Load By String',
    SearchLoadUserById = '[Search] User Load By Id',
    SearchLoadSuccess = '[Search] Load List Success',
    SearchLoadError = '[Search] Load List Error',
}

export const searchEventByStr = createAction(
    SearchActionTypes.SearchLoadEventByStr,
    props<{ searchStr: string }>(),
);

export const searchUserById = createAction(
    SearchActionTypes.SearchLoadUserById,
    props<{ id: string }>(),
);

export const searchLoadSuccess = createAction(
    SearchActionTypes.SearchLoadSuccess,
);

export const searchLoadError = createAction(
    SearchActionTypes.SearchLoadError,
    props<{ error: unknown }>(),
);

export const allSearchs = union({
    searchEventByStr,
    searchLoadSuccess,
    searchLoadError,
    searchUserById,
  });

export type SearchActionsUnion = typeof allSearchs;
