<a
  class="dark:bg-olive-950 bg-olive-200 rounded-lg w-full sm:aspect-[4/3] flex flex-col gap-2 px-6 py-5 transition-colors hover:bg-olive-300 dark:hover:bg-olive-900 no-underline"
  [routerLink]="link()"
>
  <div class="flex items-center justify-center flex-1">
    @if (image()) {
      <img class="object-cover rounded-md size-24 md:size-36" [src]="image()" />
    } @else if (icon()) {
      <mat-icon class="leading-none size-24 text-8xl text-pear-500">{{ icon() }}</mat-icon>
    }
  </div>
  <span class="font-bold dark:text-olive-50 text-olive-1000">{{ title() }}</span>
  <span class="dark:text-olive-500 text-olive-600">{{ description() }}</span>
</a>