import { Component, input, output } from '@angular/core';
import { NavDropdownItemComponent } from '../nav-dropdown-item/nav-dropdown-item.component';
import { BrandWithSeasonGroups, isGroup, SeasonGroupsWithSeasons } from '@ark/components/organizer-website/models/website-models';
import { animate, style, transition, trigger } from '@angular/animations';
import { NavMenuType } from '../../organizer-website-navbar.component';
import { DatePipe } from '@angular/common';
import { GthTeamSeasonModel } from '@sentinels/models';

@Component({
  selector: 'ark-nav-brands-dropdown',
  standalone: true,
  imports: [
    NavDropdownItemComponent,
    DatePipe,
  ],
  templateUrl: './nav-brands-dropdown.component.html',
  styleUrl: './nav-brands-dropdown.component.scss',
  animations: [
    trigger('fadeSlide', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(2.5%)' }),
        animate('250ms ease-out', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('250ms ease-in', style({ opacity: 0, transform: 'translateX(-2.5%)', position: 'absolute' }))
      ])
    ])
  ]
})
export class NavBrandsDropdownComponent {
  group = input<SeasonGroupsWithSeasons | null>(null);
  brand = input<BrandWithSeasonGroups | null>();
  small = input<boolean>(false);
  menuToggle = output<{ type: NavMenuType, id?: string }>();
  setGroup = output<SeasonGroupsWithSeasons | null>();
  setBrand = output<BrandWithSeasonGroups | null>();

  NavMenuType = NavMenuType;
  isGroup = isGroup;

  onGroupSelect(group: SeasonGroupsWithSeasons) {
    this.setGroup.emit(group);
    this.setBrand.emit(this.brand());
  }

  isActive(season: GthTeamSeasonModel) {
    const now = new Date();
    const start = season.startDate;
    const end = season.endDate;
    return start <= now && now <= end;
  }
}
