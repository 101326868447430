import { provideHttpClient, withFetch } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { OrganizerWebsitePageComponent } from '@ark/components/organizer-website/components/organizer-website-page/organizer-website-page.component';
import { OrganizerWebsiteLayoutComponent } from '@ark/components/organizer-website/layout/organizer-website-layout.component';
import { organizerWebsiteResolver } from '@ark/components/organizer-website/resolvers/organizer-website.resolver';
import { environment } from '@environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import { APP_EFFECTS, GTH_ENVIRONMENT, GthAuthService } from '@gth-legacy';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { favoritesReducer } from '@sentinels/state/features/favorites/reducers';
import { DomainCheckService } from '@shared/services/domain-check.service';
import { RouteService } from '@shared/services/route.service';
import { AppSharedModule } from '@shared/shared.module';
import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';
import { NgxStripeModule } from 'ngx-stripe';
// import player from 'lottie-web';
// import { provideLottieOptions } from 'ngx-lottie';
import { StripeModule } from 'stripe-angular';

import { appRoutes } from './app.routes';
import { AuthModule } from './features/auth/auth.module';
import { PaymentsModule } from './features/payments/payments.module';
import { FirebaseProviders } from './firebase-modules';
import { GameInfoResolver } from './route-resolvers';

// TODO: If this works well, clean up and move it to different file
export function initializeApp(
  domainCheckService: DomainCheckService,
  routeService: RouteService,
): () => Promise<void> {
  return () => {
    return new Promise<void>((resolve) => {
      const isGameTimeHero = domainCheckService.isGameTimeHeroDomain();
      let routes = appRoutes;
      if (!isGameTimeHero) {
        routes = [
          {
            path: 'form',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./features/form/views/list/list.component').then(
                    (c) => c.ListComponent,
                  ),
              },
              {
                path: 'build/:id',
                loadComponent: () =>
                  import('./features/form/views/form-builder/form-builder.component').then(
                    (c) => c.FormBuilderComponent,
                  ),
              },
              {
                path: ':id',
                loadComponent: () =>
                  import('./features/form/views/edit/edit.component').then(
                    (c) => c.EditComponent,
                  ),
              },
              {
                path: ':id/submissions',
                loadComponent: () =>
                  import('./features/form/views/submissions/submissions.component').then(
                    (c) => c.SubmissionsComponent,
                  ),
              },
              {
                path: ':id/:submissionId',
                loadComponent: () =>
                  import('./features/form/views/edit/edit.component').then(
                    (c) => c.EditComponent,
                  ),
              },
              {
                path: 'payment/:id/:submissionId',
                loadComponent: () =>
                  import(
                    // eslint-disable-next-line max-len
                    './features/form/views/form-payments-manager/form-payments-manager.component'
                  ).then((c) => c.FormPaymentsManagerComponent),
              },
            ],
          },
          {
            path: '',
            component: OrganizerWebsiteLayoutComponent,
            // canActivate: [organizerWebsiteGuard],
            resolve: {
              team: organizerWebsiteResolver,
            },
            children: [
              {
                path: '**',
                component: OrganizerWebsitePageComponent,
              },
            ],
          },
        ];
      }
      routeService.setRoutes(routes);
      resolve();
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    DomainCheckService,
    RouteService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [DomainCheckService, RouteService],
      multi: true,
    },
    provideRouter([]),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideNativeDateAdapter(),
    provideHttpClient(withFetch()),
    ...FirebaseProviders,
    provideLottieOptions({
      player: () => player,
    }),
    GameInfoResolver,
    GthAuthService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 } },
    {
      provide: GTH_ENVIRONMENT,
      useValue: {
        root: 'https://app.gametimehero.com',
        appName: 'Gametime Hero',
        teamsEnabled: true,
      },
    },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.googleMaps,
        libraries: ['places'],
      }),
    },
    importProvidersFrom(
      AuthModule,
      AppSharedModule,
      NgxStripeModule.forRoot(environment.stripePublicKey),
      NgxGpAutocompleteModule,
      PaymentsModule,
      MatNativeDateModule,
      MatInputModule,
      MatNativeDateModule,
      StripeModule.forRoot(environment.stripe),
      StoreModule.forRoot(
        { favorites: favoritesReducer },
        {
          runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false,
          },
        },
      ),
      EffectsModule.forRoot(APP_EFFECTS),
    ),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: isDevMode(),
    }),
    provideServiceWorker('combined-sw.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // FIXME: Commented out because of the error: document is not defined
    // provideLottieOptions({
    //   player: () => player,
    // }),
  ],
};
