@if(progress$ | async; as progress) {
<mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
}

<div class="splash">
  <div class="hero">
    <ng-content select="[slot=hero]" />
  </div>
  <ark-progressive-form-layout [service]="service">
  </ark-progressive-form-layout>
</div>