import { Component, input } from '@angular/core';
import { WebsiteModuleType, WebsitePage } from '../../models/website-models';
import { GalleryModuleComponent } from '../modules/gallery-module/gallery-module.component';
import { FreeformModuleComponent } from '../modules/freeform-module/freeform-module.component';
import { ContactModuleComponent } from '../modules/contact-module/contact-module.component';
import { TestimonialsModuleComponent } from '../modules/testimonials-module/testimonials-module.component';
import { SocialModuleComponent } from '../modules/social-module/social-module.component';
import { RulesModuleComponent } from '../modules/rules-module/rules-module.component';
import { ScheduleModuleComponent } from '../modules/schedule-module/schedule-module.component';
import { SeasonRegisterModuleComponent } from '../modules/season-register-module/season-register-module.component';
import { BrandModuleComponent } from '../modules/brand-module/brand-module.component';
import { ScheduleViewModuleComponent } from '../modules/schedule-view-module/schedule-view-module.component';

@Component({
  selector: 'ark-organizer-website-page',
  standalone: true,
  imports: [
    GalleryModuleComponent,
    FreeformModuleComponent,
    ContactModuleComponent,
    TestimonialsModuleComponent,
    RulesModuleComponent,
    ScheduleModuleComponent,
    ScheduleViewModuleComponent,
    SeasonRegisterModuleComponent,
    BrandModuleComponent,
    SocialModuleComponent,
  ],
  templateUrl: './organizer-website-page.component.html',
  styleUrl: './organizer-website-page.component.scss'
})
export class OrganizerWebsitePageComponent {
  pageData = input.required<WebsitePage>();

  moduleType = WebsiteModuleType;
}
