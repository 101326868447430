<h1>Personality</h1>

<p>Choose the qualities that best describe you.</p>

<mat-chip-listbox *ngIf="personalityTypes else loadingTmpl" aria-label="Personality selection" [multiple]="true">
  <mat-chip-option *ngFor="let personalityType of personalityTypes" [value]="personalityType[0].toLowerCase()"
    (selectionChange)="onPersonalityChange($event)"
    [selected]="selectedPersonalities.includes(personalityType[0].toLowerCase())">
    {{personalityType[1]}}
  </mat-chip-option>
</mat-chip-listbox>

<ng-template #loadingTmpl>
  <div class="fx-auto fx-fill fx-centered">
    <mat-spinner [diameter]="36" />
  </div>
</ng-template>