<div class="flex flex-col gap-8 pt-6 size-full">
  @if (photoURL()) {
    <img [src]="photoURL()" class="w-full h-[250px] md:h-[350px] rounded-[2rem] object-cover bg-olive-200/70 dark:bg-olive-950" />
  }
  <div class="flex">
    <div>
      <div class="flex flex-col">
        @if (link()) {
          <a
            [routerLink]="link()"
            class="flex items-center gap-2 all-unset text-olive-600 dark:text-olive-500 mb-2.5 cursor-pointer transition-colors hover:text-olive-800 dark:hover:text-olive-200"
          >
            <mat-icon class="text-2xl leading-none size-6">keyboard_backspace</mat-icon>
            <span class="font-bold text-inherit">Back to season selection</span>
          </a>
        }
        @if (subHeader()) {
          <h2 class="my-0 text-base text-pear-500">{{ subHeader() }}</h2>
        }
        <h1 class="my-0 text-2xl font-extrabold text-black dark:text-white">{{ header() }}</h1>
        @if (startDate()) {
          <span class="font-medium text-sm mt-1.5 dark:text-olive-300 text-olive-900 dark:bg-olive-950 border-olive-300 dark:border-olive-900 border-solid border bg-olive-200 rounded-md flex items-center px-2.5 py-1.5 w-fit">
            <mat-icon class="mr-2 text-xl leading-none size-5 text-pear-500">calendar_today</mat-icon>
            Starts:<span class="font-bold">&nbsp;{{ (startDate() | date: 'mediumDate') }}</span>
          </span>
        }
        <p class="mb-0 mt-2.5 prose dark:text-olive-500 text-olive-600">
          {{ description() }}
        </p>
      </div>
    </div>
  </div>
</div>