@if (brand()) {
  <div class="flex flex-col gap-8 px-4 pb-16 mx-auto max-w-7xl size-full">
    <ark-organizer-website-brand-header
      [header]="brand().brand.name + ' Rules'"
      [link]="[brand().brand.id]"
      [description]="description()"
    />
    <div class="flex flex-col gap-12 md:flex-row">
      <aside class="flex flex-col gap-2.5 border-0 border-b border-r-0 md:border-b-0 md:border-r border-solid border-olive-300 dark:border-olive-900 pb-6 md:pb-0 md:pr-12">
        <h3 class="m-0 text-base dark:text-olive-50 text-olive-1000 break-inside-avoid whitespace-nowrap">Quick Links</h3>
        <div class="flex flex-row md:flex-col gap-2.5 flex-wrap justify-center md:justify-start">
          @for (rule of rules(); track rule) {
            <button
              class="underline cursor-pointer text-pear-500 all-unset break-inside-avoid whitespace-nowrap"
              (click)="onQuickLinkClick(rule.header)"
            >{{ rule.header }}</button>
          }
        </div>
      </aside>
      <div class="gap-6 md:columns-2">
        @for (rule of rules(); track rule) {
          <ark-rule-item
            [header]="rule.header"
            [content]="rule.content"
          />
        }
      </div>
    </div>
    <ark-organizer-website-other-links
      [brandId]="brand().brand.id"
      [rules]="false"
    />
  </div>
}