<app-nav-bar-layout [showTopBar]="!insideAuthDialogRoute" [navBarOptions]="navBarOptions">
  <div class="container" *ngIf="onlyGeneralSettings; else showMoreInfo">
    <div class="settings-container">
      <div class="header-container">
        <h1>User Settings</h1>
      </div>
      <mat-tab-group
        (selectedIndexChange)="onTabSelectChange($event)"
        color="accent"
        [mat-stretch-tabs]="true"
        [selectedIndex]="selectedTabIndex"
      >
        <!-- General -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="tab-header">General</span>
          </ng-template>
          <mat-card appearance="outlined">
            <mat-card-content class="general-container">
              <gth-profile-form [formDetails]="moreInfoFormDetails" />
              <a
                mat-flat-button
                class="inverted uppercase"
                color="accent"
                [routerLink]="[APP_ROUTES.Profile]"
                >View Profile</a
              >
            </mat-card-content>
          </mat-card>
        </mat-tab>

        <!-- Privacy -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="tab-header">Privacy</span>
          </ng-template>
          <mat-card appearance="outlined">
            <mat-card-content class="scrollable-content">
              <app-more-info-privacy />
            </mat-card-content>
          </mat-card>
        </mat-tab>

        <!-- Payments -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="tab-header">Payments</span>
          </ng-template>
          <mat-card appearance="outlined" class="filler-background">
            <mat-card-content class="scrollable-content payments-container">
              <gth-user-payments platform="gth" />
              <a
                mat-flat-button
                class="inverted uppercase"
                color="accent"
                [routerLink]="[APP_ROUTES.Profile]"
                >View Profile</a
              >
            </mat-card-content>
          </mat-card>
        </mat-tab>

        <!-- Sports -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="tab-header">Interests</span>
          </ng-template>
          <mat-card appearance="outlined">
            <mat-card-content class="scrollable-content sports-container">
              <app-more-info-sports />
              <a
                mat-flat-button
                class="inverted uppercase"
                color="accent"
                [routerLink]="[APP_ROUTES.Profile]"
                >View Your Profile</a>
            </mat-card-content>
          </mat-card>
        </mat-tab>
         <mat-tab>
          <ng-template mat-tab-label>
            <span class="tab-header"> Availability</span>
          </ng-template>
          <mat-card appearance="outlined" class="scrollable-content">
            <mat-card-content class="availability-container">
              <app-more-info-availability />
              <a
                mat-flat-button
                *ngIf="skippable$ | async"
                class="inverted"
                color="accent"
                [routerLink]="[APP_ROUTES.Profile]"
                >Skip for now...</a>
              <a
                *ngIf="(skippable$ | async) === undefined"
                mat-flat-button
                class="inverted uppercase"
                color="accent"
                [routerLink]="[APP_ROUTES.Profile]"
                >View Profile</a>
            </mat-card-content>
          </mat-card>
        </mat-tab>

        <!-- Notification Settings -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="tab-header"> Notification Settings</span>
          </ng-template>
          <app-notification-settings></app-notification-settings>
          
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <ng-template #showMoreInfo>
    <div class="more-info-container">
      <picture>
        <img src="assets/football.webp" />
      </picture>
      <gth-profile-form
        [initialSignUp]="!onlyGeneralSettings"
        [formDetails]="moreInfoFormDetails"
        (newUser)="onCreateNewUser($event)"/>
    </div>
  </ng-template>
</app-nav-bar-layout>
