import { DatePipe } from '@angular/common';
import { Component, computed, input, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { BrandModule, SeasonGroupsWithSeasons } from '@ark/components/organizer-website/models/website-models';
import { OrganizerWebsiteBrandHeaderComponent } from '../../organizer-website-brand-header/organizer-website-brand-header.component';
import { RouterLink } from '@angular/router';
import { isGroup } from '../../../models/website-models';
import { NavDropdownItemComponent } from '../../organizer-website-navbar/components/nav-dropdown-item/nav-dropdown-item.component';
import { OrganizerWebsiteOtherLinksComponent } from '../../organizer-website-other-links/organizer-website-other-links.component';
import { OrganizerWebsiteCardLinkComponent } from '../../organizer-website-card-link/organizer-website-card-link.component';
import { GthTeamSeasonModel } from '@sentinels/models';

@Component({
  selector: 'ark-brand-module',
  standalone: true,
  imports: [
    DatePipe,
    MatIcon,
    OrganizerWebsiteBrandHeaderComponent,
    OrganizerWebsiteOtherLinksComponent,
    OrganizerWebsiteCardLinkComponent,
    RouterLink,
    NavDropdownItemComponent,
  ],
  templateUrl: './brand-module.component.html',
  styleUrl: './brand-module.component.scss'
})
export class BrandModuleComponent {
  moduleData = input.required<BrandModule>();

  brand = computed(() => this.moduleData().brand.brand);
  seasonsAndGroups = computed(() => this.moduleData().brand.seasonsAndGroups);

  upcomingSeasons = computed(() => this.seasonsAndGroups().map((season) => {
    const date = new Date();
    if (isGroup(season)) {
      const upcomingSeasons = season.seasons.filter((s) => s.startDate > date);
      if (upcomingSeasons.length > 0) {
        return { ...season, seasons: upcomingSeasons };
      }
      return null;
    }
    return season.startDate > date ? season : null;
  }).filter((season) => season !== null));
  currentSeasons = computed(() => this.seasonsAndGroups().map((season) => {
    const date = new Date();
    if (isGroup(season)) {
      const upcomingSeasons = season.seasons.filter((s) => s.startDate < date);
      if (upcomingSeasons.length > 0) {
        return { ...season, seasons: upcomingSeasons };
      }
      return null;
    }
    return season.startDate < date ? season : null;
  }).filter((season) => season !== null));

  isGroup = isGroup;
}
