<a
  (click)="click.emit()"
  [routerLink]="link()"
  class="no-underline p-4 transition-[background] rounded-lg size-full bg-olive-200 dark:bg-olive-950 hover:bg-olive-300 dark:hover:bg-olive-900 duration-100 flex min-w-[200px] cursor-pointer relative"
  [class.flex-col]="!small()"
  [class.items-center]="small()"
  [class.gap-1.5]="!small()"
  [class.gap-3]="small()"
  [class.cursor-not-allowed]="disabled()"
  [class.pointer-events-none]="disabled()"
>
  @if (badgeText()) {
    <span class="absolute px-2 py-1 text-sm font-medium border border-solid rounded-full dark:bg-olive-900 bg-olive-300 border-olive-400 dark:border-olive-800 text-olive-1000 dark:text-olive-50 top-3 right-3">{{ badgeText() }}</span>
  }
  <div
    class="flex items-center"
    [class.flex-1]="!small()"
  >
    @if (image()) {
      <img class="object-contain object-left w-full h-16 rounded-lg" [src]="image()" alt="Photo" />
    } @else if (icon()) {
      <mat-icon
        class="leading-none text-pear-500"
        [class.size-16]="!small()"
        [class.text-6xl]="!small()"
        [class.size-6]="small()"
        [class.text-2xl]="small()"
      >{{ icon() }}</mat-icon>
    }
  </div>
  <div class="flex flex-col gap-0.5">
    <span class="font-bold text-olive-950 dark:text-olive-50">{{ title() }}</span>
    @if (subtitle()) {
      <span class="text-sm text-olive-600 dark:text-olive-500">{{ subtitle() }}</span>
    }
  </div>
</a>