<div class="flex flex-col gap-4 px-4 py-16 mx-auto md:flex-row max-w-7xl">
  <div class="w-full md:w-1/2">
    <h2 class="mb-2.5 mt-0 text-3xl font-bold dark:text-olive-50 text-olive-1000">Contact Us</h2>
    <p class="m-0 dark:text-olive-500 text-olive-600 md:max-w-[66%] prose">{{ description() }}</p>
  </div>
  <div class="w-full md:w-1/2">
    <form class="flex flex-col">
      @for (input of inputs(); track $index) {
        @switch (input.type) {
          @case (ContactFormFieldType.TEXT) {
            <mat-form-field>
              <mat-label>{{ input.label }}</mat-label>
              <input matInput />
            </mat-form-field>
          }
          @case (ContactFormFieldType.EMAIL) {
            <mat-form-field>
              <mat-label>{{ input.label }}</mat-label>
              <input type="email" matInput />
            </mat-form-field>
          }
          @case (ContactFormFieldType.SELECT) {
            <mat-form-field>
              <mat-label>{{ input.label }}</mat-label>
              <mat-select>
              </mat-select>
            </mat-form-field>
          }
          @case (ContactFormFieldType.TEXTAREA) {
            <mat-form-field>
              <mat-label>{{ input.label }}</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>
          }
        }
      }
      <button type="submit" class="text-button">Submit</button>
    </form>
  </div>
</div>