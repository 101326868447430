@if (season() && brand()) {
  <div class="flex flex-col gap-8 px-4 pb-16 mx-auto size-full max-w-7xl">
    <ark-organizer-website-brand-header
      header="{{ seasonGroupLabel() ? seasonGroupLabel() + ' - ' : '' }}{{ season().label }}"
      [subHeader]="brand().name"
      [photoURL]="brand().photoURL"
      [link]="[brand().id]"
      [startDate]="season().startDate"
      [description]="brand().description"
    />
    @if (formId()) {      
      <a
        class="text-button"
        [routerLink]="[appRoutes.Forms, formId()]"
        [queryParams]="{
          backLink: backLink(),
          color: color(),
          accent: accent(),
          dark: darkMode(),
        }"
      >Register</a>
    }
    <ark-organizer-website-other-links
      [brandId]="brand().id"
    />
  </div>
}