<app-nav-bar-layout>
  @defer (when !!user(); on idle; prefetch on idle) {
    <app-alert-menu [tasks]="tasks"></app-alert-menu>
} @loading (minimum 300ms) {
    <ngx-skeleton-loader
      [theme]="{ 'width': '100%', 'height': '48px', 'margin-bottom': '10px', 'opacity': '50%' }"
      animation="pulse">
    </ngx-skeleton-loader>
}

  <div class="main-section-container">
    @defer (when !!user(); on idle; prefetch on idle) {
        <div class="top-home-container">
          <app-home-upcoming-events
            [name]="user()?.user?.displayName"
            [events]="userEvents()"
            [isUserEventsLoading]="isUserEventsLoading"/>
          <ark-location-card
            [city]="user()?.defaultCity"/>
        </div>
    } @loading (minimum 300ms) {
      <div class="skeleton-container">
        <ngx-skeleton-loader
          [theme]="{ 'width': '100%', 'height': skeletonHeight, 'margin-bottom': '10px', 'opacity': '50%' }"
          animation="pulse">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          [theme]="{ 'width': '100%', 'height': skeletonHeight, 'margin-bottom': '10px', 'opacity': '50%' }"
          animation="pulse">
        </ngx-skeleton-loader>
      </div>
    }
    @defer (when !!user(); on idle) {
      <app-nearby-events [cityName]="user()?.defaultCity.name"
        [eventPlayerObjects]="nearbyEventPlayerObjects()" />
  } @loading (minimum 300ms) {
    <ngx-skeleton-loader
      [theme]="{ 'width': '100%', 'height': '630px', 'margin-bottom': '10px', 'opacity': '50%' }"
      animation="pulse"/>
  }
  @defer (on viewport; when !!user()) {
    <app-nearby-players [users]="users()?.users" />
  } @placeholder () {
    <ngx-skeleton-loader
      [theme]="{ 'width': '100%', 'height': '614px', 'margin-bottom': '10px', 'opacity': '50%' }"
      animation="pulse"/>
  }
  </div>
</app-nav-bar-layout>
