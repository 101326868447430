import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GthAuthService } from '@gth-legacy';
import { UserService } from '@sentinels/services/firebase/user.service';
import { of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { GthUserModel } from '../../../../../sentinels/src/lib/models/user';
import { APP_ROUTES } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class NewUserGuard {
  constructor(
    public authService: GthAuthService,
    public router: Router,
    private usersService: UserService,
  ) {}

  canActivate() {
    const user$ = this.authService.getAuthStateUser$();
    return user$.pipe(
      distinctUntilChanged(),
      switchMap((user: any) => {
        if (user === undefined) {
          return of(undefined);
        }

        if (user === null || user.email === null) {
          this.router.navigate([APP_ROUTES.Login]);
          return of(null);
        }

        return this.usersService.getUserByEmail$(user.email!);
      }),
      map((userDbInfo: GthUserModel | undefined | null) => {
        if (userDbInfo === undefined) {
          return false;
        }

        if (!userDbInfo || !userDbInfo.email) {
          this.router.navigate([APP_ROUTES.Root]);
          return false;
        }
        if (!userDbInfo.hasAdditionalInfo) {
          this.router.navigate([APP_ROUTES.MoreInfo]);
          return true;
        }
        return true;
      }),
    );
  }
}
