import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserService } from '@sentinels/services/firebase/user.service';
import { switchMap } from 'rxjs/operators';

import { EventItemService } from '../../../services/firebase/event-items.service';
import * as Event from '../events/actions';
import * as User from './../user/actions';
import * as Search from './actions';

@Injectable()
export class SearchEffects {
  actions$ = inject(Actions<Search.SearchActionsUnion>);
  eventService = inject(EventItemService);
  userService = inject(UserService);

  readonly initSearch$ = createEffect(() => this.actions$.pipe(
    ofType(Search.searchEventByStr),
   switchMap((action) =>{
      return this.eventService.searchForEvents$(action.searchStr).pipe(
        switchMap((eJItem) => [
          Search.searchLoadSuccess(),
          Event.eventLoadListSuccess(eJItem),
        ]),
      );
    }),
  ));

  readonly initUserSearch$ = createEffect(() => this.actions$.pipe(
    ofType(Search.searchUserById),
   switchMap((action) =>{
      return this.userService.getConnections(action.id).pipe(
        switchMap((users) => [
          Search.searchLoadSuccess(),
          User.userLoadListSuccess({ users }),
        ]),
      );
    }),
  ));
}

