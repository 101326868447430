import { Validators } from '@angular/forms';
import { SkillLevel } from '@index/enums';

import { ArkProgressiveFormLayoutItemType } from '../../../../../../../ark/src/public-api';
const TYPE_COMMUNITY = {
  title: 'What type of community do you need?',
  formKey: 'communityType',
  collection: [
    {
      key: 'communityType',
      label: '',
      itemType: ArkProgressiveFormLayoutItemType.radio_group_cards,
      options: [
        {
          key: 'Team or Group',
          label: 'Team',
          // eslint-disable-next-line max-len
          description: 'A dedicated group of players who come together to compete in games.',
        },
        {
          key: 'organization',
          label: 'Organization (Coming Soon)',
          // eslint-disable-next-line max-len
          description: 'A formal entity, such as a league or sports association, that manages and oversees multiple teams or events.',
          disabled: true,
        },
        {
          key: 'league',
          label: 'League (Coming Soon)',
          // eslint-disable-next-line max-len
          description: 'A structured community where teams or individuals compete in a series of games or events, fostering skill development, camaraderie, and organized play. Leagues often provide opportunities for mentorship, coaching, and progression within a competitive framework.',
          disabled: true,
        },
      ],
    },
  ],
};

const NAME_COMMUNITY = {
  title: 'Manage your community on Gametime Hero',
  formKey: 'name',
  collection: [
    {
      key: 'desc',
      label: 'To begin, please enter your community’s name',
      itemType: ArkProgressiveFormLayoutItemType.text,
      isBold: true,
    },
    {
      key: 'name',
      label: 'Community Name',
      itemType: ArkProgressiveFormLayoutItemType.text_input,
      validator: [Validators.required],
    },
  ],
};

const TYPE_TEAM_AND_GROUP = {
  title: 'What is your main use of this community?',
  formKey: 'communityType',
  collection: [
    {
      key: 'communityType',
      label: '',
      itemType: ArkProgressiveFormLayoutItemType.radio_group_cards,
      options: [
        {
          key: 'team',
          label: 'Team',
          // eslint-disable-next-line max-len
          description: 'A dedicated group of players who come together to compete in games.',
        },
        {
          key: 'organization',
          label: 'Organization',
          // eslint-disable-next-line max-len
          description: 'A formal entity, such as a league or sports association, that manages and oversees multiple teams or events.',
        },
        {
          key: 'coaching',
          label: 'Coaching',
          // eslint-disable-next-line max-len
          description: 'A community led by coaches aimed at providing training, skills development, and mentorship to players.  Coaching communities focus on improving individual and team performance through structured guidance and practice sessions.',
        },
        {
          key: 'clubs',
          label: 'Clubs',
          // eslint-disable-next-line max-len
          description: 'Informal groups that bring together enthusiasts with common interests.',
        },
        {
          key: 'generalGroup',
          label: 'General Group/Social',
          // eslint-disable-next-line max-len
          description: 'A versatile category for any group of individuals interested in sports activities, ranging from casual players looking for pick-up games, to fans organizing viewing parties.',
        },
      ],
    },
  ],
};

const TYPE_EVENTS = {
  title: 'What type of events does your community run?',
  formKey: 'gameType',
  collection: [
    {
      key: 'gameType',
      label: 'Activity',
      itemType: ArkProgressiveFormLayoutItemType.select,
      // Populated by constructor
      options: [],
    },
  ],
};

const SELECT_CITY = {
  title: 'Where is your community located?',
  formKey: 'location',
  collection: [
    {
      key: 'location',
      label: 'Location',
      itemType: ArkProgressiveFormLayoutItemType.location,
    },
  ],
};

const SKILL_LEVEL = {
  title: 'What skill levels are appropriate for your community?',
  formKey: 'skillLevels',
  collection: [
    {
      key: 'skillLevels',
      label: 'Choose a skill level',
      itemType: ArkProgressiveFormLayoutItemType.radio_checkbox_cards,
      multiple: true,
      options: [
        {
          key: SkillLevel.Novice,
          label: 'Novice',
          // eslint-disable-next-line max-len
          description: 'Individuals completely new to the sport and have little to no prior experience',
          withIconEnd: 'menu_book',
        },
        {
          key: SkillLevel.Beginner,
          label: 'Beginner',
          // eslint-disable-next-line max-len
          description: 'Participants with some basic knowledge and initial experience in the sport.',
          withIconEnd: 'wb_incandescent',
        },
        {
          key: SkillLevel.Intermediate,
          label: 'Intermediate',
          // eslint-disable-next-line max-len
          description: 'Players with a moderate level of experience and skill, with good understanding of rules and strategies.',
          withIconEnd: 'thumb_up',
        },
        {
          key: SkillLevel.Advanced,
          label: 'Advanced',
          // eslint-disable-next-line max-len
          description: 'Highly skilled and experienced players with a deep understanding of the sport.',
          withIconEnd: 'verified',
        },
      ],
    },
  ],
};

const AGE_SELECTION = {
  title: 'What is your communities preferred age range?',
  formKey: 'ageRange',
  collection: [
    {
      key: 'ageRange',
      // eslint-disable-next-line max-len
      label: 'Please enter a range of ages that are allowed to participate within your community',
      itemType: ArkProgressiveFormLayoutItemType.range,
      min: 0,
      max: 110,
    },
    {
      key: 'anyAgeRange',
      label: 'All ages allowed',
      itemType: ArkProgressiveFormLayoutItemType.checkbox,
    },
  ],
};

const UPLOAD_IMAGE = {
  title: 'Creating your community on Gametime Hero',
  formKey: '',
  collection: [
    {
      key: 'desc',
      label: 'Upload a community image',
      itemType: ArkProgressiveFormLayoutItemType.text,
    },
    {
      key: '',
      label: 'Upload',
      itemType: ArkProgressiveFormLayoutItemType.custom,
    },
  ],
};

export const DEFAULT_LAYOUT = {
  pages: [
    NAME_COMMUNITY,
    TYPE_COMMUNITY,
    TYPE_TEAM_AND_GROUP,
    TYPE_EVENTS,
    SELECT_CITY,
    SKILL_LEVEL,
    AGE_SELECTION,
    UPLOAD_IMAGE,
  ],
};
