import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { ArkLocationCardComponent } from '@ark';
import {
  APP_STATE,
  eventLoadListByLatLng,
  eventLoadListByUserId,
  eventReducer,
  GthFeedbackService,
} from '@gth-legacy';
import { Store } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';
import { SrvUserAvailabiiltyService } from '@sentinels/services';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { selectJoinersWithEventsByLatLng } from '@sentinels/state/features/joiners/selectors';
import { userLoadListByLatLng, userLoadOne } from '@sentinels/state/features/user/actions';
import { selectUsersByLatLngForAuthUser } from '@sentinels/state/features/user/selectors';
import {
  AlertMenuComponent,
  TodoListItem,
} from '@shared/components/alert-menu/alert-menu.component';
import { APP_ROUTES } from '@shared/helpers';
import { NavBarLayoutComponent } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { debounceTime, tap } from 'rxjs';

import { DiscoveryService } from '../../discover/services/discovery.service';
import { selectEventsByCreatorIdAndPlayerAsJoiner } from '../../profile/state/selectors';
import { NearbyEventsComponent } from '../components/nearby-events/nearby-events.component';
import { NearbyPlayersComponent } from '../components/nearby-players/nearby-players.component';
import { HomeUpcomingEventsComponent } from '../components/upcoming-events/home-upcoming-events.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    NavBarLayoutComponent,
    NgxSkeletonLoaderModule,
    MatIconModule,
    MatListModule,
    RouterLink,
    MatExpansionModule,
    ArkLocationCardComponent,
    HomeUpcomingEventsComponent,
    NearbyEventsComponent,
    NearbyPlayersComponent,
    AlertMenuComponent,
  ],
})
export class MainHomeComponent {
  tasks: TodoListItem[] = [];
  APP_ROUTES = APP_ROUTES;
  discoveryService = inject(DiscoveryService);

  isUserEventsLoading = true;
  user = this.store.selectSignal(selectUser);
  userEvents = toSignal(
    this.store.select(selectEventsByCreatorIdAndPlayerAsJoiner(this.user()?.id)).pipe(
      debounceTime(1000),
      tap(() => {
        this.isUserEventsLoading = false;
      }),
    ),
  );
  users = this.store.selectSignal(selectUsersByLatLngForAuthUser(0, 7));

  nearbyEventPlayerObjects = computed(() => {
    const userJoinersWithEvents = this.store.selectSignal(
      selectJoinersWithEventsByLatLng(
        this.user().defaultCity.lat,
        this.user().defaultCity.lng,
        7,
      ),
    );
    return userJoinersWithEvents().eventsAndJoiners;
  });

  destroyRef = inject(DestroyRef);

  constructor(
    private store: Store<APP_STATE>,
    private availService: SrvUserAvailabiiltyService,
    private cdr: ChangeDetectorRef,
    private feedback: GthFeedbackService,
    private safeWindow: SrvSafeWindowService,
  ) {
    effect(
      () => {
        if (this.user()) {
          this.discoveryService.setLocation(
            this.user().defaultCity.lat,
            this.user().defaultCity.lng,
            this.user().defaultCity.name,
          );

          const latLngName = {
            lat: this.user().defaultCity.lat,
            lng: this.user().defaultCity.lng,
            name: this.user().defaultCity.name,
          };
          this.dispatchViaUserLocation(latLngName);
          this.cdr.markForCheck();
        }
      },
      { allowSignalWrites: true },
    );

    this.discoveryService.loadDataFromSelectors();
    this.store.addReducer('event', eventReducer);
    this.store
      .select(selectUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        if (user) {
          this.setTasks(user);
        }
      });
  }

  get width() {
    return this.safeWindow.innerWidth;
  }

  get height() {
    return this.safeWindow.innerHeight;
  }

  get skeletonHeight() {
    return this.width < 1201 ? '141px' : '283px';
  }

  dispatchViaUserLocation(latLng: { lat: number; lng: number; name: string }) {
    this.discoveryService.setLocation(latLng.lat, latLng.lng, latLng.name);

    this.store.dispatch(userLoadOne({ uid: this.user().id }));
    this.store.dispatch(eventLoadListByUserId({ id: this.user().id }));
    this.discoveryService.dispatchLoad();
    this.store.dispatch(userLoadListByLatLng(latLng));
    this.store.dispatch(eventLoadListByLatLng(latLng));
  }

  async setTasks(user: GthUserModel) {
    this.tasks = await this.getUserIncompleteTasks(user);
  }

  async getUserIncompleteTasks(user: GthUserModel) {
    const avail = await this.availService.read(user.id);
    const tasks = [];
    if (!avail) {
      tasks.push({
        text: 'Update your availability so others can find you!',
        url: [APP_ROUTES.Settings],
        params: { tab: 'availability' },
      });
    }

    if (!user.feedbackSubmitted) {
      tasks.push({
        text: 'Submit feedback for Gametime Hero!',
        callback: () => {
          this.feedback.openFeedbackDialog();
        },
      });
    }

    if (!user.notificationSettings) {
      tasks.push({
        text: 'Set what notifications you want to receive!',
        url: [APP_ROUTES.Settings],
        params: { tab: 'notification' },
      });
    }

    if (user.sportAvailability.length === 0) {
      tasks.push({
        text: 'Set your favorite activities to match with others!',
        url: [APP_ROUTES.Settings],
        params: { tab: 'sports' },
      });

      if (!user?.bio) {
        tasks.push({
          text: 'Complete your Bio!',
          url: [APP_ROUTES.Profile],
        });
      }
    }

    return tasks;
  }
}
