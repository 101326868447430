import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { EventInfoLoadEventItem, EventInfoOnEventItemSuccess } from './actions';
import { EventInfoViewModelService } from './services/event-info.service';

@Injectable()
export class EventInfoEffects {
  readonly effect1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventInfoLoadEventItem),
      switchMap((action) => {
        return this.loadEvents$(action.eventId);
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private eventInfoService: EventInfoViewModelService,
  ) {}

  loadEvents$(eventId: string) {
    return this.eventInfoService.getEventInfo(eventId).pipe(
      map((eventInfo) => {
        return EventInfoOnEventItemSuccess({ eventInfo });
      }),
      catchError((error) => {
        return EMPTY;
      }),
    );
  }
}
