<div class="md:absolute flex flex-col md:flex-1 w-full md:w-[calc(100%-4rem)] md:h-[calc(100%-3rem)]" @fadeSlide>
  <div class="flex items-center gap-2 mb-2">
    <h3 class="my-0 dark:text-olive-50 text-olive-1000">Pages</h3>
  </div>
  <ul class="flex flex-1 gap-4 p-0 m-0 overflow-x-auto list-none">
    @for (page of pageData(); track $index) {
      <ark-nav-dropdown-item
        [title]="page.title"
        [link]="page.slug"
        [icon]="page.icon ?? 'insert_link'"
        [small]="small()"
        (click)="menuToggle.emit(NavMenuType.PAGES)"
      />
    }
  </ul>
</div>