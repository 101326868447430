import { Component, computed, input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ContactFormFieldType, ContactModule } from '@ark/components/organizer-website/models/website-models';

@Component({
  selector: 'ark-contact-module',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  templateUrl: './contact-module.component.html',
  styleUrl: './contact-module.component.scss'
})
export class ContactModuleComponent {
  moduleData = input.required<ContactModule>();

  ContactFormFieldType = ContactFormFieldType; 

  inputs = computed(() => this.moduleData().formFields || []);
  description = computed(() => this.moduleData().description || '');
}
