import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { subscriptionMetadata, SubscriptionSettingsBase } from '@index/notification';
import { Store } from '@ngrx/store';
import { UserService } from '@sentinels/services/firebase/user.service';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { APP_STATE } from '@sentinels/state/state';
import { take } from 'rxjs';

import { GthAnalyticsService } from '../../../../../../../../gth-legacy/src/public-api';
import { APP_ROUTES } from '../../../../../shared/helpers/routes';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatListModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatIconModule,
    RouterModule,
    MatDividerModule,
    MatButtonModule,
  ],
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent {
  private store = inject(Store<APP_STATE>);
  private fb = inject(FormBuilder);
  userService = inject(UserService);
  snackBar = inject(MatSnackBar);
  analyticsServices = inject(GthAnalyticsService);

  private defaultNotificationSettings: SubscriptionSettingsBase = {
    surveys: { email: true, push: true },
    news: { email: true, push: true },
    learning: { email: true, push: true },
    productSpotlight: { email: true, push: true },
    offers: { email: true, push: true },
    usersJoinNearby: { email: true, push: true },
    eventCreatedJoin: { email: true, push: true },
    eventJoined: { email: true, push: true },
    endorsement: { email: true, push: true },
    directMessage: { email: true, push: true },
    teamJoin: { email: true, push: true },
    teamMessage: { email: true, push: true },
    connections: { email: true, push: true },
    announcements: { email: true, push: true },
  };

  user = this.store.selectSignal(selectUser);
  APP_ROUTES = APP_ROUTES;
  notificationSettings = computed(() => {
    const userModel = this.user();
    return userModel?.hasNotificationSettings
      ? userModel.notificationSettings
      : this.defaultNotificationSettings;
  });
  settingsForm = this.fb.group(
    Object.keys(this.defaultNotificationSettings).reduce(
      (acc, key) => {
        acc[key] = this.fb.group({
          email: [null],
          push: [null],
        });
        return acc;
      },
      {} as Record<keyof SubscriptionSettingsBase, FormGroup>,
    ),
  );
  subscriptionMetadata = subscriptionMetadata;
  keepOriginalOrder = (a, b) => a.key;

  constructor() {
    effect(() => {
      this.settingsForm = this.fb.group(
        Object.keys(this.defaultNotificationSettings).reduce(
          (acc, key) => {
            const setting = this.notificationSettings()[key];
            if (setting) {
              acc[key] = this.fb.group({
                email: [setting.email],
                push: [setting.push],
              });
            } else {
              acc[key] = this.fb.group({
                email: [true],
                push: [true],
              });
            }
            return acc;
          },
          {} as Record<keyof SubscriptionSettingsBase, FormGroup>,
        ),
      );
    });
  }

  saveSettings() {
    const formValues = this.settingsForm.value;

    const updatedSettings: SubscriptionSettingsBase = {
      surveys: formValues.surveys,
      news: formValues.news,
      learning: formValues.learning,
      productSpotlight: formValues.productSpotlight,
      offers: formValues.offers,
      usersJoinNearby: formValues.usersJoinNearby,
      eventCreatedJoin: formValues.eventCreatedJoin,
      eventJoined: formValues.eventJoined,
      endorsement: formValues.endorsement,
      directMessage: formValues.directMessage,
      teamJoin: formValues.teamJoin,
      teamMessage: formValues.teamMessage,
      connections: formValues.connections,
      announcements: formValues.announcements,
    };

    this.analyticsServices.logEvent(
      'notification_settings_updated',
      JSON.stringify(updatedSettings),
    );

    this.userService
      .update(this.user().id, { notificationSettings: updatedSettings })
      .pipe(take(1))
      .subscribe(() => {
        this.snackBar.open('Notification settings updated', 'Dismiss', {
          duration: 2000,
        });
      });
  }

  getFormGroup(key: string) {
    return this.settingsForm.get(key) as FormGroup;
  }
}
