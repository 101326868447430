<nav class="fixed top-0 z-50 flex items-center justify-between w-full h-16 gap-3 px-4 text-black border-0 border-b border-solid md:px-16 dark:text-white dark:bg-olive-1000 dark:border-b-olive-900 border-b-olive-300 bg-olive-100">
  <!-- TODO(srevier): update this -->
  <a [routerLink]="['home']" class="h-12">
    <img [src]="logoURL()" class="w-full h-full" />
  </a>
  <div class="flex items-center h-full gap-4">
    <ul class="hidden h-full p-0 m-0 list-none md:flex">
      @for (brand of brandData(); track $index) {
        <li>
          <ark-nav-link
            [label]="brand.brand?.name"
            [brandData]="brand"
            [isDropdownOpen]="isBrandDropdownOpen(brand.id)"
            [active]="urlSegments()[1] === brand.id"
            icon="shield"
            (linkClicked)="onMenuToggle(NavMenuType.BRAND, brand.id)"
          />
        </li>
      }
      <li>
        <ark-nav-link
          label="Pages"
          icon="insert_drive_file"
          [pageData]="pageData()"
          [isDropdownOpen]="isPagesDropdownOpen()"
          (linkClicked)="onMenuToggle(NavMenuType.PAGES)"
        />
      </li>
    </ul>
    <ul class="flex items-center h-full gap-4 p-0 m-0 list-none md:gap-6">
      <li>
        <button class="text-button sm">Login</button>
      </li>
      <li>
        <button
          class="flex items-center justify-center rounded-full shadow-sm all-unset dark:bg-olive-900 bg-olive-300 size-7"
          (click)="toggleDarkMode.emit()"
        >
          @if (isDarkMode()) {
            <mat-icon class="text-xl leading-none size-5 dark:text-olive-400 text-olive-600">wb_sunny</mat-icon>
          } @else {
            <mat-icon class="text-xl leading-none size-5 dark:text-olive-400 text-olive-600">nights_stay</mat-icon>
          }
        </button>
      </li>
      <li class="md:hidden">
        <button class="flex items-center justify-center all-unset" (click)="onMobileNavMenuClick()">
          @if (isMenuOpen()) {
            <mat-icon class="text-xl leading-none size-5 dark:text-olive-400 text-olive-600">close</mat-icon>
          } @else {
            <mat-icon class="text-xl leading-none size-5 dark:text-olive-400 text-olive-600">menu</mat-icon>
          }
        </button>
      </li>
    </ul>
  </div>
</nav>

@if (isMenuOpen()) {
  <div
    class="fixed z-40 w-full h-full top-16 bg-black/50 backdrop-blur-md"
    (click)="onMenuToggle(menuType())"
  >
    <div
      class="relative flex flex-col gap-4 w-full h-[calc(100%-4rem)] px-4 py-3 md:px-8 md:py-6 border-0 border-b border-solid md:h-64 dark:bg-olive-1000 dark:border-b-olive-900 bg-olive-100 border-b-olive-200 overflow-y-auto overflow-x-hidden"
      (click)="$event.stopPropagation()"
    >
      @switch (menuType()) {
        @case (NavMenuType.PAGES) {
          <ark-nav-pages-dropdown
            [pageData]="pageData()"
            (menuToggle)="onMenuToggle($event)"
          />
        }
        @case (NavMenuType.BRAND) {
          <ark-nav-brands-dropdown
            [brand]="activeNavBrand()"
            [group]="activeNavGroup()"
            (menuToggle)="onMenuToggle($event.type, $event.id)"
            (setGroup)="activeNavGroup.set($event)"
          />
        }
        @case (null) {
          @if (activeNavGroup()) {
            <ark-nav-brands-dropdown
              [brand]="activeNavBrand()"
              [group]="activeNavGroup()"
              (menuToggle)="onMenuToggle(null, $event.id)"
              (setGroup)="activeNavGroup.set($event)"
            />
          } @else {
            @for (brand of brandData(); track $index) {
              <ark-nav-brands-dropdown
                [brand]="brand"
                [group]="null"
                [small]="true"
                (menuToggle)="onMenuToggle($event.type, $event.id)"
                (setGroup)="activeNavGroup.set($event)"
                (setBrand)="activeNavBrand.set($event)"
              />
            }
            <ark-nav-pages-dropdown
              [pageData]="pageData()"
              [small]="true"
              (menuToggle)="onMenuToggle($event)"
            />
          }
        }
      }
    </div>
  </div>
}