import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  FavoritesService,
} from '@sentinels/services/firebase/favorites.service';
import {
  loadFavorites, loadFavoritesFailure,
  loadFavoritesSuccess,
} from '@sentinels/state/features/favorites/actions';
import { exhaustMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class FavoritesEffects {
  private favoritesService = inject(FavoritesService);

  constructor(private action$: Actions) {}

  readonly loadFavoritesEffect$ = createEffect(() => this.action$.pipe(
    ofType(loadFavorites),
    exhaustMap(({ userId }) => this.loadFavorites$(userId)),
  ));

  loadFavorites$(userId: string) {
    return this.favoritesService.getUserFavoriteEvents$(userId).pipe(
      map((favorites) => loadFavoritesSuccess({ favorites })),
      catchError((error: unknown) => {
        return of(loadFavoritesFailure({ error }));
      }),
    );
  }
}
