<h3 class="mt-0 mb-4 text-base font-extrabold text-olive-1000 dark:text-olive-50">Other links</h3>
<div class="flex flex-col gap-4 sm:flex-row">
  @if (seasons()) {
    <ark-nav-dropdown-item
      title="Seasons"
      subtitle="View all seasons for this brand"
      icon="sports_score"
      [small]="true"
      [link]="[brandId()]"
    />
  }
  @if (schedule()) {
    <ark-nav-dropdown-item
      title="Schedules"
      subtitle="View all schedules for this brand"
      icon="calendar_month"
      [small]="true"
      [link]="[brandId(), 'schedule']"
    />
  }
  @if (rules()) {
    <ark-nav-dropdown-item
      title="Rules"
      subtitle="View the rules for this brand"
      icon="balance"
      [small]="true"
      [link]="[brandId(), 'rules']"
    />
  }
</div>