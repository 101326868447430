import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GthEventItemModel } from '@sentinels/models';
import { TeamMemberRolesService } from '@sentinels/services/firebase/member-roles.service';
import { TeamSeasonService } from '@sentinels/services/firebase/team-season.service';
import { TeamSessionService } from '@sentinels/services/firebase/team-session.service';
import { TeamsService } from '@sentinels/services/firebase/teams.service';
import { exhaustMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  LoadEvents,
  LoadEventsError,
  LoadEventsSuccess,
  LoadSeasonGroups,
  LoadSeasonGroupsError,
  LoadSeasonGroupsSuccess,
  LoadSeasons,
  LoadSeasonsError,
  LoadSeasonsSuccess,
  LoadSessions,
  LoadSessionsError,
  LoadSessionsSuccess,
  LoadTeamMemberRoles,
  LoadTeamMemberRolesError,
  LoadTeamMemberRolesSuccess,
  SetActiveTeamById,
  SetActiveTeamByIdError,
  SetActiveTeamByIdSuccess,
} from './actions';

@Injectable()
export class ActiveTeamsEffects {
  constructor(
    private actions$: Actions,
    private teamsService: TeamsService,
    private teamMemberRolesService: TeamMemberRolesService,
    private teamSessionService: TeamSessionService,
    private teamSeasonService: TeamSeasonService,
  ) {}

  readonly setActiveTeamByTeamId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetActiveTeamById),
      exhaustMap((action) => this.teamsService.getTeamByTeamId$(action.activeTeamId)),
      map((activeTeam) => SetActiveTeamByIdSuccess({ activeTeam })),
      catchError((error: unknown) => {
        return of(SetActiveTeamByIdError({ error }));
      }),
    ),
  );

  readonly loadEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadEvents),
      exhaustMap((action) =>
        this.teamsService.getTeamEventsForSession$(
          action.activeTeamId,
          action.activeSessionId,
        ),
      ),
      map((events: GthEventItemModel[]) => LoadEventsSuccess({ events })),
      catchError((error: unknown) => {
        return of(LoadEventsError({ error }));
      }),
    ),
  );

  readonly loadTeamMemberRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTeamMemberRoles),
      exhaustMap((action) => this.teamMemberRolesService.getByTeamId$(action.activeTeamId)),
      map((teamMemberRoles) => LoadTeamMemberRolesSuccess({ teamMemberRoles })),
      catchError((error: unknown) => {
        return of(LoadTeamMemberRolesError({ error }));
      })));

  readonly loadSessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSessions),
      exhaustMap((action) => this.teamSessionService.getAll(action.activeTeamId)),
      map((sessions: any[]) => LoadSessionsSuccess({ sessions })),
      catchError((error: unknown) => {
        return of(LoadSessionsError({ error }));
      }),
    ),
  );

  readonly loadSeasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSeasons),
      exhaustMap((action) => this.teamSeasonService.getAll(action.activeTeamId)),
      map((seasons) => LoadSeasonsSuccess({ seasons })),
      catchError((error: unknown) => {
        return of(LoadSeasonsError({ error }));
      }),
    ),
  );

  readonly loadSeasonGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSeasonGroups),
      exhaustMap((action) => this.teamSeasonService.getAllGroups(action.activeTeamId)),
      map((seasonGroups) => LoadSeasonGroupsSuccess({ seasonGroups })),
      catchError((error: unknown) => {
        return of(LoadSeasonGroupsError({ error }));
      }),
    ),
  );
}
