<div class="flex flex-col gap-8 px-4 pb-16 mx-auto max-w-7xl">
  @if (brand()) {
    <ark-organizer-website-brand-header
      header="Schedule Selection"
      [subHeader]="brand().name"
      [photoURL]="brand().photoURL"
      [link]="[brand().id]"
      [description]="brand().description"
    />
    
    @for (group of groups(); track group) {
      <div>
        <h3 class="mt-0 mb-4 text-base font-extrabold text-olive-1000 dark:text-olive-50">{{ group.seasonGroup.label }}</h3>
        <div class="grid grid-cols-1 gap-4 sm:flex-row xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2">
          @for (season of group.seasons; track season) {
            @if (season.scheduleURLs[0]) {
              <ark-organizer-website-card-link
                icon="sports_soccer"
                [title]="season.label"
                description="Starts {{ season.startDate | date:'longDate' }}"
                [link]="[brand().id, 'schedule', season.id]"
                [image]="season.imageURL"
              />
            }
          }
        </div>
      </div>
    }
    
    @if (seasons().length > 0) {
      <div>
        <h3 class="mt-0 mb-4 text-base font-extrabold text-olive-1000 dark:text-olive-50">Other Seasons</h3>
        <div class="grid grid-cols-1 gap-4 sm:flex-row xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2">
          @for (season of seasons(); track season) {
            @if (season.scheduleURLs[0]) {
              <ark-organizer-website-card-link
                icon="sports_soccer"
                [title]="season.label"
                description="Starts {{ season.startDate | date:'longDate' }}"
                [link]="[brand().id, 'schedule', season.id]"
                [image]="season.imageURL"
              />
            }
          }
        </div>
      </div>
    }

    <ark-organizer-website-other-links
      [brandId]="brand().id"
      [schedule]="false"
    />
  }
</div>
