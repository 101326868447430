import { Component, computed, inject, input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FreeFormModule } from '@ark/components/organizer-website/models/website-models';

@Component({
  selector: 'ark-freeform-module',
  standalone: true,
  imports: [],
  templateUrl: './freeform-module.component.html',
  styleUrl: './freeform-module.component.scss'
})
export class FreeformModuleComponent {
  moduleData = input.required<FreeFormModule>();

  private sanitizer = inject(DomSanitizer);

  content = computed(() => {
    return this.sanitizer.bypassSecurityTrustHtml(this.moduleData().content);
  });
  extraClasses = computed(() => this.moduleData().extraClasses ?? '');
}
