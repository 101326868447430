import { Injectable } from '@angular/core';

const GthDomains = [
  'gametimehero.com',
  'app.gametimehero.com',
  'web.app',
  'firebaseapp.com',
  'localhost',
];

@Injectable({
  providedIn: 'root',
})
export class DomainCheckService {
  isGameTimeHeroDomain(): boolean {
    if (typeof window !== 'undefined') {
      return GthDomains.includes(window.location.hostname);
    }
    return false; // Default to false if window is not defined
  }
}
