import { Component, computed, inject, OnInit, Signal, signal, ViewEncapsulation } from '@angular/core';
import { BrandWithSeasonGroups, flattenSeasonsAndGroups, OrganizerWebsite, SeasonsAndGroups, WebsiteModuleType, WebsitePage } from '../models/website-models';
import { OrganizerWebsiteNavbarComponent } from '../components/organizer-website-navbar/organizer-website-navbar.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OrganizerWebsitePageComponent } from '../components/organizer-website-page/organizer-website-page.component';
import { OrganizerWebsiteFooterComponent } from '../components/organizer-website-footer/organizer-website-footer.component';
import { Store } from '@ngrx/store';
import { APP_STATE, LoadSeasonGroupsOne, LoadSeasonsOne, LoadTeamsOne, selectSeasonGroupsByTeamId, selectSeasonsByTeamId, selectTeamById, teamsReducer } from '@gth-legacy';
import { GthTeamModel } from '@sentinels/models';
import { filter, map } from 'rxjs';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { JsonPipe } from '@angular/common';
import { DomainCheckService } from '@shared/services/domain-check.service';

@Component({
  selector: 'ark-organizer-website-layout',
  standalone: true,
  imports: [
    OrganizerWebsiteNavbarComponent,
    OrganizerWebsiteFooterComponent,
    OrganizerWebsitePageComponent,
    JsonPipe,
  ],
  templateUrl: './organizer-website-layout.component.html',
  styleUrl: './organizer-website-layout.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class OrganizerWebsiteLayoutComponent implements OnInit {
  private route = inject(ActivatedRoute);
  // TODO(srevier): Create page builder service
  
  organization = toSignal<GthTeamModel>(this.route.data.pipe(
    map(data => data['team'])
  ));
  websiteDetails = computed<OrganizerWebsite>(() => this.organization().organizerWebsite);
  private router = inject(Router);
  private store = inject(Store<APP_STATE>);
  private safeStorage = inject(SrvSafeStorageService);
  private domainCheckService = inject(DomainCheckService);

  urlSegments = signal<string[]>([]);

  isDarkMode = signal<boolean>(
    this.safeStorage.getItem('darkMode') == null ?
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches :
      this.safeStorage.getItem('darkMode') === 'true'
  );

  logoURL = computed(() => {
    if (this.isDarkMode() && this.websiteDetails()?.darkLogoURL) {
      return this.websiteDetails()?.darkLogoURL;
    }
    return this.websiteDetails()?.logoURL
  });
  footerLogoURL = computed(() => {
    const { footerLogoURL, darkFooterLogoURL, darkLogoURL, logoURL } = this.websiteDetails();
    if (this.isDarkMode()) {
      return darkFooterLogoURL || footerLogoURL || darkLogoURL || logoURL;
    }
    return footerLogoURL || logoURL;
  });
  websiteDescription = computed(() => this.websiteDetails()?.websiteDescription);
  navPageData = computed(() => {
    return this.websiteDetails()?.pages.filter((page) => page.title !== '404').map(page => {
      return {
        title: page.title,
        slug: page.slug,
        icon: page.icon,
      }
    });
  });

  socialData = computed(() => this.websiteDetails()?.socialLinks);

  notFoundPage = computed(() => this.websiteDetails()?.pages.find(page => page.slug === '404'));
  pageData = computed(() => {
    let pageSlug = this.urlSegments()[0];
    if (!pageSlug) pageSlug = 'home';
    let pageData = this.websiteDetails()?.pages.find(page => page.slug === pageSlug);

    // If page isn't found, show 404 page
    if (!pageData && !this.websiteDetails()?.brands.includes(pageSlug)) {
      pageData = this.notFoundPage();
      return pageData;
    } else if (pageData) {
      return pageData;
    }
    
    let secondarySlug = this.urlSegments()[1];
    let tertiarySlug = this.urlSegments()[2];
    if (!secondarySlug) {
      pageData = this.buildBrandPage(pageSlug);
      return pageData;
    }

    switch (secondarySlug) {
      case 'season':
        pageData = tertiarySlug ?
          this.buildSeasonPage(pageSlug, tertiarySlug) :
          this.buildBrandPage(pageSlug);
        break;
      case 'rules':
        pageData = this.buildRulesPage(pageSlug);
        break;
      case 'schedule':
        pageData = tertiarySlug ?
          this.buildScheduleViewPage(pageSlug, tertiarySlug) :
          this.buildSchedulePage(pageSlug);
        break;
      default:
        pageData = this.notFoundPage();
        break;
    }
  
    return pageData;
  });

  brandData = signal<{ id: string, brand: Signal<GthTeamModel>, seasonGroups: Signal<SeasonsAndGroups> }[]>([]);
  brandDataArray = computed<BrandWithSeasonGroups[]>(() => this.brandData().map(brand => {
    return {
      id: brand.id,
      brand: brand.brand(),
      seasonsAndGroups: brand.seasonGroups()
    }
  }));

  constructor() {
    this.store.addReducer('teams', teamsReducer);
  }

  ngOnInit(): void {
    this.collectUrlSegments(this.route);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.collectUrlSegments(this.route);
    });

    const brandIds = this.websiteDetails()?.brands;
    if (!brandIds) return;

    const newBrandData = this.getBrandData(brandIds);

    this.brandData.set(newBrandData);
  }

  getBrandData(brandIds: string[]): { id: string, brand: Signal<GthTeamModel>, seasonGroups: Signal<SeasonsAndGroups> }[] {
    return brandIds.map(brand => {
      this.store.dispatch(LoadTeamsOne({ id: brand }));
      this.store.dispatch(LoadSeasonsOne({ teamId: brand }));
      this.store.dispatch(LoadSeasonGroupsOne({ teamId: brand }));

      const brandSignal = this.store.selectSignal(selectTeamById(brand));
      const seasonGroupSignal = this.store.selectSignal(selectSeasonGroupsByTeamId(brand));
      const seasonSignal = this.store.selectSignal(selectSeasonsByTeamId(brand));

      const seasonGroupWithSeasonSignal = computed<SeasonsAndGroups>(() => {
        let seasonGroups = seasonGroupSignal();
        let seasons = seasonSignal();
        const groupedSeasons = seasonGroups?.map((seasonGroup) => {
          return {
            seasonGroup: seasonGroup,
            seasons: seasons.filter((season) => seasonGroup.seasonIds.includes(season.id))
          };
        });

        const groupedSeasonIds = seasonGroups?.flatMap(seasonGroup => seasonGroup.seasonIds);
        const ungroupedSeasons = seasons.filter(season => !groupedSeasonIds?.includes(season.id));

        return [...(groupedSeasons ?? []), ...ungroupedSeasons];
      });

      return { id: brand, brand: brandSignal, seasonGroups: seasonGroupWithSeasonSignal };
    });
  }

  collectUrlSegments(route: ActivatedRoute) {
    let segments: string[] = [];
    while (route) {
      segments = segments.concat(route.snapshot.url.map(segment => segment.path));
      route = route.firstChild!;
    }
    const isGthDomain = this.domainCheckService.isGameTimeHeroDomain();
    if (isGthDomain && segments[0] === 'organization' && segments[1] === this.organization().id) {
      segments = segments.slice(2);
    }
    this.urlSegments.set(segments);
  }

  buildBrandPage(brandId: string): WebsitePage {
    let brand = this.brandDataArray().find(brand => brand.id === brandId);

    if (!brand?.brand) return this.notFoundPage(); // TODO(srevier): Change this to a loading page

    const brandPage = {
      id: brandId,
      title: brand.brand.name,
      slug: brand.id,
      modules: [
        {
          type: WebsiteModuleType.BRAND,
          module: {
            brand: brand,
          }
        },
      ]
    };
    return brandPage as WebsitePage;
  }

  buildSeasonPage(brandId: string, seasonId: string) {
    let brand = this.brandDataArray().find(brand => brand.id === brandId);
    if (!brand) return this.notFoundPage();
    let seasons = flattenSeasonsAndGroups(brand.seasonsAndGroups);
    if (!seasons?.length) return this.notFoundPage();
    let season = seasons?.find(season => season.id === seasonId);
    if (!season) return this.notFoundPage();
    const seasonPage = {
      id: seasonId,
      title: season.label,
      slug: seasonId,
      modules: [
        {
          type: WebsiteModuleType.SEASON_REGISTER,
          module: {
            brand: brand,
            color: this.websiteDetails()?.color,
            accent: this.websiteDetails()?.accent,
            darkMode: this.isDarkMode(),
            seasonId: season.id,
          }
        },
      ]
    };
    return seasonPage as WebsitePage;
  }

  buildRulesPage(brandId: string) {
    const brand = this.brandDataArray().find(brand => brand.id === brandId);
    const rules = this.websiteDetails()?.rules;
    if (!rules) return this.notFoundPage();
    const brandRules = rules.find(rule => rule.brandId === brandId);
    if (!brandRules) return this.notFoundPage();
    const rulesPage = {
      id: 'rules',
      title: 'Rules',
      slug: 'rules',
      modules: [
        {
          type: WebsiteModuleType.RULES,
          module: {
            description: brandRules.description,
            rules: brandRules.rules,
            brand,
          }
        },
      ]
    };
    return rulesPage as WebsitePage;
  }

  buildSchedulePage(brandId: string) {
    let brand = this.brandDataArray().find(brand => brand.id === brandId);
    const schedulePage = {
      id: 'schedule',
      title: 'Schedule',
      slug: 'schedule',
      modules: [
        {
          type: WebsiteModuleType.SCHEDULE,
          module: {
            brand,
          }
        },
      ]
    };
    return schedulePage as WebsitePage;
  }

  buildScheduleViewPage(brandId: string, seasonId: string) {
    const brand = this.brandDataArray().find(brand => brand.id === brandId);
    const season = flattenSeasonsAndGroups(brand.seasonsAndGroups).find(season => season.id === seasonId);
    const scheduleViewPage = {
      id: 'schedule-view',
      title: 'Schedule View',
      slug: 'schedule-view',
      modules: [
        {
          type: WebsiteModuleType.SCHEDULE_VIEW,
          module: {
            brand,
            season,
          }
        },
      ]
    };
    return scheduleViewPage as WebsitePage;
  }

  onToggleDarkMode() {
    this.isDarkMode.set(!this.isDarkMode());
    this.safeStorage.setItem('darkMode', this.isDarkMode().toString());
  }
}