<div class="px-4 pb-16 mx-auto max-w-7xl">
  @if (brand()?.brand) {
    <ark-organizer-website-brand-header
      [subHeader]="brand().brand.name"
      header="{{ seasonGroupLabel() ? seasonGroupLabel() + ' - ' : '' }}{{ season().label }} Schedule"
      [link]="[brand().brand.id]"
      [description]="null"
    />
    <mat-form-field>
      <mat-label>Division</mat-label>
      <mat-select
        (selectionChange)="selectedDivision.set($event.value)"
        [value]="selectedDivision()"
      >
        @for (name of divisionNames(); track name) {
          <mat-option [value]="$index">{{ name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="flex flex-col gap-12 mb-4 md:flex-row">
      <aside class="flex flex-col gap-2.5 border-0 border-b border-r-0 md:border-b-0 md:border-r border-solid border-olive-300 dark:border-olive-900 pb-6 md:pb-0 md:pr-12">
        <h3 class="m-0 text-base dark:text-olive-50 text-olive-1000 break-inside-avoid whitespace-nowrap">Quick Links</h3>
        <div class="flex flex-row md:flex-col gap-2.5 flex-wrap justify-center md:justify-start">
          @for (link of quickLinks(); track link) {
            <button
              class="underline cursor-pointer text-pear-500 all-unset break-inside-avoid whitespace-nowrap"
              (click)="onQuickLinkClick(link)"
            >{{ link }}</button>
          }
        </div>
      </aside>
      <div class="flex flex-col justify-center flex-1 min-h-96">
        @if (selectedDivisionData(); as data) {
          <h3 id="Standings" #header class="my-0 dark:text-olive-50 text-olive-1000">Standings</h3>
          <div class="overflow-x-auto">
            <table class="w-full" mat-table [dataSource]="standingsTableData()">
              <ng-container matColumnDef="place">
                <th mat-header-cell *matHeaderCellDef><strong>Place</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.place }}</td>
              </ng-container>
          
              <ng-container matColumnDef="team">
                <th mat-header-cell *matHeaderCellDef><strong>Team</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.team }}</td>
              </ng-container>
          
              <ng-container matColumnDef="w">
                <th mat-header-cell *matHeaderCellDef><strong>W</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.w }}</td>
              </ng-container>
          
              <ng-container matColumnDef="t">
                <th mat-header-cell *matHeaderCellDef><strong>T</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.t }}</td>
              </ng-container>
          
              <ng-container matColumnDef="l">
                <th mat-header-cell *matHeaderCellDef><strong>L</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.l }}</td>
              </ng-container>
          
              <ng-container matColumnDef="pts">
                <th mat-header-cell *matHeaderCellDef><strong>Points</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.pts }}</td>
              </ng-container>
          
              <ng-container matColumnDef="gd">
                <th mat-header-cell *matHeaderCellDef><strong>GD</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.gd }}</td>
              </ng-container>
          
              <ng-container matColumnDef="gf">
                <th mat-header-cell *matHeaderCellDef><strong>GF</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.gf }}</td>
              </ng-container>
          
              <ng-container matColumnDef="ga">
                <th mat-header-cell *matHeaderCellDef><strong>GA</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.ga }}</td>
              </ng-container>
          
              <ng-container matColumnDef="gp">
                <th mat-header-cell *matHeaderCellDef><strong>GP</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.gp }}</td>
              </ng-container>
          
              <ng-container matColumnDef="streak">
                <th mat-header-cell *matHeaderCellDef><strong>Streak</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.streak }}</td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="standingsDisplayedColumns"></tr>
              <tr mat-row class="odd:bg-olive-200 dark:odd:bg-olive-950" *matRowDef="let row; columns: standingsDisplayedColumns"></tr>
            </table>
          </div>

          @if (upcomingData().length) {
            <h3 id="Upcoming" #header class="mb-0 dark:text-olive-50 text-olive-1000">Upcoming</h3>
            <div class="overflow-x-auto">
              <table class="w-full" mat-table [dataSource]="upcomingData()">
                <ng-container matColumnDef="time">
                  <th mat-header-cell *matHeaderCellDef><strong>Time</strong></th>
                  <td mat-cell *matCellDef="let item">
                    @if (item.time) {
                      {{ item.date }}
                      {{ item.time }}
                    } @else {
                      <strong>{{ item.date }}</strong>
                    }
                  </td>
                </ng-container>
            
                <ng-container matColumnDef="home">
                  <th mat-header-cell *matHeaderCellDef><strong>Home</strong></th>
                  <td mat-cell *matCellDef="let item">{{ item.home }}</td>
                </ng-container>
            
                <ng-container matColumnDef="away">
                  <th mat-header-cell *matHeaderCellDef><strong>Away</strong></th>
                  <td mat-cell *matCellDef="let item">{{ item.away }}</td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="upcomingDisplayedColumns"></tr>
                <tr mat-row class="odd:bg-olive-200 dark:odd:bg-olive-950" *matRowDef="let row; columns: upcomingDisplayedColumns"></tr>
              </table>
            </div>
          }
        
          <h3 id="Playoffs" #header class="mb-0 dark:text-olive-50 text-olive-1000">Playoffs</h3>
          <div class="overflow-x-auto">
            <table class="w-full" mat-table [dataSource]="playOffsData()">
              <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef><strong>Time</strong></th>
                <td mat-cell *matCellDef="let item">
                  @if (item.time) {
                    {{ item.date }}
                    {{ item.time }}
                  } @else {
                    <strong>{{ item.date }}</strong>
                  }
                </td>
              </ng-container>
          
              <ng-container matColumnDef="home">
                <th mat-header-cell *matHeaderCellDef><strong>Home</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.home }}</td>
              </ng-container>
          
              <ng-container matColumnDef="homeScore">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item">{{ item.homeScore }}</td>
              </ng-container>
          
              <ng-container matColumnDef="scoreDivider">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item">{{ item.homeScore ? '-' : '' }}</td>
              </ng-container>
          
              <ng-container matColumnDef="awayScore">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item">{{ item.awayScore }}</td>
              </ng-container>

              <ng-container matColumnDef="away">
                <th mat-header-cell *matHeaderCellDef><strong>Away</strong></th>
                <td mat-cell *matCellDef="let item">{{ item.away }}</td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="playOffsDisplayedColumns"></tr>
              <tr mat-row class="odd:bg-olive-200 dark:odd:bg-olive-950" *matRowDef="let row; columns: playOffsDisplayedColumns"></tr>
            </table>
          </div>

          @if (pastData().length) {
            <h3 id="Results" #header class="mb-0 dark:text-olive-50 text-olive-1000">Results</h3>
            <div class="overflow-x-auto">
              <table class="w-full" mat-table [dataSource]="pastData()">
                <ng-container matColumnDef="time">
                  <th mat-header-cell *matHeaderCellDef><strong>Time</strong></th>
                  <td mat-cell *matCellDef="let item">
                    @if (item.time) {
                      {{ item.date }}
                      {{ item.time }}
                    } @else {
                      <strong>{{ item.date }}</strong>
                    }
                  </td>
                </ng-container>
            
                <ng-container matColumnDef="home">
                  <th mat-header-cell *matHeaderCellDef><strong>Home</strong></th>
                  <td mat-cell *matCellDef="let item">{{ item.home }}</td>
                </ng-container>
            
                <ng-container matColumnDef="homeScore">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let item">{{ item.homeScore }}</td>
                </ng-container>
            
                <ng-container matColumnDef="scoreDivider">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let item">-</td>
                </ng-container>
            
                <ng-container matColumnDef="awayScore">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let item">{{ item.awayScore }}</td>
                </ng-container>
            
                <ng-container matColumnDef="away">
                  <th mat-header-cell *matHeaderCellDef><strong>Away</strong></th>
                  <td mat-cell *matCellDef="let item">{{ item.away }}</td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="pastDisplayedColumns"></tr>
                <tr mat-row class="odd:bg-olive-200 dark:odd:bg-olive-950" *matRowDef="let row; columns: pastDisplayedColumns"></tr>
              </table>
            </div>
          }
        } @else {
          <mat-spinner class="mx-auto"></mat-spinner>
        }
      </div>
    </div>
    <ark-organizer-website-other-links
      [brandId]="brand().brand.id"
    />
  }
</div>
