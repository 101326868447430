<button
  (click)="linkClicked.emit()"
  class="h-full py-1.5 cursor-pointer all-unset group border-solid border-0 border-pear-500"
  [class.border-b]="active()"
>
  <div class="flex items-center h-full gap-1 px-4 transition-colors rounded-md dark:group-hover:bg-olive-900 group-hover:bg-olive-200">
    @if (icon()) {
      <mat-icon class="text-pear-500">{{ icon() }}</mat-icon>
    }
    <span
      class="font-medium text-olive-1000 dark:text-olive-50"
      [class.dark:text-pear-500]="active()"
      [class.text-pear-500]="active()"
    >{{ label() }}</span>
    @if (hasDropdown()) {
      <mat-icon
        class="text-base leading-none opacity-50 text-olive-1000 size-4 dark:text-olive-50"
        [class.dark:text-pear-500]="active()"
        [class.text-pear-500]="active()"
      >
        @if (isDropdownOpen()) {
          keyboard_arrow_up
        } @else {
          keyboard_arrow_down
        }
      </mat-icon>
    }
  </div>
</button>