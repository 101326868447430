import { inject, Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';

import { appRoutes } from '../../app.routes';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  private router = inject(Router);
  private routes: Routes = [];

  setRoutes(routes: Routes): void {
    this.routes = routes;
    this.router.resetConfig(routes);
  }

  getRoutes(): Routes {
    return this.routes;
  }
}
