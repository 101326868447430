import { Component, input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ark-rule-item',
  standalone: true,
  imports: [],
  templateUrl: './rule-item.component.html',
  styleUrl: './rule-item.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RuleItemComponent {
  header = input<string>();
  content = input<string>();
}
