import { Component, computed, inject, input } from '@angular/core';
import { flattenSeasonsAndGroups, isGroup, SeasonGroupsWithSeasons, SeasonRegisterModule } from '@ark/components/organizer-website/models/website-models';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { OrganizerWebsiteBrandHeaderComponent } from '../../organizer-website-brand-header/organizer-website-brand-header.component';
import { APP_ROUTES } from '@shared/helpers';
import { OrganizerWebsiteOtherLinksComponent } from '../../organizer-website-other-links/organizer-website-other-links.component';

@Component({
  selector: 'ark-season-register-module',
  standalone: true,
  imports: [
    MatIconModule,
    RouterModule,
    OrganizerWebsiteBrandHeaderComponent,
    OrganizerWebsiteOtherLinksComponent,
  ],
  templateUrl: './season-register-module.component.html',
  styleUrl: './season-register-module.component.scss'
})
export class SeasonRegisterModuleComponent {
  moduleData = input.required<SeasonRegisterModule>();

  private route = inject(ActivatedRoute);
  private router = inject(Router);

  appRoutes = APP_ROUTES;

  brand = computed(() => this.moduleData().brand.brand);
  seasons = computed(() => flattenSeasonsAndGroups(this.moduleData().brand.seasonsAndGroups));
  color = computed(() => this.moduleData().color);
  accent = computed(() => this.moduleData().accent);
  darkMode = computed(() => this.moduleData().darkMode);

  season = computed(() => this.seasons().find(season => season.id === this.moduleData().seasonId));
  seasonGroup = computed<SeasonGroupsWithSeasons>(() => 
    this.moduleData().brand?.seasonsAndGroups.find(
      (group) => {
        if (!isGroup(group)) return false;
        return group.seasonGroup.seasonIds.includes(this.season()?.id);
      }) as SeasonGroupsWithSeasons,
  );
  seasonGroupLabel = computed(() => this.seasonGroup()?.seasonGroup.label);
  startDate = computed(() => this.season().startDate);

  formId = computed(() => this.season().formId);
  backLink = computed(() => {
    const baseLink = `${this.brand().id}/season/${this.season().id}`;
    const urlSegments = this.route.snapshot.url.map(segment => segment.path);
    return urlSegments.includes('organization') ? `/organization/${baseLink}` : `/${baseLink}`;
  });

  onBackToSeasonClick() {
    const urlSegments = this.route.snapshot.url;
    const newUrl = urlSegments.slice(0, -2).map(segment => segment.path).join('/');
    this.router.navigate([newUrl]);
  }
}
