import { DatePipe, JsonPipe } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { isGroup, ScheduleModule, SeasonGroupsWithSeasons } from '@ark/components/organizer-website/models/website-models';
import { OrganizerWebsiteCardLinkComponent } from '../../organizer-website-card-link/organizer-website-card-link.component';
import { OrganizerWebsiteBrandHeaderComponent } from '../../organizer-website-brand-header/organizer-website-brand-header.component';
import { GthTeamSeasonModel } from '@sentinels/models';
import { OrganizerWebsiteOtherLinksComponent } from '../../organizer-website-other-links/organizer-website-other-links.component';

@Component({
  selector: 'ark-schedule-module',
  standalone: true,
  imports: [
    OrganizerWebsiteCardLinkComponent,
    OrganizerWebsiteBrandHeaderComponent,
    OrganizerWebsiteOtherLinksComponent,
    DatePipe,
  ],
  templateUrl: './schedule-module.component.html',
  styleUrl: './schedule-module.component.scss'
})
export class ScheduleModuleComponent {
  moduleData = input.required<ScheduleModule>();

  brand = computed(() => this.moduleData().brand.brand);
  seasonsAndGroups = computed(() => this.moduleData().brand.seasonsAndGroups);

  groups = computed(() => {
    const seasonsAndGroups = this.seasonsAndGroups();
    if (!seasonsAndGroups) return [];
    return seasonsAndGroups.filter(item => isGroup(item)) as SeasonGroupsWithSeasons[];
  });
  seasons = computed(() => {
    const seasonsAndGroups = this.seasonsAndGroups();
    if (!seasonsAndGroups) return [];
    return seasonsAndGroups.filter(item => !isGroup(item)) as GthTeamSeasonModel[];
  });
}
