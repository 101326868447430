import { Component, input } from '@angular/core';
import { NavDropdownItemComponent } from '../organizer-website-navbar/components/nav-dropdown-item/nav-dropdown-item.component';

@Component({
  selector: 'ark-organizer-website-other-links',
  standalone: true,
  imports: [
    NavDropdownItemComponent,
  ],
  templateUrl: './organizer-website-other-links.component.html',
  styleUrl: './organizer-website-other-links.component.scss'
})
export class OrganizerWebsiteOtherLinksComponent {
  brandId = input.required<string>();
  seasons = input<boolean>(true);
  schedule = input<boolean>(true);
  rules = input<boolean>(true);
}
