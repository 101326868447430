import { Location } from '@angular/common';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_STATE, AuthUpdate, MoreInfoFormDetails } from '@gth-legacy';
import { Store } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { NavBarOptions } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { APP_ROUTES } from '../../../../../shared/helpers';

enum MoreInfoTab {
  GENERAL = 'general',
  PRIVACY = 'privacy',
  PAYMENTS = 'payments',
  SPORTS = 'sports',
  AVAILABILITY = 'availability',
  NOTIFICATION = 'notification',
}
const MoreInfoTabIndexToTabMap: Map<number, MoreInfoTab> = new Map([
  [0, MoreInfoTab.GENERAL],
  [1, MoreInfoTab.PRIVACY],
  [2, MoreInfoTab.PAYMENTS],
  [3, MoreInfoTab.SPORTS],
  [4, MoreInfoTab.AVAILABILITY],
  [5, MoreInfoTab.NOTIFICATION],
]);
const MoreInfoTabToIndexMap: Map<MoreInfoTab, number> = new Map([
  [MoreInfoTab.GENERAL, 0],
  [MoreInfoTab.PRIVACY, 1],
  [MoreInfoTab.PAYMENTS, 2],
  [MoreInfoTab.SPORTS, 3],
  [MoreInfoTab.AVAILABILITY, 4],
  [MoreInfoTab.NOTIFICATION, 5],
]);

@Component({
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss'],
})
export class MoreInfoComponent implements OnInit, OnDestroy {
  onlyGeneralSettings = false;
  @Input() insideAuthDialogRoute = null;

  moreInfoFormDetails: MoreInfoFormDetails;
  navBarOptions: NavBarOptions = {
    pageName: 'Settings',
  };
  selectedTabIndex: number;
  skippable$: Observable<boolean>;

  private subscription = new Subscription();

  constructor(
    private safeWindow: SrvSafeWindowService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private location: Location,
    private router: Router,
    private zone: NgZone,
    private store: Store<APP_STATE>,
  ) {}

  protected APP_ROUTES = APP_ROUTES;

  ngOnInit(): void {
    this.moreInfoFormDetails = {
      header: this.onlyGeneralSettings ? 'General' : 'Settings',
      layout: this.onlyGeneralSettings ? 'horizontal' : 'vertical',
    };
    this.selectedTabIndex = 0;

    const root$ = this.route.url;

    const param$ = this.route.queryParams.pipe(
      map((query) => query.tab),
    );

    this.skippable$ = this.route.queryParams.pipe(
      map((query) => query.skippable),
    );

    this.subscription.add(
      root$.subscribe((url) => {
        this.onlyGeneralSettings = url.length > 0 && url[0].path === 'settings';
      }),
    );

    this.subscription.add(
      param$.subscribe((tab: MoreInfoTab) => {
        if (!tab) {
          return;
        }
        this.selectedTabIndex = this.getTabIndexNumber(tab.toLowerCase() as MoreInfoTab);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async onCreateNewUser(_user: GthUserModel) {
    this.snackbar.open('Welcome to Gametime Hero!');
    this.store.dispatch(AuthUpdate());
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        // Using this trick to login for now. Navigate wasn't working.
        if (this.insideAuthDialogRoute) {
          this.location.go(this.insideAuthDialogRoute);
          this.safeWindow.reload();
          return;
        }
        this.location.go('settings?tab=availability&skippable=true  ');
        this.safeWindow.reload();
      }, 250);
    });
  }

  onTabSelectChange(index: number) {
    this.selectedTabIndex = index;
    const tab = this.getTabFromIndexNumber(index);
    this.router.navigate([], { queryParams: { tab } });
  }

  getTabIndexNumber(tab: MoreInfoTab): number {
    return MoreInfoTabToIndexMap.get(tab) ?? 0;
  }
  getTabFromIndexNumber(tabIndex: number): MoreInfoTab {
    return MoreInfoTabIndexToTabMap.get(tabIndex) ?? MoreInfoTab.GENERAL;
  }
}
