import { Component, input } from '@angular/core';
import { SocialMediaModule } from '@ark/components/organizer-website/models/website-models';

@Component({
  selector: 'ark-social-module',
  standalone: true,
  imports: [],
  templateUrl: './social-module.component.html',
  styleUrl: './social-module.component.scss'
})
export class SocialModuleComponent {
  moduleData = input.required<SocialMediaModule>();
}
