import { Component, computed, ElementRef, inject, input, signal, viewChildren } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { isGroup, ScheduleViewModule, SeasonGroupsWithSeasons } from '@ark/components/organizer-website/models/website-models';
import { ScheduleScraperService } from '@ark/components/organizer-website/services/schedule-scraper.service';
import { switchMap } from 'rxjs';
import { OrganizerWebsiteBrandHeaderComponent } from '../../organizer-website-brand-header/organizer-website-brand-header.component';
import { OrganizerWebsiteOtherLinksComponent } from '../../organizer-website-other-links/organizer-website-other-links.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'ark-schedule-view-module',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatTableModule,
    MatSelectModule,
    OrganizerWebsiteBrandHeaderComponent,
    OrganizerWebsiteOtherLinksComponent,
    MatProgressSpinner,
  ],
  templateUrl: './schedule-view-module.component.html',
  styleUrl: './schedule-view-module.component.scss'
})
export class ScheduleViewModuleComponent {
  moduleData = input.required<ScheduleViewModule>();

  brand = computed(() => this.moduleData().brand);
  season = computed(() => this.moduleData().season);
  seasonGroup = computed<SeasonGroupsWithSeasons>(() => 
    this.brand()?.seasonsAndGroups.find(
      (group) => {
        if (!isGroup(group)) return false;
        return group.seasonGroup.seasonIds.includes(this.season()?.id);
      }) as SeasonGroupsWithSeasons,
  );
  seasonGroupLabel = computed(() => this.seasonGroup()?.seasonGroup.label);
  scheduleURLs = computed(() => this.season()?.scheduleURLs);

  private scheduleScraper = inject(ScheduleScraperService);

  scheduleData = toSignal(toObservable(this.scheduleURLs).pipe(
    switchMap((urls) => this.scheduleScraper.getAllScheduleData(urls))
  ));
  selectedDivision = signal<number>(0);
  selectedDivisionData = computed(() => {
    const scheduleData = this.scheduleData();
    return scheduleData && scheduleData[this.selectedDivision()];
  });
  divisionNames = computed(() => this.scheduleData()?.map((data) => data.name));

  // TABLE DATA
  standingsDisplayedColumns = ['place', 'team', 'w', 't', 'l', 'pts', 'gd', 'gf', 'ga', 'gp', 'streak'];
  standingsTableData = computed(() => {
    const standings = this.selectedDivisionData()?.standings;
    const initialColumnOrder = ['place', 'team', 'w', 'l', 't', 'pts', 'gp', 'streak', 'gf', 'ga', 'gd'];
    if (!standings) return [];
    standings.splice(0, 1);

    return standings.map((col: any) => {
      const reorderedCol: any = {};
      initialColumnOrder.forEach((column, index) => {
        reorderedCol[column] = col[index];
      });
      return reorderedCol;
    });
  });

  playOffsDisplayedColumns = ['time', 'home', 'homeScore', 'scoreDivider', 'awayScore', 'away'];
  playOffsData = computed(() => {
    const playOffs = this.selectedDivisionData()?.playOffs;
    const initialColumnOrder = ['date', 'time', 'game', 'home', 'away', 'location'];
    if (!playOffs) return [];
    playOffs.splice(0, 1);

    return playOffs.map((game: any) => {
      const reorderedGame: any = {};
      initialColumnOrder.forEach((column, index) => {
        if (!['home', 'away'].includes(column)) return reorderedGame[column] = game[index];
        
        if (game[index]?.includes('|')) {
          const teamAndScore = game[index].split('|');
          reorderedGame[column] = teamAndScore[0];
          reorderedGame[`${column}Score`] = teamAndScore[1];
        } else {
          reorderedGame[column] = game[index];
        }
      });
      return reorderedGame;
    });
  });

  upcomingDisplayedColumns = ['time', 'home', 'away'];
  upcomingData = computed(() => {
    const upcoming = this.selectedDivisionData()?.upcoming;
    const initialColumnOrder = ['date', 'time', 'home', 'away', 'location'];
    if (!upcoming) return [];

    return upcoming.map((game: any) => {
      const reorderedGame: any = {};
      initialColumnOrder.forEach((column, index) => {
        reorderedGame[column] = game[index];
      });
      return reorderedGame;
    });
  });

  pastDisplayedColumns = ['time', 'home', 'homeScore', 'scoreDivider', 'awayScore', 'away',];
  pastData = computed(() => {
    const past = this.selectedDivisionData()?.past;
    const initialColumnOrder = ['date', 'time', 'home', 'away', 'location'];
    if (!past) return [];

    return past.map((game: any) => {
      const reorderedGame: any = {};
      initialColumnOrder.forEach((column, index) => {
        if (!['home', 'away'].includes(column)) return reorderedGame[column] = game[index];
        
        if (game[index].includes('|')) {
          const teamAndScore = game[index].split('|');
          reorderedGame[column] = teamAndScore[0];
          reorderedGame[`${column}Score`] = teamAndScore[1];
        }
        
      });
      return reorderedGame;
    });
  });

  quickLinks = computed(() => {
    const links = [
      { label: 'Standings', data: this.standingsTableData() },
      { label: 'Playoffs', data: this.playOffsData() },
      { label: 'Upcoming', data: this.upcomingData() },
      { label: 'Results', data: this.pastData() },
    ];

    return links.filter(link => link.data && link.data.length).map(link => link.label);
  });

  tableHeaders = viewChildren('header', {
    read: ElementRef,
  });

  onChangeDivision(division: number) {
    this.selectedDivision.set(division);
  }

  onQuickLinkClick(label: string) {
    const header = this.tableHeaders().find((header) => header.nativeElement.textContent === label);
    if (!header) return;

    const top = header.nativeElement.getBoundingClientRect().top;
    const scrollingElement = document.querySelector('html');
    scrollingElement.scrollTo({
      left: 0, 
      top: top + scrollingElement.scrollTop - 80,
      behavior: 'smooth',
    });
  }
}
