import { DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ark-organizer-website-brand-header',
  standalone: true,
  imports: [
    RouterLink,
    MatIcon,
    DatePipe,
    MatIcon,
  ],
  templateUrl: './organizer-website-brand-header.component.html',
  styleUrl: './organizer-website-brand-header.component.scss',
})
export class OrganizerWebsiteBrandHeaderComponent {
  header = input<string>();
  subHeader = input<string>();
  startDate = input<Date>();
  link = input<string[]>();
  photoURL = input<string>();
  description = input<string>('Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque corrupti tempora, debitis nemo possimus pariatur veniam! Omnis illum nobis cum vitae, sint repellat explicabo nulla saepe earum fugiat quo magnam.');
}
