import { Component, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ark-nav-dropdown-item',
  standalone: true,
  imports: [
    RouterLink,
    MatIconModule,
  ],
  templateUrl: './nav-dropdown-item.component.html',
  styleUrl: './nav-dropdown-item.component.scss',
})
export class NavDropdownItemComponent {
  link = input<string | string[]>();
  title = input<string>();
  subtitle = input<string>();
  icon = input<string>();
  image = input<string>();
  small = input<boolean>(false);
  disabled = input<boolean>(false);
  badgeText = input<string>();

  click = output<void>();
}
